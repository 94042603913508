import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { baseURL } from "../../constants";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import LoaderDashboard from "../../utils/LoaderDashboard";
import "../../utils/LoaderDashboard.css";
import "./ViewDashboard.css"
import { FiSearch, FiMinus, FiPlus, FiMaximize2 } from 'react-icons/fi';
import { TbArrowsLeftRight } from 'react-icons/tb';
import { IoIosExpand } from "react-icons/io";
import { TfiArrowsHorizontal } from "react-icons/tfi";

const ViewDashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
  const token = localStorage.getItem("auth");
  const decoded = localStorage.getItem("auth")
    ? jwt_decode(localStorage.getItem("auth"))
    : null;
  const { id, dashboardPlatform } = useParams();
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [powerBiAccessToken, setPowerBiAccessToken] = useState("");
  const [powerBiEmbedUrl, setpowerBiEmbedUrl] = useState("");
  const [powerBiReportId, setpowerBiReportId] = useState("");
  const [embedLink, setEmbedLink] = useState("");
  const [embedToken, setEmbedToken] = useState(null);
  const iframeRef = useRef(null);
  const powerBIRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const containerRef = useRef(null);

  const getTableauToken = async () => {
    try {
      const res = await axios.get(`${baseURL}/getTableauToken/${id}`);
      setEmbedToken(res.data.token);
      setEmbedLink(res.data.embedLink);
    } catch (error) {
      console.error('Error fetching Tableau token:', error);
      // Optionally set some error state here to show a user-friendly message
    }
  };

  const getPowerBiToken = async () => {
    try {
      const res = await axios.get(`${baseURL}/getEmbedToken/${id}`);
      setPowerBiAccessToken(res.data.accessToken);
      setpowerBiEmbedUrl(res.data.embedUrl[0].embedUrl);
      setpowerBiReportId(res.data.embedUrl[0].reportId);
    } catch (error) {
      console.error('Error fetching Power BI token:', error);
      // Optionally set some error state here to show a user-friendly message
    }
  };

  const getSrc = async () => {
    try {
      setLoadingDashboard(true);
      const res = await fetch(`${baseURL}/domo/dashboard/${id}`, {
        method: "GET",
        headers: {
          accessToken: token,
        },
      });
      setLoadingDashboard(false);
      const blob = await res.blob();
      const urlObject = URL.createObjectURL(blob);

      if (iframeRef.current) {
        iframeRef.current.setAttribute("src", urlObject);
      }

      if (decoded) {
        await axios.post(`${baseURL}/logEvent`, {
          eventType: "View Dashboard",
          dashboardId: id,
          userId: decoded.adminId,
        });
      }
    } catch (error) {
      setLoadingDashboard(false);
      console.error('Error fetching Domo dashboard:', error);
      // Optionally set some error state here to show a user-friendly message
    }
  };

  useEffect(() => {
    if (dashboardPlatform == 0) {
      getSrc();
    } else if (dashboardPlatform == 1) {
      getPowerBiToken();
    } else if (dashboardPlatform == 2) {
      getTableauToken();
    }
  }, [id, dashboardPlatform]);

  const toggleFullscreen = () => {
    let element;
    if (dashboardPlatform == 0) {
      element = iframeRef.current;
    } else if (dashboardPlatform == 1) {
      element = powerBIRef.current.querySelector("iframe");
    } else if (dashboardPlatform == 2) {
      element = document.getElementById("tableauViz");
    }

    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const zoomIn = () => {
    if (zoomLevel < 2) {
      setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 2));
    }
  };

  const zoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 0.5)); // Set a min zoom level
  };

  const resetZoom = () => {
    setZoomLevel(1); // Reset to 100%
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.transform = `scale(${zoomLevel})`;
      containerRef.current.style.transformOrigin = "center";
    }
  }, [zoomLevel]);

  useEffect(() => {
    let element;
    if (dashboardPlatform == 0) {
      element = iframeRef.current;
    } else if (dashboardPlatform == 1) {
      element = powerBIRef.current.querySelector("iframe");
    } else if (dashboardPlatform == 2) {
      element = document.getElementById("tableauViz");
    }

    if (element) {
      element.style.transform = `scale(${zoomLevel})`;
      element.style.transformOrigin = "0 0";
      element.style.width = `${100 / zoomLevel}%`;
      element.style.height = `${100 / zoomLevel}%`;
    }
  }, [zoomLevel, dashboardPlatform]);

  return (
    <div className="flex flex-col h-full">
      {/* Toolbar Section */}
      <div className="flex justify-end">
        <div className="flex items-center space-x-6 bg-white p-1.5 rounded-md shadow w-auto">
          <div className="flex items-center space-x-1 border-r pr-2">
            <button className="p-1.5 hover:bg-gray-100 rounded" onClick={zoomOut}>
              <FiMinus className="text-gray-500 w-4 h-4" />
            </button>
            <span className="text-gray-700 text-sm">{Math.round(zoomLevel * 100)}%</span>
            <button
              className="p-1.5 hover:bg-gray-100 rounded"
              onClick={zoomIn}
              disabled={zoomLevel >= 1}
            >
              <FiPlus className={`w-4 h-4 ${zoomLevel >= 1 ? 'text-gray-300' : 'text-gray-500'}`} />
            </button>
          </div>
          <button className="p-1.5 hover:bg-gray-100 rounded" onClick={resetZoom}>
            <TfiArrowsHorizontal className="text-gray-500 w-4 h-4" />
          </button>
          <button className="p-1.5 hover:bg-gray-100 rounded" onClick={toggleFullscreen}>
            <IoIosExpand className="text-gray-500 w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Dashboard Section */}
      <div className="flex flex-col justify-center items-center h-full bg-white p-2 mt-4">
        {loadingDashboard ? (
          <div className="flex justify-center items-center w-full h-full">
            <LoaderDashboard />
            <span className="ml-2">Loading Dashboard</span>
          </div>
        ) : (
          <div ref={containerRef} className="w-full h-full max-w-full max-h-fit overflow-hidden">
            {dashboardPlatform == 0 && (
              <iframe
                ref={iframeRef}
                id="iframe1"
                src=""
                className="w-full h-full"
              ></iframe>
            )}
            {dashboardPlatform == 1 && (
              <div ref={powerBIRef} className="w-full h-full">
                <PowerBIEmbed
                  cssClassName={"powerbi-embed w-full h-full"}
                  embedConfig={{
                    type: "report",
                    id: powerBiReportId,
                    embedUrl: powerBiEmbedUrl,
                    accessToken: powerBiAccessToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                      panes: {
                        filters: {
                          expanded: false,
                          visible: true,
                        },
                      },
                    },
                  }}
                />
              </div>
            )}
            {dashboardPlatform == 2 && (
              <tableau-viz
                id="tableauViz"
                src={embedLink}
                token={embedToken}
                className="w-full h-full"
              ></tableau-viz>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDashboard;
