import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { baseURL } from "../../constants";
import { useParams, useSearchParams } from "react-router-dom";
import ClientLogo from "./../../assets/org-logo-3.png";
import userIcon from "../../assets/user1.png";

import { Link } from 'react-router-dom';
import { LuLayoutDashboard, LuUser2, LuTable2 } from 'react-icons/lu';
import { MdOutlineEdit } from 'react-icons/md';
import { PiWrench } from "react-icons/pi";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme
import { SiAwsorganizations } from "react-icons/si";
import { HiOutlineUsers } from "react-icons/hi2";
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FiEdit } from "react-icons/fi";

const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: `#f1f5f9`, // Optional: Change column headers background color
        borderBottom: '1px solid #dbeafe', // Added border bottom
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: `#f1f5f9`, // Tailwind CSS bg-grey-50 equivalent
    },
    '& .user-column-bold': {
        fontWeight: 'bold',
    },
});


const OrgEdit = () => {
    const { selectedColor } = useTheme();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let { orgId } = useParams();
    const [adminInfo, setAdminInfo] = useState([]);
    const [orgInfo, setOrgInfo] = useState([]);
    const [orgIcon, setOrgIcon] = useState(ClientLogo);
    const [orgName, setOrgName] = useState("");
    const [iconFile, setIconFile] = useState(null);
    const [isIconUpdated, setIsIconUpdated] = useState(false);
    const [fetchedIcon, setFetchedIcon] = useState(null);
    const [selectedTools, setSelectedTools] = useState([]);
    const [formError, setFormError] = useState({});
    const [platformFlag, setPlatformFlag] = useState([]);


    const handleChangeOrgName = (event) => {
        setOrgName(event.target.value);
    };
    const handleToolSelect = (event) => {
        const selectedTool = event.target.value;
        if (selectedTools.includes(selectedTool)) {
            setSelectedTools(selectedTools.filter((tool) => tool !== selectedTool));
        } else {
            setSelectedTools([...selectedTools, selectedTool]);
        }
    };

    const handleEditOrg = (orgObject) => {
        setOrgName(orgObject.company_name);
    };

    // const editOrgName = () => {
    //     // console.log("selected tools", selectedTools);
    //     if (orgName && selectedTools.length > 0) {
    //         setFormError({});
    //         axios
    //             .put(`${baseURL}/organizationEdit`, {
    //                 companyName: orgName,
    //                 orgId: orgId,
    //                 selectedTools: selectedTools
    //             })
    //             .then((res) => {
    //                 if (isIconUpdated) {
    //                     uploadIcon();
    //                 }
    //                 getOrgInfo();
    //                 handleClose();
    //             });
    //     } else {
    //         const err = {};
    //         if (!orgName) {
    //             err.orgName = "Org Name is mandatory";
    //         }
    //         if (!selectedTools.length > 0) {
    //             err.tool = "Atleast one tool selection is mandatory"
    //         }
    //         setFormError(err);
    //     }
    // };

    const editOrgName = () => {
        if (orgName && selectedTools.length > 0) {
            setFormError({});
            axios
                .put(`${baseURL}/organizationEdit`, {
                    companyName: orgName,
                    orgId: orgId,
                    selectedTools: selectedTools,
                })
                .then((res) => {
                    toast.success("Organization information updated successfully!");
                    if (isIconUpdated) {
                        uploadIcon();
                    }
                    getOrgInfo();
                    handleClose();
                })
                .catch((error) => {
                    toast.error("Failed to update organization information. Please try again.");
                    // console.log(error);
                });
        } else {
            const err = {};
            if (!orgName) {
                err.orgName = "Org Name is mandatory";
            }
            if (!selectedTools.length > 0) {
                err.tool = "At least one tool selection is mandatory";
            }
            setFormError(err);
            toast.error("Please fill out all required fields.");
        }
    };
    

    function setPlatform(platformFlag) {
        if (platformFlag === 1) {
            setSelectedTools([]);
            setSelectedTools(["DOMO"]);
        } else if (platformFlag === 2) {
            setSelectedTools([]);
            setSelectedTools(["PowerBI"]);
        } else if (platformFlag === 3) {
            setSelectedTools([]);
            setSelectedTools(["Tableau"]);
        } else if (platformFlag === 4) {
            setSelectedTools([]);
            setSelectedTools(["DOMO", "PowerBI"]);
        } else if (platformFlag === 5) {
            setSelectedTools([]);
            setSelectedTools(["DOMO", "Tableau"]);
        } else if (platformFlag === 6) {
            setSelectedTools([]);
            setSelectedTools(["PowerBI", "Tableau"]);
        } else if (platformFlag === 7) {
            setSelectedTools([]);
            setSelectedTools(["DOMO", "PowerBI", "Tableau"]);
        }
    }

    function getOrgInfo() {
        // console.log("in getOrgInfo function");
        axios
            .get(`${baseURL}/getOrgInfoById/${orgId}`)
            .then(function (response) {
                setAdminInfo(response.data.adminInfo);
                setOrgInfo(response.data.orgInfo);
                // setPlatformFlag(response.data.orgInfo[0].platform_flag);
                setPlatformFlag(response.data.orgInfo[0].platform_flag);
                const flag = response.data.orgInfo[0].platform_flag;
                setPlatform(flag);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    useEffect(() => {
        getOrgInfo();
    }, []);

    useEffect(() => {
        getOrgIcon();
    }, []);

    const getOrgIcon = () => {
        axios.get(`${baseURL}/retrieveOrgIcon/${orgId}`).then((response) => {
            const orgIconUrl = response.data.dataUrl;
            checkImageExists(orgIconUrl)
                .then(() => {
                    // console.log("Image exists");
                    setOrgIcon(orgIconUrl);
                    setFetchedIcon(orgIconUrl);
                })
                .catch(() => {
                    // console.log("Image does not exist");
                    setOrgIcon(ClientLogo);
                });
        });
    };

    const handleOrgIconChange = (event) => {
        const iconValue = event.target.files[0];
        setIconFile(iconValue);
        // console.log(iconValue);
        setIsIconUpdated(true);
        let icon = URL.createObjectURL(iconValue);
        setOrgIcon(icon);
        event.target.value = null;
        // console.log(iconValue);
    };

    const uploadIcon = () => {
        const formData = new FormData();
        formData.append("image", iconFile);
        axios
            .post(`${baseURL}/uploadOrgIcon/${orgId}`, formData, {})
            .then((response) => {
                getOrgIcon();
                setIsIconUpdated(false);
            });
    };

    const handleModalClose = () => {
        // setOrgIcon(fetchedIcon);
        checkImageExists(fetchedIcon)
            .then(() => {
                // console.log("Image exists");
                setOrgIcon(fetchedIcon);
                // setFetchedIcon(orgIconUrl);
            })
            .catch(() => {
                // console.log("Image does not exist");
                setOrgIcon(ClientLogo);
            });
        setPlatform(platformFlag);
        setFormError({});
    };

    const checkImageExists = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    };

    const [activeTab, setActiveTab] = useState('organisationInformation');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        // console.log(tabName);
    };

    const columns = [
        { field: 'index', headerName: 'Id', width: 70, align: 'left',
            headerAlign: 'left', },
        { field: 'company_name', headerName: 'Organisation Name', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<EditOutlinedIcon />}
                    onClick={() => {
                        handleEditOrg(params.row);
                        handleShow();
                    }}
                >
                    Edit
                </Button>
            ),
        },

    ];

    const rows = orgInfo.map((org, index) => ({
        id: org.id,
        index: index + 1,
        company_name: org.company_name,
        status: org.status,
    }));


    const columnsTwo = [
        { field: 'id', headerName: 'Id', width: 70,align: 'center',
            headerAlign: 'center', },
        { field: 'adminName', headerName: 'Admin Name', flex: 1 },
        { field: 'jobTitle', headerName: 'Job Title', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'phone', headerName: 'Phone', flex: 1 },
    ];

    const rowsTwo = adminInfo.map((admin, index) => ({
        id: index + 1,
        adminName: `${admin.first_name} ${admin.last_name}`,
        jobTitle: admin.job_title,
        email: admin.work_email,
        phone: admin.phone,
    }));


    return (
        <>
            <label className="px-3 text-sm text-gray-900 capitalize py-1 flex items-center overflow-x-auto whitespace-nowrap">
                <Link to={'/dashboard/orgs'} className="text-gray-600 hover:underline">
                    Manage Customers
                </Link>

                <span className="mx-3 text-gray-500 rtl:-scale-x-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                </span>

                <p className={`text-${selectedColor}-600 hover:underline`}>
                    Customers Information
                </p>
            </label>

            <div className="mt-8 flex justify-between items-center border-b border-gray-200">
                <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
                    <button
                        className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'organisationInformation'
                            ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                            : 'text-gray-700 border-b-2 border-transparent'
                            } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                        onClick={() => handleTabClick('organisationInformation')}
                    >
                        <SiAwsorganizations className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
                        <span className="mx-1 text-xs sm:text-sm font-medium">
                            Organisation Information
                        </span>
                    </button>

                    <button
                        className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'adminInformation'
                            ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                            : 'text-gray-700 border-b-2 border-transparent'
                            } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                        onClick={() => handleTabClick('adminInformation')}
                    >
                        <HiOutlineUsers className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
                        <span className="mx-1 text-xs sm:text-sm font-medium">
                            Admin Information
                        </span>
                    </button>

                </div>
            </div>

            <div className="my-4 relative">
                {activeTab === 'organisationInformation' && (
                    <div style={{ height: 'auto', width: '100%' }}>
                        <StyledDataGrid
                            rows={rows}
                            columns={columns}
                            rowHeight={45}
                            columnHeaderHeight={50}
                            autoHeight
                            pageSizeOptions={[10, 25, 50]}
                            // checkboxSelection
                            disableSelectionOnClick
                            disableRowSelectionOnClick
                        />
                    </div>
                )}
            </div>

            {show && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-lg shadow-lg">

                        {/* Header */}
                        <div className="px-2 py-2 flex justify-between items-center border-b sticky top-0 bg-white z-10">
                            <h2 className="text-lg font-semibold text-gray-800">
                                Update Organisation Info
                            </h2>
                            <button
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                    handleClose();
                                    handleModalClose();
                                }}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="mt-4">
                            <form className="flex flex-col items-start">
                                <div className='mt-4'>
                                    <label htmlFor="companyName" className="text-gray-700">
                                        Company Name
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        onChange={handleChangeOrgName}
                                        value={orgName}
                                        className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                        placeholder="Company Name"
                                        id="companyName"
                                    />
                                    <p className="mt-1 text-xs text-red-500">{formError.orgName}</p>
                                </div>
                                <div className='mt-4'>
                                    <label htmlFor="companyLogo" className="text-gray-700">
                                        Company Logo
                                    </label>
                                    <div className="mt-2 relative w-40 h-40 border border-gray-300 rounded-md logo_container">
                                        <img
                                            src={orgIcon}
                                            alt="Company logo"
                                            className="object-cover w-full h-full rounded-md"
                                        />
                                        <label htmlFor="profilePic" className="absolute bottom-0 right-0 p-1 bg-gray-200 rounded-tl-md cursor-pointer">
                                            <FiEdit size={15} className="text-gray-500" />
                                        </label>
                                        <input
                                            type="file"
                                            style={{ visibility: "hidden" }}
                                            accept=".jpg, .jpeg, .png"
                                            id="profilePic"
                                            onChange={handleOrgIconChange}
                                        />
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <label className="text-gray-700">
                                        Select Tools <span className="text-red-500">*</span>
                                    </label>
                                    <div className="flex space-x-7 mt-2">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="mr-2 form-checkbox"
                                                id="powerBI"
                                                value="PowerBI"
                                                checked={selectedTools.includes("PowerBI")}
                                                onChange={handleToolSelect}
                                            />
                                            <label htmlFor="powerBI" className="text-md text-gray-700">PowerBI</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="mr-2 form-checkbox"
                                                id="domo"
                                                value="DOMO"
                                                checked={selectedTools.includes("DOMO")}
                                                onChange={handleToolSelect}
                                            />
                                            <label htmlFor="domo" className="text-md text-gray-700">DOMO</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="mr-2 form-checkbox"
                                                id="tableau"
                                                value="Tableau"
                                                checked={selectedTools.includes("Tableau")}
                                                onChange={handleToolSelect}
                                            />
                                            <label htmlFor="tableau" className="text-md text-gray-700">Tableau</label>
                                        </div>
                                    </div>
                                    <p className="mt-1 text-xs text-red-500">{formError.tool}</p>
                                </div>
                            </form>
                        </div>
                        <div className="flex justify-end border-t border-gray-200 sticky bottom-0 bg-white z-10 gap-4 pt-4 mt-4">
                            <button
                                className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                onClick={() => {
                                    handleModalClose();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                onClick={editOrgName}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="my-4 relative">
                {activeTab === 'adminInformation' && (
                    <div style={{ height: 'auto', width: '100%' }}>
                        <StyledDataGrid
                            rows={rowsTwo}
                            columns={columnsTwo}
                            rowHeight={45}
                            columnHeaderHeight={50}
                            autoHeight
                            pageSizeOptions={[10, 25, 50]}
                            disableRowSelectionOnClick
                        // checkboxSelection
                        // disableSelectionOnClick
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default OrgEdit;
