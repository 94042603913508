import { React, useState } from 'react';
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from "react-cookie";

import Login from './Components/userLogin/Login';
import ResetPassword from './Components/userLogin/ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from './Components/dashboard/Dashboard';
import Configure from './Components/configure/Configure';
import Sidebar from './Components/sidebar/Sidebar';
import toast, { Toaster } from 'react-hot-toast';
import ManageUsers from './Components/manage-users/ManageUsers';
import EditUsers from './Components/edit-user/EditUsers';
import ManageGroups from './Components/manage-groups/ManageGroups';
import EditGroups from './Components/edit-group/EditGroups';
import DashboardTab from './Components/configure/DashboardTab';
import ViewDashboard from './Components/view-dashboard/ViewDashboard';
import Branding from './Components/branding/Branding';
import About from './Components/about/About';
import SmtpSetup from './Components/smtpSetup/SmtpSetup';
import { ThemeProvider } from './ThemeContext.js'; // Import ThemeProvide
import UserDashboard from './Components/UserDashboard/UserDashboard.js';
import OrgsList from './Components/orgsList/OrgsList.js';
import OrgEdit from './Components/orgsList/OrgEdit.js';
import ViewAnalyticsDashboard from './Components/viewAnalytics/ViewAnalyticsDashboard.js';



const App = () => {

  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
  const [user, setUser] = useState(null);

  const assignUser = (user) => {
    setUser(user);
  };


  axios.interceptors.request.use(
    (config) => {
      //  console.log("interceptor called");
      // Add configurations here
      const token = localStorage.getItem("auth");
      if (token) {
        config.headers.accessToken = token;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return (
    <Router>
      <Toaster position="top-center" autoClose={5000} hideProgressBar={false} />
      <Routes>
        {/* Routes not wrapped with ThemeProvider */}
        <Route path="/" element={<Login assignUser={assignUser} />} />
        {/* <Route path="/login" element={<Login assignUser={assignUser} />} /> */}
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Routes wrapped with ThemeProvider */}
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute user={user}>
              <ThemeProvider>
                <Dashboard />
              </ThemeProvider>
            </ProtectedRoute>
          }
        >
          <Route path="settings/branding" element={<Branding />} />
          <Route path="settings/configure" element={<Configure />} />
          <Route path="settings/manage-users" element={<ManageUsers />} />
          <Route path="settings/manage-groups" element={<ManageGroups />} />
          <Route path="settings/manage-users/edit-users/:userId" element={<EditUsers />} />
          <Route path="settings/manage-groups/edit-groups/:groupId" element={<EditGroups />} />
          <Route path="settings/smtp-setup" element={<SmtpSetup />} />
          <Route path="settings/add-dashboards" element={<DashboardTab />} />
          <Route path="view-dashboard/:id/:dashboardPlatform" element={<ViewDashboard />} />
          <Route path="about" element={<About />} />
          <Route path="user-dashboard" element={<UserDashboard />} />
          <Route path="orgs" element={<OrgsList />} />
          <Route path="orgs/:orgId" element={<OrgEdit />} />
          <Route
            path="view-analytics-dashboard"
            element={<ViewAnalyticsDashboard />}
          />

        </Route>
      </Routes>
    </Router>
  );
};

export default App;
