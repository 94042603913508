import React, { useState, useEffect } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useDebounce } from 'use-debounce';
import { FiDownload } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import axios from "axios";
import { baseURL } from "../../constants.js";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { useTheme } from '../../ThemeContext.js'; // Import useTheme
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';



const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: `#f1f5f9`, // Optional: Change column headers background color
        borderBottom: '1px solid #dbeafe', // Added border bottom
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: `#f1f5f9`, // Tailwind CSS bg-grey-50 equivalent
    },
    '& .user-column-bold': {
        fontWeight: 'bold',
    },
});

const OrgsList = () => {
    const { selectedColor } = useTheme();
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText] = useDebounce(searchText, 300); // Reduced debounce time for responsiveness
    const [show, setShow] = useState(false);
    let history = useNavigate();

    const [orgs, setOrgs] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [department, setDepartment] = useState("");
    const [location, setLocation] = useState("");

    const [formError, setFormError] = useState({});
    const handleClose = () => {
        setShow(false);
        setFormError({});
        setFirstName("");
        setLastName("");
        setEmail("");
        setCompanyName("");
        setPhone("");
        setJobTitle("");
        setDepartment("");
        setLocation("");
    };
    const handleShow = () => setShow(true);

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    };

    const handleChangeJobTitle = (event) => {
        setJobTitle(event.target.value);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };

    const handleChangeDepartment = (event) => {
        setDepartment(event.target.value);
    };

    const handleChangeLocation = (event) => {
        setLocation(event.target.value);
    };

    function getAllOrgs() {
        axios
            .get(`${baseURL}/getAllOrgs`)
            .then(function (response) {
                // console.log(response);
                setOrgs(response.data.orgs);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        getAllOrgs();
    }, []);

    // function saveInfo() {
    //     if (firstName && lastName && email && companyName && jobTitle && selectedTools.length > 0) {
    //         setFormError({});
    //         axios
    //             .post(`${baseURL}/organizationRegistration`, {
    //                 firstName: firstName,
    //                 lastName: lastName,
    //                 workEmail: email,
    //                 companyName: companyName,
    //                 jobTitle: jobTitle,
    //                 phone: phone ? phone : null,
    //                 department: department ? department : null,
    //                 location: location ? location : null,
    //                 selectedTools: selectedTools,
    //             })
    //             .then(function (response) {
    //                 // console.log(response);
    //                 handleClose();
    //                 getAllOrgs();

    //                 // setSelectedValue(response?.data?.message);
    //                 // handleClickOpen();
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    //     } else {
    //         const err = {};
    //         if (!firstName) {
    //             err.firstName = "First Name is mandatory";
    //         }
    //         if (!lastName) {
    //             err.lastName = "Last Name is mandatory";
    //         }
    //         if (!email) {
    //             err.email = "Email is mandatory";
    //         }
    //         if (!selectedTools.length > 0) {
    //             err.tool = "Atleast one tool selection is mandatory"
    //         }
    //         if (!companyName) {
    //             err.companyName = "Company Name is mandatory";
    //         }
    //         if (!jobTitle) {
    //             err.jobTitle = "Job Title is mandatory";
    //         }
    //         // if (!department) {
    //         //   err.department = "Department is mandatory"
    //         // }
    //         // if (!location) {
    //         //   err.location = "Location is mandatory"
    //         // }
    //         setFormError(err);
    //     }
    // }

    function saveInfo() {
        if (firstName && lastName && email && companyName && jobTitle && selectedTools.length > 0) {
            setFormError({});
            axios
                .post(`${baseURL}/organizationRegistration`, {
                    firstName: firstName,
                    lastName: lastName,
                    workEmail: email,
                    companyName: companyName,
                    jobTitle: jobTitle,
                    phone: phone ? phone : null,
                    department: department ? department : null,
                    location: location ? location : null,
                    selectedTools: selectedTools,
                })
                .then(function (response) {
                    toast.success("Organization information saved successfully!");
                    handleClose();
                    getAllOrgs();
                })
                .catch(function (error) {
                    toast.error("Failed to save organization information. Please try again.");
                    // console.log(error);
                });
        } else {
            const err = {};
            if (!firstName) {
                err.firstName = "First Name is mandatory";
            }
            if (!lastName) {
                err.lastName = "Last Name is mandatory";
            }
            if (!email) {
                err.email = "Email is mandatory";
            }
            if (!selectedTools.length > 0) {
                err.tool = "At least one tool selection is mandatory";
            }
            if (!companyName) {
                err.companyName = "Company Name is mandatory";
            }
            if (!jobTitle) {
                err.jobTitle = "Job Title is mandatory";
            }
            setFormError(err);
            toast.error("Please fill out all required fields.");
        }
    }
    

    const handleToolSelect = (event) => {
        const selectedTool = event.target.value;
        if (selectedTools.includes(selectedTool)) {
            setSelectedTools(selectedTools.filter((tool) => tool !== selectedTool));
        } else {
            setSelectedTools([...selectedTools, selectedTool]);
        }
    };

    const handleOnChange = (position, orgId, orgStatus, e) => {
        // console.log("function called", e);
        axios
            .put(`${baseURL}/ogrStatus/${orgId}`, {
                status: orgStatus ? "disable" : "enable",
            })
            .then(function (response) {
                // console.log(response);
                if (response.status == 200) {
                    getAllOrgs();
                    //orgs[position].status = orgStatus ? 0 : 1;
                    //setOrgs(orgs);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };

    const editOrg = (orgId) => {
        history("/dashboard/orgs/" + orgId);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentRow, setCurrentRow] = React.useState(null);

    const handleOpenMenu = (event, row) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const columns = [
        { field: 'index', headerName: 'Id', width: 70 , align: 'center',
            headerAlign: 'center', }, // Smaller width for the ID column
        { field: 'customerName', headerName: 'Customer Name', flex: 1 }, // Larger width for names
        { field: 'companyDomain', headerName: 'Company Domain', flex: 1 }, // Set specific width
        {
            field: 'users', headerName: 'Users', type: 'number', width: 100, align: 'left',
            headerAlign: 'left',
        }, // Adjusted width for numeric data
        {
            field: 'status',
            headerName: 'Status',
            align: 'left',
            headerAlign: 'left',
            width: 250, // Specific width for the status column
            renderCell: (params) => (
                <div>
                    <Switch
                        checked={params.value}
                        onChange={(e) =>
                            handleOnChange(params.row.index, params.row.id, params.value, e)
                        }
                        color="primary"
                    />
                    <label onClick={(e) => e.stopPropagation()}>
                        {params.value ? "Active" : "Inactive"}
                    </label>
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200, // Set width for the actions column
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (

                <Button
                    variant="outlined"
                    size='small'
                    startIcon={<ManageAccountsOutlinedIcon />}
                    onClick={() => editOrg(params.row.id)}>
                    Manage
                </Button>
            ),
        },
    ];


    const rows = orgs.map((org, index) => ({
        id: org.id,
        index: index + 1,
        customerName: `${org.first_name} ${org.last_name}`,
        companyDomain: org.company_name,
        users: org.count == null ? 0 : org.count,
        status: org.status,
    }));
    return (
        <>
            <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
                Manage Customers
            </label>


            <div className="mt-3 flex justify-between items-center border-b border-gray-200">
                <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
                    <div className="relative mt-3 mb-3 text-sm">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <IoSearchOutline className="w-5 h-5 text-gray-400" />
                        </span>
                        <input
                            type="text"
                            className="w-full py-1.5 pl-10 pr-4 text-gray-700 bg-gray-50 border rounded outline-none"
                            placeholder="Search Customers"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div>

                <div className="flex space-x-5">
                    <button
                        type="button"
                        className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                        onClick={handleShow} // ---------- Add User Event Binding
                    >
                        <IoIosAdd className="w-5 h-5 mx-1" />
                        <span className="text-sm">Add Users</span>
                    </button>
                </div>
            </div>

            <div style={{ height: 'auto', width: '100%' }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    rowHeight={45}
                    columnHeaderHeight={50}
                    autoHeight
                    pageSizeOptions={[10, 25, 50]}
                    // checkboxSelection
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    
                />
            </div>

            {show && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                    <div className="bg-white rounded shadow-xl w-2/3 2xl:w-5/12 max-h-[80vh] transition-transform transform duration-300 flex flex-col">

                        {/* Header */}
                        <div className="px-6 py-4 flex justify-between items-center border-b sticky top-0 bg-white z-10">
                            <h2 className="text-lg font-semibold text-gray-800">
                                Save Customer Info
                            </h2>
                            <button
                                className="text-gray-400 hover:text-gray-500"
                                onClick={handleClose}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>

                        {/* Scrollable Content */}
                        <div className="p-4 overflow-y-auto max-h-[calc(80vh-144px)]">
                            <form className="space-y-4">
                                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                                    <div>
                                        <label className="text-gray-700" htmlFor="firstName">
                                            First Name <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            onChange={handleChangeFirstName}
                                            value={firstName}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="First Name"
                                            id="firstName"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.firstName}</p>
                                    </div>
                                    <div>
                                        <label className="text-gray-700" htmlFor="lastName">
                                            Last Name <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            onChange={handleChangeLastName}
                                            value={lastName}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Last Name"
                                            id="lastName"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.lastName}</p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                                    <div>
                                        <label className="text-gray-700" htmlFor="companyName">
                                            Company Name <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="companyName"
                                            onChange={handleChangeCompanyName}
                                            value={companyName}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Company Name"
                                            id="companyName"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.companyName}</p>
                                    </div>
                                    <div>
                                        <label className="text-gray-700" htmlFor="jobTitle">
                                            Job Title <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="jobTitle"
                                            onChange={handleChangeJobTitle}
                                            value={jobTitle}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Job Title"
                                            id="jobTitle"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.jobTitle}</p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                                    <div>
                                        <label className="text-gray-700" htmlFor="email">
                                            Email <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            onChange={handleChangeEmail}
                                            value={email}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Email"
                                            id="email"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.email}</p>
                                    </div>
                                    <div>
                                        <label className="text-gray-700" htmlFor="phone">
                                            Phone <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="phone"
                                            onChange={handleChangePhone}
                                            value={phone}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Phone"
                                            id="phone"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.phone}</p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                                    <div>
                                        <label className="text-gray-700" htmlFor="department">
                                            Department <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="department"
                                            onChange={handleChangeDepartment}
                                            value={department}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Department"
                                            id="department"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.department}</p>
                                    </div>
                                    <div>
                                        <label className="text-gray-700" htmlFor="location">
                                            Location <span className="text-red-500 font-bold">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="location"
                                            onChange={handleChangeLocation}
                                            value={location}
                                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                            placeholder="Location"
                                            id="location"
                                        />
                                        <p className="text-red-500 text-xs mt-1">{formError.location}</p>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className="text-gray-700">Select Tools <span className="text-red-500 font-bold">*</span></label>
                                    <div className="flex space-x-8 mt-2">
                                        {['PowerBI', 'DOMO', 'Tableau'].map((tool) => (
                                            <div key={tool} className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    id={tool}
                                                    value={tool}
                                                    checked={selectedTools.includes(tool)}
                                                    onChange={handleToolSelect}
                                                    className="mr-2 h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500"
                                                />
                                                <label htmlFor={tool} className="text-gray-700">{tool}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formError.tool && <p className="text-red-500 text-xs mt-1">{formError.tool}</p>}
                                </div>
                            </form>
                        </div>

                        <div className="flex justify-end border-t border-gray-200 p-4 sticky bottom-0 bg-white z-10 gap-4">
                            <button
                                onClick={handleClose}
                                className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={saveInfo}
                                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default OrgsList;
