import { React, useState, useEffect } from 'react';
import { PiPaintBucket } from "react-icons/pi";
import { FaRegBookmark } from "react-icons/fa6";
import { FiUpload } from 'react-icons/fi';
import { FaRegImage } from 'react-icons/fa';
import { IoSearchOutline } from 'react-icons/io5';
import CompanyLogo from '../../assets/companyLogo.png';
import { IoAdd } from "react-icons/io5";
import { LuLayoutDashboard } from 'react-icons/lu';
import { IoAnalytics, IoSettingsOutline, IoTicketOutline } from 'react-icons/io5';
import { IoInformationCircleOutline } from "react-icons/io5";

import { PiWrench } from "react-icons/pi";
import { LuUser2 } from "react-icons/lu";
import { HiOutlineUsers } from "react-icons/hi2";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineAttachEmail } from "react-icons/md";

import { FaUsers, FaRegCalendarAlt } from 'react-icons/fa';
import { IoShareSocialOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { IoEyeOutline } from 'react-icons/io5';
import { LuChevronsLeft, LuChevronsRight } from "react-icons/lu";

import powerbiLogo from '../../assets/powerbi.png'
import domoLogo from '../../assets/domo.png'

import userIcon from "../../assets/user.png";
import axios from 'axios';
import { baseURL } from '../../constants';
import jwt_decode from "jwt-decode";
import toast, { Toaster } from 'react-hot-toast';
import { useTheme } from '../../ThemeContext.js';
// import CompanyLogo from '../../assets/companyLogo.png'

const Branding = ({notfy}) => {

    const { selectedColor, setSelectedColor, previewColor, setPreviewColor } = useTheme();
    const [activeTab, setActiveTab] = useState('apperance');
    const [isPaletteOpen, setIsPaletteOpen] = useState(false);

    const [logo, setLogo] = useState(""); // Holds the logo image URL
    const [isUploading, setIsUploading] = useState(false); // Indicates if the image is being uploaded

    const token = localStorage.getItem("auth");
    const decoded = jwt_decode(token);
    const orgId = decoded.organisationId;


    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        // console.log(tabName);
    };

    const colorOptions = [
        { color: 'slate' },
        { color: 'red' },
        { color: 'orange' },
        { color: 'amber' },
        { color: 'yellow' },
        { color: 'lime' },
        { color: 'green' },
        { color: 'emerald' },
        { color: 'teal' },
        { color: 'cyan' },
        { color: 'sky' },
        { color: 'blue' }, // Default color
        { color: 'indigo' },
        { color: 'violet' },
        { color: 'purple' },
        { color: 'fuchsia' },
        { color: 'pink' },
        { color: 'rose' },
        { color: 'diactoGold' },
        { color: 'universalYellow' }
    ];

    const defaultLimitedColors = [11, 6, 13, 15].map(index => colorOptions[index]);

    const [limitedColorOptions, setLimitedColorOptions] = useState(defaultLimitedColors);

    // const handleColorChange = (color) => {
    //     setPreviewColor(color);
    //     setIsPaletteOpen(false);
    // };


    // const handleColorChange = (color) => {
    //     setPreviewColor(color);

    //     // Update limitedColorOptions to move the selected color to the first position
    //     const selectedColorOption = colorOptions.find(option => option.color === color);
    //     if (selectedColorOption) {
    //         setLimitedColorOptions(prevOptions => [
    //             selectedColorOption,
    //             ...prevOptions.filter(option => option.color !== color)
    //         ]);
    //     }
    // };

    const handleColorChange = (selectedColor) => {
        const updatedColors = [
            { color: selectedColor },
            ...limitedColorOptions.filter(option => option.color !== selectedColor)
        ].slice(0, 4);

        setLimitedColorOptions(updatedColors);
        setPreviewColor(selectedColor);
    };


    useEffect(() => {
        const fetchSavedTheme = async () => {
            try {
                const token = localStorage.getItem("auth");
                const decoded = jwt_decode(token);
                const org_id = decoded.organisationId;

                const response = await axios.get(`${baseURL}/getColorTheme/${org_id}`);
                const savedTheme = response.data.colorTheme;
                setSelectedColor(savedTheme);
                setPreviewColor(savedTheme);
            } catch (error) {
                // console.log("Error fetching saved theme:", error);
            }
        };

        fetchSavedTheme();
    }, []);

    // const handleLogoUpload = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //         toast.error("No file selected");
    //         return;
    //     }

    //     setIsUploading(true);
    //     const formData = new FormData();
    //     formData.append("image", file);

    //     try {
    //         const response = await axios.post(`${baseURL}/uploadOrgIcon/${orgId}`, formData);
    //         toast.success("Logo uploaded successfully!");
    //         fetchLogo(); // Retrieve the newly uploaded logo
    //     } catch (error) {
    //         toast.error("Error uploading logo. Please try again.");
    //         console.error("Error uploading logo:", error);
    //     } finally {
    //         setIsUploading(false);
    //     }
    // };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            toast.error("No file selected");
            return;
        }

        setIsUploading(true);
        const formData = new FormData();
        formData.append("image", file);

        try {
            const response = await axios.post(`${baseURL}/uploadOrgIcon/${orgId}`, formData);
            toast.success("Logo uploaded successfully!");
            window.location.reload(); // Refresh the page to reflect the changes
        } catch (error) {
            toast.error("Error uploading logo. Please try again.");
            console.error("Error uploading logo:", error);
        } finally {
            setIsUploading(false);
        }
    };



    const fetchLogo = async () => {
        try {
            const response = await axios.get(`${baseURL}/retrieveOrgIcon/${orgId}`);
            setLogo(response.data.dataUrl);
        } catch (error) {
            console.error("Error retrieving logo:", error);
            toast.error("Error retrieving logo. Please try again.");
        }
    };

    useEffect(() => {
        fetchLogo();
    }, []);

    const saveAndApply = async () => {
        try {
            const token = localStorage.getItem("auth");
            const decoded = jwt_decode(token);
            const org_id = decoded.organisationId;

            const themeData = {
                org_id: org_id,
                colors: previewColor,
            };

            await axios.post(`${baseURL}/saveColorTheme`, themeData);
            setSelectedColor(previewColor); // Update the selected color after saving
            toast.success('Theme saved and applied successfully!');
        } catch (error) {
            // console.log("Error saving theme:", error);
            toast.error('Failed to save theme.');
        }
    };

    return (
        <>
            <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
                Branding
            </label>

            <div className="mt-8 flex justify-between items-center border-b border-gray-200">
                <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
                    <button
                        className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'apperance'
                            ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                            : 'text-gray-700 border-b-2 border-transparent'
                            } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                        onClick={() => handleTabClick('apperance')}
                    >
                        <PiPaintBucket className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
                        <span className="mx-1 text-xs sm:text-sm font-medium">
                            Appearance
                        </span>
                    </button>
                </div>

                <div className="flex space-x-5">
                    <button
                        type="button"
                        className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-4 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                        onClick={saveAndApply}
                    >
                        <FaRegBookmark className="w-4 h-4 mr-2" />
                        <span className="text-sm">Save & Apply</span>
                    </button>
                </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow mt-6 ">
                <div className="grid grid-cols-3 gap-8 2xl:p-6">

                    {/* Branding Component */}
                    <div className="col-span-1">
                        <h2 className="text-md font-semibold mb-4 text-gray-700">Organization Logo</h2>

                        <div className="flex items-center mb-6">
                            <div className="w-64 h-24 2xl:w-36 2xl:h-20 bg-gray-100 rounded flex items-center justify-center mr-4">
                                {logo ? (
                                    <img src={logo} alt="Logo" className="w-auto h-6" />
                                ) : (
                                    <img src={CompanyLogo} alt="Logo" className="w-auto h-6" />
                                )}
                            </div>
                            <div className='my-2'>
                                <label htmlFor="logo-upload" className={`text-${selectedColor}-500 flex items-center gap-2 mb-2 cursor-pointer`}>
                                    <FiUpload className="w-4 h-4" />
                                    <span className='text-sm'>{isUploading ? "Uploading..." : "Upload"}</span>
                                </label>
                                <input
                                    id="logo-upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoUpload}
                                    className="hidden"
                                    disabled={isUploading}
                                />
                                <p className="text-xs text-gray-500">
                                    Upload your company logo to display it on your profile. Ensure the image is clear and properly sized.
                                </p>
                            </div>
                        </div>

                        <h2 className="text-md font-semibold mb-2 text-gray-700">Dashboard theme</h2>
                        <p className="text-sm text-gray-600 mb-3">Choose preset color or "<span><IoAdd className='inline-block w-4 h-4 mb-1' /></span>" add your brand color</p>

                        {/* <div className="flex space-x-4 mb-6">
                            {limitedColorOptions.map(({ color }) => (
                                <button
                                    key={color}
                                    className={`w-12 h-12 rounded-full relative overflow-hidden ${previewColor === color ? `ring-2 ring-offset-2 ring-${color}-500` : ''}`}
                                    onClick={() => handleColorChange(color)}
                                >
                                    <div className={`absolute top-0 left-0 right-0 h-1/2 bg-${color}-100`}></div>
                                    <div className={`absolute bottom-0 left-0 right-0 h-1/2 bg-${color}-500`}></div>
                                </button>
                            ))}
                        </div>
                        <div className="relative">
                            <button
                                className="w-12 h-12 rounded-full bg-white border-2 border-gray-300 flex items-center justify-center text-gray-400 text-xl font-semibold"
                                onClick={() => setIsPaletteOpen(!isPaletteOpen)}
                            >
                                <IoAdd className='w-5 h-5 font-medium' />
                            </button>

                            {isPaletteOpen && (
                                <div className="absolute top-full left-0 mt-2 bg-white p-4 rounded-lg shadow-lg z-10">
                                    <div className="grid grid-cols-4 gap-2">
                                        {colorOptions.map(({ color }) => (
                                            <button
                                                key={color}
                                                className={`w-12 h-12 rounded-full relative overflow-hidden ${previewColor === color ? `ring-2 ring-offset-2 ring-${color}-500` : ''}`}
                                                onClick={() => {
                                                    handleColorChange(color);
                                                    setIsPaletteOpen(false);
                                                }}
                                            >
                                                <div className={`absolute top-0 left-0 right-0 h-1/2 bg-${color}-100`}></div>
                                                <div className={`absolute bottom-0 left-0 right-0 h-1/2 bg-${color}-500`}></div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div> */}


                        <div className="flex space-x-4 mb-6">
                            {limitedColorOptions.map(({ color }) => (
                                <button
                                    key={color}
                                    className={`2xl:w-12 2xl:h-12 w-10 h-10 rounded-full relative overflow-hidden ${previewColor === color ? `ring-2 ring-offset-2 ring-${color}-500` : ''}`}
                                    onClick={() => handleColorChange(color)}
                                >
                                    <div className={`absolute top-0 left-0 right-0 h-1/2 bg-${color}-100`}></div>
                                    <div className={`absolute bottom-0 left-0 right-0 h-1/2 bg-${color}-500`}></div>
                                </button>
                            ))}
                            <div className="relative z-20">
                                <button
                                    className="2xl:w-12 2xl:h-12 w-10 h-10 rounded-full bg-white border-2 border-gray-300 flex items-center justify-center text-gray-400 text-xl font-semibold"
                                    onClick={() => setIsPaletteOpen(!isPaletteOpen)}
                                >
                                    <IoAdd className='w-5 h-5 font-medium' />
                                </button>

                                {isPaletteOpen && (
                                    <div className="absolute top-full left-0 mt-2 bg-white p-4 rounded-lg shadow-lg z-30 2xl:w-72 w-64">
                                        <div className="grid grid-cols-4 gap-2">
                                            {colorOptions.map(({ color }) => (
                                                <button
                                                    key={color}
                                                    className={`2xl:w-14 2xl:h-14 w-12 h-12 rounded-full relative overflow-hidden ${previewColor === color ? `ring-2 ring-offset-2 ring-${color}-500` : ''}`}
                                                    onClick={() => {
                                                        handleColorChange(color);
                                                        setIsPaletteOpen(false);
                                                    }}
                                                >
                                                    <div className={`absolute top-0 left-0 right-0 h-1/2 bg-${color}-100`}></div>
                                                    <div className={`absolute bottom-0 left-0 right-0 h-1/2 bg-${color}-500`}></div>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    {/* Preview Component */}
                    <div className='col-span-2 ml-10 bg-neutral-50 py-3 px-5 rounded-md'>
                        <h2 className="text-md font-semibold mb-4 text-gray-700">Preview</h2>
                        <div className="border border-gray-200 ">
                            <div className="flex overflow-hidden">
                                <aside className="flex">
                                    <div className="flex flex-col items-center w-12 2xl:w-16 py-8 bg-white">
                                        <nav className="flex flex-col items-center flex-1 space-y-8">
                                            <div className="relative rounded p-2">
                                                {logo ? (
                                                    <img className="w-full h-full object-contain" src={logo} alt="Logo" />
                                                ) : (
                                                    <img className="w-full h-full object-contain" src={CompanyLogo} alt="Logo" />
                                                )}
                                            </div>

                                            <button
                                                className={`p-1.5 inline-block transition-colors duration-200 rounded-lg text-gray-900                  
                                                    `}
                                            >
                                                <LuLayoutDashboard className="w-5 h-5 2xl:w-6 2xl:h-6" />
                                            </button>
                                            <button
                                                className={`p-1.5 inline-block transition-colors duration-200 rounded-lg text-gray-900                  
                                                    `}
                                            >
                                                <IoAnalytics className="w-5 h-5 2xl:w-6 2xl:h-6" />
                                            </button>
                                            <button
                                                className={`p-1.5 inline-block transition-colors duration-200 rounded-lg text-white bg-${previewColor}-500                  
                                                    `}
                                            >
                                                <IoSettingsOutline className="w-5 h-5 2xl:w-6 2xl:h-6" />
                                            </button>
                                            <button
                                                className={`p-1.5 inline-block transition-colors duration-200 rounded-lg text-gray-900                  
                                                    `}
                                            >
                                                <IoInformationCircleOutline className="w-5 h-5 2xl:w-6 2xl:h-6" />
                                            </button>
                                        </nav>
                                        {/* Profile image */}
                                        <div className="flex flex-col items-center mt-4">
                                            <img
                                                className="object-cover w-8 h-8 rounded-full"
                                                src={userIcon}
                                                alt="avatar"
                                            />
                                        </div>
                                    </div>
                                    <div className={`px-5 py-8 overflow-y-auto bg-${previewColor}-50 border-l border-r w-52 2xl:w-64 lg:overflow-y-scroll`}>

                                        <div className="flex justify-between items-center px-3 mb-5">
                                            <label className="text-2xl text-gray-900 capitalize font-medium">
                                                Settings
                                            </label>
                                            <button

                                                className={`rounded-sm p-1 bg-${previewColor}-500 text-white hover:bg-${previewColor}-600 cursor-pointer`}
                                            >
                                                <LuChevronsLeft className="w-5 h-5" />
                                            </button>
                                        </div>

                                        {/* Search input for settings and dashboard tabs */}

                                        <div className="relative mt-5 mb-5">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <IoSearchOutline className="w-5 h-5 text-gray-400" />
                                            </span>
                                            <input
                                                type="text"
                                                className={`w-full py-1.5 pl-10 pr-4 text-gray-700 bg-${previewColor}-100 border rounded-md focus:border-${previewColor}-400 focus:ring-${previewColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                placeholder="Search"
                                            />
                                        </div>

                                        <nav className="mt-5 -mx-3 space-y-3">
                                            <div className="space-y-3">
                                                <div className="flex flex-col items-start px-3 py-2 transition-colors duration-300 transform rounded-lg">
                                                    {/* Icon and label */}
                                                    <div className="flex flex-col items-left">
                                                        <div className='flex flex-row gap-1'>
                                                            <PiPaintBucket className="w-5 h-5 text-gray-700" />
                                                            <span className="mx-2 text-sm font-medium">Branding</span>
                                                        </div>
                                                        {/* Description */}
                                                        <p className="text-xs transition-colors duration-300 transform text-gray-400 mt-3 leading-5">
                                                            Customize your app's color scheme, logo, and login page banner for a unified brand identity.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-start px-3 py-2 transition-colors duration-300 transform rounded-lg">
                                                    {/* Icon and label */}
                                                    <div className="flex flex-col items-left">
                                                        <div className='flex flex-row gap-1'>
                                                            <PiWrench className="w-5 h-5 text-gray-700" />
                                                            <span className="mx-2 text-sm font-medium">Configure</span>
                                                        </div>
                                                        {/* Description */}
                                                        <p className="text-xs transition-colors duration-300 transform text-gray-400 mt-3 leading-5">
                                                            Adjust app settings and credentials for importing assets, tailoring functionality to your needs.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-start px-3 py-2 transition-colors duration-300 transform rounded-lg">
                                                    {/* Icon and label */}
                                                    <div className="flex flex-col items-left">
                                                        <div className='flex flex-row gap-1'>
                                                            <LuUser2 className="w-5 h-5 text-gray-700" />
                                                            <span className="mx-2 text-sm font-medium">Manage Users</span>
                                                        </div>
                                                        {/* Description */}
                                                        <p className="text-xs transition-colors duration-300 transform text-gray-400 mt-3 leading-5">
                                                            Add, edit, or remove users and control their access permissions.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-start px-3 py-2 transition-colors duration-300 transform rounded-lg ">
                                                    {/* Icon and label */}
                                                    <div className="flex flex-col items-left">
                                                        <div className='flex flex-row gap-1'>
                                                            <HiOutlineUsers className="w-5 h-5 text-gray-700" />
                                                            <span className="mx-2 text-sm font-medium">Manage Groups</span>
                                                        </div>
                                                        {/* Description */}
                                                        <p className="text-xs transition-colors duration-300 transform text-gray-400 mt-3 leading-5">
                                                            Organize users into groups and manage group-level permissions.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={`flex flex-col items-start px-3 py-2 transition-colors duration-300 transform rounded-lg bg-${previewColor}-500 text-white`}>
                                                    {/* Icon and label */}
                                                    <div className="flex flex-col items-left">
                                                        <div className='flex flex-row gap-1'>
                                                            <MdOutlineDashboardCustomize className="w-5 h-5 text-white" />
                                                            <span className="mx-2 text-sm font-medium">Add Asset</span>
                                                        </div>
                                                        {/* Description */}
                                                        <p className="text-xs transition-colors duration-300 transform text-white mt-3 leading-5">
                                                            Add and manage assets like Dashboards and Tables.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-start px-3 py-2 transition-colors duration-300 transform rounded-lg ">
                                                    {/* Icon and label */}
                                                    <div className="flex flex-col items-left">
                                                        <div className='flex flex-row gap-1'>
                                                            <MdOutlineAttachEmail className="w-5 h-5 text-gray-700" />
                                                            <span className="mx-2 text-sm font-medium">SMTP</span>
                                                        </div>
                                                        {/* Description */}
                                                        <p className="text-xs transition-colors duration-300 transform text-gray-400 mt-3 leading-5">
                                                            Configure email server settings for sending notifications and alerts.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </nav>

                                    </div>
                                </aside>

                                <main className="flex-1 overflow-y-auto">
                                    <div className='p-4 pt-5'>
                                        <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
                                            Dashboards
                                        </label>

                                        <div className="mt-3 flex justify-between items-center border-b border-gray-200 pb-3">
                                            <div className="relative flex-grow mr-2 max-w-[40%]">
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-2 sm:pl-3">
                                                    <IoSearchOutline className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="w-full py-1 sm:py-1.5 pl-8 sm:pl-10 pr-2 sm:pr-4 text-xs sm:text-sm text-gray-700 bg-gray-50 border rounded outline-none"
                                                    placeholder="Search Dashboard"
                                                    disabled
                                                />
                                            </div>
                                            <div className="flex-shrink-0">
                                                <button
                                                    type="button"
                                                    className={`inline-flex justify-center items-center rounded bg-${previewColor}-500 px-2 py-1 2xl:px-4 2xl:py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${previewColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${previewColor}-600`}
                                                >
                                                    <IoAdd className="w-4 h-4 2xl:w-4 2xl:h-4 2xl:mr-2" />
                                                    <span className="text-xs 2xl:text-sm">Add Dashboard</span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="py-4 px-2 flex flex-col gap-5">
                                            <div className="">
                                                <div className="bg-white shadow-md rounded-lg px-5 pt-5 pb-4">
                                                    <div className="flex justify-between items-center mb-3">
                                                        <img
                                                            src={powerbiLogo}
                                                            alt="Dashboard Name"
                                                            className="w-8 h-8"
                                                        />
                                                        <input
                                                            type="checkbox"
                                                            className={`form-checkbox h-4 w-4 text-gray-600`}
                                                            disabled
                                                        />
                                                    </div>
                                                    <h3 className="text-sm 2xl:text-md font-semibold text-gray-600 truncate">Dashboard Name</h3>
                                                    <p className="text-xs 2xl:text-sm text-slate-400 mt-2">Owned by Jane Gray</p>
                                                    <div className="flex items-center text-slate-400 mt-2 text-xs 2xl:text-md gap-1">
                                                        <FaUsers className="w-4 h-4 mr-1" />
                                                        <span>10 users</span>
                                                    </div>
                                                    <div className="flex items-center text-slate-400 mt-2 text-xs 2xl:text-md gap-1">
                                                        <FaRegCalendarAlt className="w-4 h-4 mr-1" />
                                                        <span>2023-08-20</span>
                                                    </div>
                                                    <div className="flex justify-end items-center mt-4 gap-4 border-t">
                                                        <div className="flex items-center mt-3 gap-4">
                                                            <IoShareSocialOutline
                                                                className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                                                            />
                                                            <BiEditAlt
                                                                className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                                                            />
                                                            <div className={`flex items-center text-blue-500 hover:text-blue-600 cursor-pointer`}>
                                                                <IoEyeOutline className="w-4 h-4 mr-1" />
                                                                <span className="text-sm">View</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="bg-white shadow-md rounded-lg px-5 pt-5 pb-4">
                                                    <div className="flex justify-between items-center mb-3">
                                                        <img
                                                            src={domoLogo}
                                                            alt="Dashboard Name"
                                                            className="w-8 h-8"
                                                        />
                                                        <input
                                                            type="checkbox"
                                                            className={`form-checkbox h-4 w-4 text-gray-600`}
                                                            disabled
                                                        />
                                                    </div>
                                                    <h3 className="text-sm 2xl:text-md font-semibold text-gray-600 truncate">Dashboard Name</h3>
                                                    <p className="text-xs 2xl:text-sm text-slate-400 mt-2">Owned by John Doe</p>
                                                    <div className="flex items-center text-slate-400 mt-2 text-xs 2xl:text-md gap-1">
                                                        <FaUsers className="w-4 h-4 mr-1" />
                                                        <span>5 users</span>
                                                    </div>
                                                    <div className="flex items-center text-slate-400 mt-2 text-xs 2xl:text-md gap-1">
                                                        <FaRegCalendarAlt className="w-4 h-4 mr-1" />
                                                        <span>2023-08-20</span>
                                                    </div>
                                                    <div className="flex justify-end items-center mt-4 gap-4 border-t">
                                                        <div className="flex items-center mt-3 gap-4">
                                                            <IoShareSocialOutline
                                                                className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                                                            />
                                                            <BiEditAlt
                                                                className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                                                            />
                                                            <div className={`flex items-center text-blue-500 hover:text-blue-600 cursor-pointer`}>
                                                                <IoEyeOutline className="w-4 h-4 mr-1" />
                                                                <span className="text-sm">View</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Branding;