// import React, { useEffect, useState, useRef } from "react";
// import { useParams } from "react-router-dom";
// //import axios from "axios";
// import { baseURL } from "../../constants";
// //import Iframe from "react-iframe";
// import { useCookies } from "react-cookie";
// import jwt_decode from "jwt-decode";
// import axios from "axios";
// import { FiSearch, FiMinus, FiPlus, FiMaximize2 } from 'react-icons/fi';
// import { TbArrowsLeftRight } from 'react-icons/tb';
// import { IoIosExpand } from "react-icons/io";
// import { TfiArrowsHorizontal } from "react-icons/tfi";
// import LoaderDashboard from "../../utils/LoaderDashboard";
// import "../../utils/LoaderDashboard.css";

// const ViewAnalyticsDashboard = () => {
//   const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
//   const [loadingDashboard, setLoadingDashboard] = useState(false)
//   const [urlObject, setUrlObject] = useState(null);
//   // const token = cookies?.auth;
//   const token = localStorage.getItem("auth");
//   // const decoded = jwt_decode(cookies.auth);
//   const decoded = localStorage.getItem("auth")
//     ? jwt_decode(localStorage.getItem("auth"))
//     : null;
//   const [zoomLevel, setZoomLevel] = useState(1);
//   const containerRef = useRef(null);
//   // const [iframe, setIframe] = useState([]);
//   //   const { id } = useParams();
//   // const dashBoardId = "";
//   // call api
//   //   function getDashboardInfo() {
//   //     axios
//   //       .get(`${baseURL}/domo/dashboard/${id}`)
//   //       .then(function (response) {
//   //         setIframe(response);
//   //       })
//   //       .catch(function (error) {
//   //         console.log(error);
//   //       });
//   //   }

//   window.addEventListener("message", receiveMessage, false);
//   function receiveMessage(event) {
//     //if we get some data back
//     if (event.data && event.data.params && event.data.params.height) {
//       document.getElementById("iframe1").style.height =
//         event.data.params.height;
//     }
//   }

//   async function getSrc() {
//     setLoadingDashboard(true)
//     try {

//       const res = await fetch(`${baseURL}/domo/analyticsDashboard`, {
//         method: "GET",
//         headers: {
//           // Here you can set any headers you want
//           accessToken: token,
//         },
//       }).catch((error) => setLoadingDashboard(false));
//       const blob = await res.blob();
//       setUrlObject(URL.createObjectURL(blob));
//       document.querySelector("iframe").setAttribute("src", urlObject);

//       axios.post(`${baseURL}/logEvent`, {
//         eventType: "View Analytics Dashboard",
//         // dashboardId: id,
//         userId: decoded.adminId,

//       }).then((data) => setLoadingDashboard(false)).catch(error => {
//         console.log("error : ", error)
//         setLoadingDashboard(false);
//       });

//     } catch (error) {
//       setLoadingDashboard(false)
//     }

//   }

//   // useEffect(() => {
//   //   //getDashboardInfo();
//   //   getSrc();
//   // }, []);

//   useEffect(() => {
//     //getDashboardInfo();
//     getSrc();
//   }, []);

//   const toggleFullscreen = () => {
//     let element;
//     if (element) {
//       if (element.requestFullscreen) {
//         element.requestFullscreen();
//       } else if (element.mozRequestFullScreen) {
//         element.mozRequestFullScreen();
//       } else if (element.webkitRequestFullscreen) {
//         element.webkitRequestFullscreen();
//       } else if (element.msRequestFullscreen) {
//         element.msRequestFullscreen();
//       }
//     }
//   };


//   const zoomIn = () => {
//     if (zoomLevel < 2) {
//       setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 2));
//     }
//   };

//   const zoomOut = () => {
//     setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 0.5)); // Set a min zoom level
//   };

//   const resetZoom = () => {
//     setZoomLevel(1); // Reset to 100%
//   };

//   useEffect(() => {
//     if (containerRef.current) {
//       containerRef.current.style.transform = `scale(${zoomLevel})`;
//       containerRef.current.style.transformOrigin = "center";
//     }
//   }, [zoomLevel]);


//   return (
//     <>
//       <div className="flex flex-col h-full">
//         {/* Toolbar Section */}
//         <div className="flex justify-end">
//           <div className="flex items-center space-x-6 bg-white p-1.5 rounded-md shadow w-auto">
//             <div className="flex items-center space-x-1 border-r pr-2">
//               <button className="p-1.5 hover:bg-gray-100 rounded" onClick={zoomOut}>
//                 <FiMinus className="text-gray-500 w-4 h-4" />
//               </button>
//               <span className="text-gray-700 text-sm">{Math.round(zoomLevel * 100)}%</span>
//               <button
//                 className="p-1.5 hover:bg-gray-100 rounded"
//                 onClick={zoomIn}
//                 disabled={zoomLevel >= 1}
//               >
//                 <FiPlus className={`w-4 h-4 ${zoomLevel >= 1 ? 'text-gray-300' : 'text-gray-500'}`} />
//               </button>
//             </div>
//             <button className="p-1.5 hover:bg-gray-100 rounded" onClick={resetZoom}>
//               <TfiArrowsHorizontal className="text-gray-500 w-4 h-4" />
//             </button>
//             <button className="p-1.5 hover:bg-gray-100 rounded" onClick={toggleFullscreen}>
//               <IoIosExpand className="text-gray-500 w-4 h-4" />
//             </button>
//           </div>
//         </div>

//         {/* Dashboard Section */}
//         <div className="flex flex-col justify-center items-center h-full bg-white p-2 mt-4">
//           {loadingDashboard ? (
//             <div className="flex justify-center items-center w-full h-full">
//               <LoaderDashboard />
//               <span className="ml-2">Loading Dashboard</span>
//             </div>
//           ) : (
//             <iframe
//               // ref={iframeRef}
//               id="iframe1"
//               src={urlObject}
//               className="w-full h-full"
//             ></iframe>
//           )}
//         </div>
//       </div>
//     </>

//   );
// };

// export default ViewAnalyticsDashboard;




import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { FiMinus, FiPlus } from 'react-icons/fi';
import { TfiArrowsHorizontal } from "react-icons/tfi";
import { IoIosExpand } from "react-icons/io";
import LoaderDashboard from "../../utils/LoaderDashboard";
import { baseURL } from "../../constants";
import "../../utils/LoaderDashboard.css";

const ViewAnalyticsDashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [urlObject, setUrlObject] = useState(null);
  const token = localStorage.getItem("auth");
  const decoded = localStorage.getItem("auth")
    ? jwt_decode(localStorage.getItem("auth"))
    : null;
  const [zoomLevel, setZoomLevel] = useState(1);
  const containerRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    getSrc();
  }, []);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.style.transform = `scale(${zoomLevel})`;
      iframeRef.current.style.transformOrigin = "center";
    }
  }, [zoomLevel]);

  async function getSrc() {
    setLoadingDashboard(true);
    try {
      const res = await fetch(`${baseURL}/domo/analyticsDashboard`, {
        method: "GET",
        headers: {
          accessToken: token,
        },
      });
      const blob = await res.blob();
      setUrlObject(URL.createObjectURL(blob));

      await axios.post(`${baseURL}/logEvent`, {
        eventType: "View Analytics Dashboard",
        userId: decoded.adminId,
      });
    } catch (error) {
      // console.log("Error:", error);
    } finally {
      setLoadingDashboard(false);
    }
  }

  const zoomIn = () => {
    if (zoomLevel < 2) {
      setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 2));
    }
  };

  const zoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 0.5));
  };

  const resetZoom = () => {
    setZoomLevel(1);
  };

  const toggleFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Toolbar Section */}
      <div className="flex justify-end">
        <div className="flex items-center space-x-6 bg-white p-1.5 rounded-md shadow w-auto">
          <div className="flex items-center space-x-1 border-r pr-2">
            <button className="p-1.5 hover:bg-gray-100 rounded" onClick={zoomOut}>
              <FiMinus className="text-gray-500 w-4 h-4" />
            </button>
            <span className="text-gray-700 text-sm">{Math.round(zoomLevel * 100)}%</span>
            <button
              className="p-1.5 hover:bg-gray-100 rounded"
              onClick={zoomIn}
              disabled={zoomLevel >= 2}
            >
              <FiPlus className={`w-4 h-4 ${zoomLevel >= 2 ? 'text-gray-300' : 'text-gray-500'}`} />
            </button>
          </div>
          <button className="p-1.5 hover:bg-gray-100 rounded" onClick={resetZoom}>
            <TfiArrowsHorizontal className="text-gray-500 w-4 h-4" />
          </button>
          <button className="p-1.5 hover:bg-gray-100 rounded" onClick={toggleFullscreen}>
          <IoIosExpand className="text-gray-500 w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Dashboard Section */}
      <div className="flex flex-col justify-center items-center h-full bg-white p-2 mt-4">
        {loadingDashboard ? (
          <div className="flex justify-center items-center w-full h-full">
            <LoaderDashboard />
            <span className="ml-2">Loading Dashboard</span>
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            id="iframe1"
            src={urlObject}
            className="w-full h-full"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default ViewAnalyticsDashboard;
