import React from "react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ user, children }) => {
  // console.log("protected route called", user, children);
  if (!user) {
    const auth = localStorage.getItem("auth");
    if(auth) {
      return children;
    }
    // console.log("inside if user not found");
    return <Navigate to="/" />;
  } 
    return children;
};

export default ProtectedRoute;