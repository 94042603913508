import { React, useState, useEffect } from 'react'
import LoginBg from '../../assets/loginBg.png'
import CompanyLogo from '../../assets/companyLogo.png'
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { MdOutlineErrorOutline } from 'react-icons/md';
import axios from "axios";
import { baseURL } from "../../constants";
import { useCookies } from "react-cookie";
import Bg from '../../assets/Diacto-Logo.jpg'

const ResetPassword = () => {
    const navigate = useNavigate();
    const [currentForm, setCurrentForm] = useState('verifyForm');
    const [formError, setFormError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [counter, setCounter] = useState(60);
    const [enableResendButton, setEnableResendButton] = useState(false);
    const [showError, setShowError] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies("auth");

    const [resetSuccess, setResetSuccess] = useState(false);
    const [redirectCountdown, setRedirectCountdown] = useState(5);

    const [verifyData, setVerifyData] = useState({
        companyName: "",
        email: "",
    });

    const [resetPassData, setResetPassData] = useState({
        otp: "",
        newPassword: "",
        confirmPassword: "",
    });

    useEffect(() => {
        if (currentForm === "otpSubmissionForm") {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter, currentForm]);

    useEffect(() => {
        if (counter === 0) {
            setEnableResendButton(true);
        }
    }, [counter]);

    const handleVerifyFormChange = (e) => {
        setVerifyData({ ...verifyData, [e.target.name]: e.target.value });
    };

    const handleResetPassFormChange = (e) => {
        setResetPassData({ ...resetPassData, [e.target.name]: e.target.value });
    };

    const verifyForm = (e) => {
        e.preventDefault();
        if (verifyData.email) {
            setFormError({});
            axios
                .post(`${baseURL}/generate-otp`, {
                    org: verifyData.email == "r.h@d.com" ? "diacto-support" : "diacto",
                    email: verifyData.email
                })
                .then(function (response) {
                    if (response.data === "OTP Sent") {
                        setFormError({})
                        setCurrentForm('otpSubmissionForm');
                    } else if (response.data === "User not found") {
                        setFormError({ email: response.data })
                    } else {
                        setFormError({ email: 'Something went wrong! Please try again' })
                    }
                }).catch(error => console.log(error))
        } else {
            const err = {};
            if (!verifyData.email) {
                err.email = "Please enter a valid email";
            }
            if (!verifyData.companyName) {
                err.companyName = "Organisation name is mandatory";
            }
            setFormError(err);
        }
    };

    // const otpSubmit = (e) => {
    //     e.preventDefault();
    //     const validated =
    //         resetPassData.otp &&
    //         resetPassData.newPassword &&
    //         resetPassData.confirmPassword &&
    //         resetPassData.newPassword.length >= 8 &&
    //         resetPassData.newPassword === resetPassData.confirmPassword;
    //     if (validated) {
    //         setFormError({});
    //         axios
    //             .post(`${baseURL}/verify-otp`, {
    //                 org: verifyData.companyName,
    //                 email: verifyData.email,
    //                 otp: resetPassData.otp,
    //                 password: resetPassData.newPassword
    //             })
    //             .then(function (response) {
    //                 if (response.data.message === "Password reset successfully") {
    //                     removeCookie("auth");
    //                     localStorage.removeItem("auth");
    //                     setSuccessMessage(true)
    //                     setTimeout(() => {
    //                         setSuccessMessage(false)
    //                         navigate("/");
    //                     }, 5000);
    //                 }
    //             }).catch(error => {
    //                 console.log(error)
    //                 setShowError(error.response.data)
    //             })
    //     } else {
    //         const err = {};
    //         if (!resetPassData.otp) {
    //             err.otp = "Please enter a valid OTP";
    //         }
    //         if (!resetPassData.newPassword) {
    //             err.newPassword = "Password cannot be empty";
    //         }
    //         if (!resetPassData.confirmPassword) {
    //             err.confirmPassword = "Password cannot be empty";
    //         }
    //         if (resetPassData.newPassword.length < 8) {
    //             err.newPassword = "Password Length should atleast be 8 characters";
    //         }
    //         if (
    //             resetPassData.newPassword &&
    //             resetPassData.newPassword !== resetPassData.confirmPassword
    //         ) {
    //             err.confirmPassword = "Password doesn't match";
    //         }
    //         setFormError(err);
    //     }
    // };

    const otpSubmit = (e) => {
        e.preventDefault();
        const validated =
            resetPassData.otp &&
            resetPassData.newPassword &&
            resetPassData.confirmPassword &&
            resetPassData.newPassword.length >= 8 &&
            resetPassData.newPassword === resetPassData.confirmPassword;
        if (validated) {
            setFormError({});
            axios
                .post(`${baseURL}/verify-otp`, {
                    org: verifyData.email == "r.h@d.com" ? "diacto-support" : "diacto",
                    email: verifyData.email,
                    otp: resetPassData.otp,
                    password: resetPassData.newPassword
                })
                .then(function (response) {
                    if (response.data.message === "Password reset successfully") {
                        removeCookie("auth");
                        localStorage.removeItem("auth");
                        setResetSuccess(true);
                        let countDown = 5;
                        const timer = setInterval(() => {
                            countDown--;
                            setRedirectCountdown(countDown);
                            if (countDown === 0) {
                                clearInterval(timer);
                                navigate("/");
                            }
                        }, 1000);
                    }
                }).catch(error => {
                    // console.log(error)
                    setShowError(error.response.data)
                })
        } else {
            const err = {};
            if (!resetPassData.otp) {
                err.otp = "Please enter a valid OTP";
            }
            if (!resetPassData.newPassword) {
                err.newPassword = "Password cannot be empty";
            }
            if (!resetPassData.confirmPassword) {
                err.confirmPassword = "Password cannot be empty";
            }
            if (resetPassData.newPassword.length < 8) {
                err.newPassword = "Password Length should atleast be 8 characters";
            }
            if (
                resetPassData.newPassword &&
                resetPassData.newPassword !== resetPassData.confirmPassword
            ) {
                err.confirmPassword = "Password doesn't match";
            }
            setFormError(err);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleResendOTP = () => {
        axios
            .post(`${baseURL}/generate-otp`, {
                org: verifyData.email == "r.h@d.com" ? "diacto-support" : "diacto",
                email: verifyData.email
            })
            .then(function (response) {
                if (response.data === "OTP Sent") {
                    // Handle successful OTP resend
                } else {
                    // Handle error
                }
            })
        setEnableResendButton(false);
        setCounter(60);
    };


    return (
        <>
            <div>
                <div className="flex justify-center h-screen bg-gray-50">
                <div className="w-1/2 h-full bg-cover lg:block bg-white">
                        <img className="object-contain w-6/12 2xl:w-6/12 h-full bg-opacity-30 mx-auto" src={Bg} alt="Side" />
                    </div>

                    <div className="w-2/3 flex items-center max-w-lg mx-auto lg:w-2/3 h-max my-auto p-12" style={{ width: '64rem' }}>
                        <div className="flex-1">
                            <div className="text-center">
                                {/* <div className="flex justify-center mx-auto">
                                    <img className="w-auto h-7 sm:h-8" src={CompanyLogo} alt="" />
                                </div> */}

                                <h2 className="mt-6 text-3xl font-bold text-gray-900">Reset Password</h2>
                                <p className="mt-3 text-gray-500">Enter your details to reset your password</p>
                            </div>

                            <div className="mt-8">
                                {resetSuccess ? (
                                    <>
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="w-24 h-24 mb-4 rounded-full bg-green-100 flex items-center justify-center animate-bounce">
                                                <svg className="w-16 h-16 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                            </div>
                                            <h2 className="text-2xl font-bold text-green-600 mb-2">Password Updated Successfully</h2>
                                            <p className="text-gray-600">Redirecting in {redirectCountdown} seconds...</p>
                                        </div>
                                        <div className="mt-6 text-center">
                                            <Link to="/" className="text-sm text-blue-500 hover:underline">
                                                Back to Login
                                            </Link>
                                        </div>
                                    </>

                                ) : (
                                    <>
                                        {currentForm === 'verifyForm' ? (
                                            <form onSubmit={verifyForm}>
                                                {/* <div>
                                                    <label htmlFor="companyName" className="block mb-2 text-sm text-gray-600">Organization URL <span className="text-red-600">*</span></label>
                                                    <input type="text" name="companyName" id="companyName" value={verifyData.companyName} onChange={handleVerifyFormChange} placeholder="Companyname.com" className="block w-full px-4 py-2 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    {formError.companyName && (
                                                        <p className="mt-1 text-xs text-red-500 flex items-center">
                                                            <MdOutlineErrorOutline className="mr-1" />
                                                            {formError.companyName}
                                                        </p>
                                                    )}
                                                </div> */}

                                                <div className="mt-4">
                                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600">Email <span className="text-red-600">*</span></label>
                                                    <input type="email" name="email" id="email" value={verifyData.email} onChange={handleVerifyFormChange} placeholder="Enter email" className="block w-full px-4 py-2 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    {formError.email && (
                                                        <p className="mt-1 text-xs text-red-500 flex items-center">
                                                            <MdOutlineErrorOutline className="mr-1" />
                                                            {formError.email}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="mt-6">
                                                    <button type="submit" className="w-full px-4 py-3 my-3 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                                        Verify
                                                    </button>
                                                </div>
                                            </form>
                                        ) : (
                                            <form onSubmit={otpSubmit}>
                                                <div>
                                                    <label htmlFor="otp" className="block mb-2 text-sm text-gray-600">Enter OTP <span className="text-red-600">*</span></label>
                                                    <input type="text" maxLength={6} name="otp" id="otp" value={resetPassData.otp} onChange={handleResetPassFormChange} placeholder="Enter 6 digit OTP" className="block w-full px-4 py-2 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    {formError.otp && (
                                                        <p className="mt-1 text-xs text-red-500 flex items-center">
                                                            <MdOutlineErrorOutline className="mr-1" />
                                                            {formError.otp}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="mt-4">
                                                    <label htmlFor="newPassword" className="block mb-2 text-sm text-gray-600">New Password <span className="text-red-600">*</span></label>
                                                    <div className="relative">
                                                        <input type={showPassword ? 'text' : 'password'} name="newPassword" id="newPassword" value={resetPassData.newPassword} onChange={handleResetPassFormChange} placeholder="Enter new password" className="block w-full px-4 py-2 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                        <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                                            {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                                                        </button>
                                                    </div>
                                                    {formError.newPassword && (
                                                        <p className="mt-1 text-xs text-red-500 flex items-center">
                                                            <MdOutlineErrorOutline className="mr-1" />
                                                            {formError.newPassword}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="mt-4">
                                                    <label htmlFor="confirmPassword" className="block mb-2 text-sm text-gray-600">Confirm Password <span className="text-red-600">*</span></label>
                                                    <div className="relative">
                                                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" id="confirmPassword" value={resetPassData.confirmPassword} onChange={handleResetPassFormChange} placeholder="Confirm new password" className="block w-full px-4 py-2 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                        <button type="button" onClick={toggleConfirmPasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                                            {showConfirmPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                                                        </button>
                                                    </div>
                                                    {formError.confirmPassword && (
                                                        <p className="mt-1 text-xs text-red-500 flex items-center">
                                                            <MdOutlineErrorOutline className="mr-1" />
                                                            {formError.confirmPassword}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="mt-6">
                                                    <button type="submit" className="w-full px-4 py-3 my-3 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                                        Reset Password
                                                    </button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    {!enableResendButton && (
                                                        <span className="text-sm text-gray-500 mr-2">{counter}s</span>
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={handleResendOTP}
                                                        className={`text-sm ${enableResendButton ? 'text-blue-500 hover:underline' : 'text-gray-400 cursor-not-allowed'}`}
                                                        disabled={!enableResendButton}
                                                    >
                                                        Resend OTP
                                                    </button>
                                                </div>
                                            </form>
                                        )}

                                        <div className="mt-2 text-center">
                                            <Link to="/" className="text-sm text-blue-500 hover:underline">
                                                Back to Login
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword