import { React, useState, useEffect } from 'react'
import { IoSearchOutline } from "react-icons/io5";
import { useDebounce } from 'use-debounce';
import { IoGridOutline } from "react-icons/io5";
import { IoTrashOutline } from 'react-icons/io5';
import { IoIosAdd } from "react-icons/io";
import { BsTable } from "react-icons/bs";
import { IoEyeOutline } from 'react-icons/io5';
import { FaUsers, FaRegCalendarAlt } from 'react-icons/fa';
import { IoShareSocialOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import axios from "axios";
import { baseURL } from "../../constants";
import tableauLogo from '../../assets/tableau.png'
import powerbiLogo from '../../assets/powerbi.png'
import domoLogo from '../../assets/domo.png'
import * as XLSX from "xlsx";
import { useNavigate, useOutletContext } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { CiBoxList } from "react-icons/ci";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { GoTrash } from "react-icons/go";

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: `#f1f5f9`
    , // Optional: Change column headers background color
    borderBottom: '1px solid #dbeafe', // Added border bottom
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: `#f1f5f9`
    , // Tailwind CSS bg-grey-50 equivalent
  },
  '& .user-column-bold': {
    fontWeight: 'bold',
  },
});


const DashboardTab = () => {
  const { selectedColor } = useTheme();
  const [action, setAction, setReRenderSidebar] = useOutletContext();
  const [excludeUsers, setExcludeUsers] = useState([]); //updated
  const [excludeUsersOptions, setExcludeUsersOptions] = [];
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [groupDetails, setGroupDetails] = useState([]);
  const [shareModalShow, setShareModalShow] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);
  const [filteredDashboards, setFilteredDashboards] = useState([...dashboards]);
  const [dashboardEditId, setDashboardEditId] = useState("");
  const [isDashboardEdit, setIsDashboardEdit] = useState(false);
  const [trustedAttributeValue, setTrustedAttributeValue] = useState("Email");
  const [deleteDashboardObj, setDeleteDashboardObj] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [trustedAttributeColumnName, setTrustedAttributeColumnName] =
    useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardEmbedId, setDashboardEmbedId] = useState("");
  const [isTrustedChecked, setIsTrustedChecked] = useState(false);
  const [isTableauTrustedChecked, setIsTableauTrustedChecked] = useState(false);
  const [formError, setFormError] = useState({});
  const [userSearch, setUserSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [isDynamicGroup, setIsDynamicGroup] = useState(false);
  const [isClosedGroup, setIsClosedGroup] = useState(false);
  const [powerbiDashboardName, setPowerbiDashboardName] = useState("");
  const [reportId, setReportId] = useState("");
  const [workspaceId, setWorkspaceId] = useState("");
  const [dashboardRole, setDashboardRole] = useState("");
  const [reportType, setReportType] = useState("");
  const [tableauDashboardName, setTableauDashboardName] = useState("");
  const [tableauDashboardEmbedLink, setTableauDashboardEmbedLink] =
    useState("");
  const [
    tableauTrustedAttributeColumnName,
    setTableauTrustedAttributeColumnName,
  ] = useState("");
  const [tableauTrustedAttributeValue, setTableauTrustedAttributeValue] =
    useState("");
  const [importedData, setImportedData] = useState([]);
  const [excel, setExcel] = useState("");
  const decoded = jwt_decode(localStorage.getItem("auth"));
  const [missingColumns, setMissingColumns] = useState([]);
  const requiredFields = ["Roles", "Email"];
  const [hasDomo, setHasDomo] = useState(false);
  const [hasPowerBI, setHasPowerBI] = useState(false);
  const [hasTableau, setHasTableau] = useState(false);
  // const [flag, setFlag] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState({
    label: "Select a Dashboard",
    value: "",
  });
  const rolesMapping = [
    {
      Roles: "",
      Email: "",
    },
  ];
  let flag = 0;

  let history = useNavigate();
  const colourStylesRow = {
    control: (styles) => ({
      ...styles,
      borderRadius: "6px",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "black",
      fontSize: "0px",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      width: "0px",
    }),
  };

  //updated - calling getAllUsers function
  useEffect(() => {
    const decodedToken = localStorage.getItem("auth")
      ? jwt_decode(localStorage.getItem("auth"))
      : null;
    flag = decodedToken.platformFlag;
    platformVisibility(flag);

    getDashboards();
    getAllUsers();
  }, []);

  //updated - added function to get list of users
  function getAllUsers() {
    axios
      .get(`${baseURL}/getAllUsersByOrgId`)
      .then(function (response) {
        // console.log("response.data.users", response.data.users);
        const usersData = response.data.users.filter((user) => {
          return user.role === 2;
        });
        // setUsers(response.data.users);
        setUsers(usersData);
        // const userOptions = response.data?.users.map((user) => ({
        //   value: user.id,
        //   label: user.first_name + " " + user.last_name,
        // }));
        const userOptions = usersData.map((user) => ({
          value: user.id,
          label: user.first_name + " " + user.last_name,
        }));
        setUserList(userOptions);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // console.log("userList", userDetails)

  // const animatedComponents = makeAnimated();

  const handleDashboardModalClose = () => {
    setDashboardName("");
    setDashboardEmbedId("");
    setTableauDashboardName("");
    setTableauDashboardEmbedLink("");
    setIsTrustedChecked(false);
    setIsTableauTrustedChecked(false);
    setTrustedAttributeColumnName("");
    setTableauTrustedAttributeColumnName("");
    setTableauTrustedAttributeValue("");
    setTrustedAttributeValue("");
    setPowerbiDashboardName("");
    setReportId("");
    setWorkspaceId("");
    setDashboardRole("");
    setReportType("");
    setShow(false);
    setIsDynamicGroup(false);
    setIsClosedGroup(false);
    modalPlatformSet(flag);
    // setSelectedPlatform("DOMO");
    setFormError({});
  };

  const handleShowModal = (modalId) => {
    //console.log("run")
    setShowModal(modalId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const handleRemoveUser = (userId) => {
  //   if (userId) {
  //     axios
  //       .delete(
  //         `${baseURL}/unshareDashboard/${deleteDashboardObj.id}/${userId}`
  //       )
  //       .then((response) => {
  //         axios
  //           .post(`${baseURL}/logAdminEvent`, {
  //             eventType: "dashboard unshared",
  //             info: `dashboardId = ${deleteDashboardObj.id}, user = ${userId}`,
  //           })
  //           .then((res) => { });
  //         getDashboards();
  //         handleShareDashboardClick(deleteDashboardObj.id);
  //         //handleShareCancel();
  //       });
  //   } else {
  //     //handleShareCancel();
  //   }
  // };



  const handleRemoveUser = (userId) => {
    if (userId) {
      axios
        .delete(
          `${baseURL}/unshareDashboard/${deleteDashboardObj.id}/${userId}`
        )
        .then((response) => {
          axios
            .post(`${baseURL}/logAdminEvent`, {
              eventType: "dashboard unshared",
              info: `dashboardId = ${deleteDashboardObj.id}, user = ${userId}`,
            })
            .then((res) => { });
          getDashboards(); // Add this line
          handleShareDashboardClick(deleteDashboardObj.id);
        });
    }
  };

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
  };

  const handleRemoveGroup = (groupObject) => {
    if (groupObject) {
      axios
        .delete(
          `${baseURL}/unshareDashboardFromGroup/${deleteDashboardObj.id}/${groupObject.id}`
        )
        .then((response) => {
          axios
            .post(`${baseURL}/logAdminEvent`, {
              eventType: "dashboard unshared from group",
              info: `dashboardId = ${deleteDashboardObj.id}, groupId = ${groupObject.id}`,
            })
            .then((res) => { });
          getDashboards();
          handleShareDashboardClick(deleteDashboardObj.id);
          //handleShareCancel();
        });
    } else {
      //handleShareCancel();
    }
  };

  const handleShare = (selectedUsers) => {
    if (selectedUsers) {
      axios
        .post(`${baseURL}/shareDashboard`, {
          dashboardId: deleteDashboardObj.id,
          users: [{ id: selectedUsers.id }],
        })
        .then((response) => {
          axios
            .post(`${baseURL}/logAdminEvent`, {
              eventType: "dashboard shared",
              info: `dashboardId = ${deleteDashboardObj.id}, user = ${selectedUsers.id}`,
            })
            .then((res) => { });
          getDashboards();
          handleShareDashboardClick(deleteDashboardObj.id);
          //handleShareCancel();
        });
    } else {
      //handleShareCancel();
    }
  };

  const handleGroupShare = (selectedGroup) => {
    if (selectedGroup) {
      axios
        .post(`${baseURL}/shareDashboardToGroup`, {
          dashboardId: deleteDashboardObj.id,
          groupId: selectedGroup.id,
        })
        .then((response) => {
          axios
            .post(`${baseURL}/logAdminEvent`, {
              eventType: "dashboard shared to group",
              info: `dashboardId = ${deleteDashboardObj.id}, groupId = ${selectedGroup.id}`,
            })
            .then((res) => { });
          getDashboards();
          handleShareDashboardClick(deleteDashboardObj.id);
          //handleShareCancel();
        });
    }
  };

  // const deleteDashboard = (rowId, dashboardName) => {
  //   axios
  //     .delete(`${baseURL}/deleteDashboard/${rowId}`)
  //     .then(function (response) {
  //       axios
  //         .post(`${baseURL}/logAdminEvent`, {
  //           eventType: "dashboard deleted",
  //           info: `dashboardName = ${dashboardName}`,
  //         })
  //         .then((res) => { });
  //       setFormError({});
  //       getDashboards();
  //       setDeleteModalShow(false);
  //       setDeleteDashboardObj({});
  //       setAction({
  //         action: "Dashboard Deleted",
  //         value: dashboardEmbedId,
  //       });
  //     })
  //     .catch(function (error) {
  //       const err = {};
  //       err.deleteDashboard = error;
  //       setFormError(err);
  //     });
  // };

  const deleteDashboard = (rowId, dashboardName) => {
    axios
      .delete(`${baseURL}/deleteDashboard/${rowId}`)
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "dashboard deleted",
            info: `dashboardName = ${dashboardName}`,
          })
          .then((res) => {});
        setFormError({});
        getDashboards();
        setDeleteModalShow(false);
        setDeleteDashboardObj({});
        setAction({
          action: "Dashboard Deleted",
          value: dashboardEmbedId,
        });
      })
      .catch(function (error) {
        const err = {};
        err.deleteDashboard = error;
        setFormError(err);
      });
  };
  

  const handleShareCancel = () => {
    const newUserList = users.map((user) => ({
      value: user.id,
      label: user.first_name + " " + user.last_name,
    }));
    setUserList(newUserList);
    setDeleteDashboardObj({});
    setSelectedUsers([]);
    setShareModalShow(false);
  };


  // ==================== Get all Dashboard =============

  function getDashboards() {
    axios
      .get(`${baseURL}/getDashboardsById`)
      .then(function (response) {
        const dashboards = response.data.dashboards;
        // console.log("dashboards", dashboards);
        setDashboards(dashboards);
        setFilteredDashboards(dashboards);
        const dashboardOptions = dashboards.map((dashboard) => ({
          value: dashboard.id,
          label: dashboard.dashboard_name,
        }));
        setDashboardList(dashboardOptions);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const updateDashboard = () => {
    if (
      dashboardName &&
      dashboardEmbedId &&
      (!isTrustedChecked || trustedAttributeColumnName)
    ) {
      setFormError({});
      axios
        .put(`${baseURL}/editDashboard/${dashboardEditId}`, {
          dashboardName: dashboardName,
          dashboardEmbedId: dashboardEmbedId,
          isTrustedChecked: isTrustedChecked,
          trustedAttributeColumnName: isTrustedChecked
            ? trustedAttributeColumnName
            : null,
          trustedAttributeValue: isTrustedChecked
            ? trustedAttributeValue == ""
              ? "Email"
              : trustedAttributeValue
            : null,
          excludeUsers: excludeUsers,
        })
        .then((res) => {
          if (res.data.message == "Dashboard already exists") {
            alert("Dashboard with same Embed Id already exists.");
          } else {
            handleDashboardModalClose();
            getDashboards();
            setReRenderSidebar((reRenderSidebar) => {
              return !reRenderSidebar
            });
            axios
              .post(`${baseURL}/logAdminEvent`, {
                eventType: "dashboard updated",
                info: `dashboardId = ${dashboardEditId}`,
              })
              .then((res) => {
                setAction({
                  action: "Dashboard Edited",
                  value: dashboardEmbedId,
                });
              });
          }
        });
    } else {
      const err = {};
      if (!dashboardName) {
        err.dashboardName = "Dashboard Name is mandatory";
      }
      if (!dashboardEmbedId) {
        err.dashboardEmbedId = "Dashboard Embed Id is mandatory";
      }
      if (!trustedAttributeColumnName) {
        err.trustedAttributeColumnName =
          "Trusted Attribute Column Name is mandatory";
      }

      setFormError(err);
    }
  };

  const updateTableauDashboard = () => {
    if (
      tableauDashboardName &&
      tableauDashboardEmbedLink &&
      (!isTableauTrustedChecked || tableauTrustedAttributeColumnName)
    ) {
      setFormError({});
      axios
        .put(`${baseURL}/editTableauDashboard/${dashboardEditId}`, {
          dashboardName: tableauDashboardName,
          dashboardEmbedLink: tableauDashboardEmbedLink,
          isTrustedChecked: isTableauTrustedChecked,
          trustedAttributeColumnName: isTableauTrustedChecked
            ? tableauTrustedAttributeColumnName
            : null,
          trustedAttributeValue: isTableauTrustedChecked
            ? tableauTrustedAttributeValue == ""
              ? "Email"
              : tableauTrustedAttributeValue
            : null,
          // excludeUsers: excludeUsers,
        })
        .then((res) => {
          if (res.data.message == "Dashboard already exists") {
            alert("Dashboard with same Embed Id already exists.");
          } else {
            handleDashboardModalClose();
            getDashboards();
            axios
              .post(`${baseURL}/logAdminEvent`, {
                eventType: "dashboard updated",
                info: `dashboardId = ${dashboardEditId}`,
              })
              .then((res) => {
                setAction({
                  action: "Dashboard Edited",
                  value: dashboardEmbedId,
                });
              });
          }
        });
    } else {
      const err = {};
      if (!tableauDashboardName) {
        err.tableauDashboardName = "Dashboard Name is mandatory";
      }
      if (!tableauDashboardEmbedLink) {
        err.tableauDashboardEmbedLink = "Dashboard Embed Link is mandatory";
      }
      if (!tableauTrustedAttributeColumnName) {
        err.tableauTrustedAttributeColumnName =
          "Trusted Attribute Column Name is mandatory";
      }

      setFormError(err);
    }
  };

  const saveTableauDashboardInfo = () => {
    if (isTableauTrustedChecked) {
      // if trustedAttribute, validate all inputs else validate only dashboardName & embedId
      if (
        tableauDashboardName &&
        tableauDashboardEmbedLink &&
        tableauTrustedAttributeColumnName
      ) {
        setFormError({});
        // console.log("exclude users", excludeUsers);
        axios
          .post(`${baseURL}/saveTableauDashboardDetailsWithAttributes`, {
            dashboardName: tableauDashboardName,
            dashboardEmbedLink: tableauDashboardEmbedLink,
            trustedAttributeColumnName: tableauTrustedAttributeColumnName,
            trustedAttributeValue:
              trustedAttributeValue == []
                ? "Email"
                : tableauTrustedAttributeValue,
          })
          .then(function (response) {
            if (response.data.message == "Dashboard already exixts") {
              alert("Dashboard with same Embed Id already exists.");
            } else {
              const insertId = response.data.insertId;
              axios
                .post(`${baseURL}/logAdminEvent`, {
                  eventType: "dashboard saved",
                  info: `dashboardId = ${insertId}`,
                })
                .then((res) => {
                  setAction({
                    action: "Dashboard Added",
                    value: dashboardEmbedId,
                  });
                });
              getDashboards();
              handleDashboardModalClose();
              setReRenderSidebar((reRenderSidebar) => {
                return !reRenderSidebar
              });
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        const err = {};
        if (!tableauDashboardName) {
          err.tableauDashboardName = "Dashboard Name is mandatory";
        }
        if (!tableauDashboardEmbedLink) {
          err.tableauDashboardEmbedLink = "Dashboard Embed Link is mandatory";
        }
        if (!tableauTrustedAttributeColumnName) {
          err.tableauTrustedAttributeColumnName =
            "Trusted Attribute Column Name is mandatory";
        }

        setFormError(err);
      }
    } else {
      // If trustedAttribute is not checked, only validate dashboard name and embedId
      if (tableauDashboardName && tableauDashboardEmbedLink) {
        setFormError({});
        axios
          .post(`${baseURL}/saveTableauDashboardDetails`, {
            dashboardName: tableauDashboardName,
            dashboardEmbedLink: tableauDashboardEmbedLink,
          })
          .then(function (response) {
            if (response.data.message == "Dashboard already exixts") {
              alert("Dashboard with same Embed Id already exists.");
            } else {
              const insertId1 = response.data.insertId;
              axios
                .post(`${baseURL}/logAdminEvent`, {
                  eventType: "dashboard saved",
                  info: `dashboardId = ${insertId1}`,
                })
                .then((res) => {
                  setAction({
                    action: "Dashboard Added",
                    value: dashboardEmbedId,
                  });
                });
              getDashboards();
              handleDashboardModalClose();
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        const err = {};
        if (!tableauDashboardName) {
          err.tableauDashboardName = "Dashboard Name is mandatory";
        }
        if (!tableauDashboardEmbedLink) {
          err.tableauDashboardEmbedLink = "Dashboard Embed Link is mandatory";
        }

        setFormError(err);
      }
    }
  };

  // updated - passed excludeUsers to api
  function saveDashboardInfo() {
    if (isTrustedChecked) {
      // if trustedAttribute, validate all inputs else validate only dashboardName & embedId
      if (dashboardName && dashboardEmbedId && trustedAttributeColumnName) {
        setFormError({});
        // console.log("exclude users", excludeUsers);
        axios
          .post(`${baseURL}/saveDashboardDetailsWithAttributes`, {
            dashboardName: dashboardName,
            dashboardEmbedId: dashboardEmbedId,
            trustedAttributeColumnName: trustedAttributeColumnName,
            trustedAttributeValue:
              trustedAttributeValue == [] ? "Email" : trustedAttributeValue,
            excludeUsers: excludeUsers,
          })
          .then(function (response) {
            if (response.data.message == "Dashboard already exixts") {
              alert("Dashboard with same Embed Id already exists.");
            } else {
              const insertId = response.data.insertId;
              axios
                .post(`${baseURL}/logAdminEvent`, {
                  eventType: "dashboard saved",
                  info: `dashboardId = ${insertId}`,
                })
                .then((res) => {
                  setAction({
                    action: "Dashboard Added",
                    value: dashboardEmbedId,
                  });
                });
              getDashboards();
              handleDashboardModalClose();
              setReRenderSidebar((reRenderSidebar) => {
                return !reRenderSidebar
              });
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        const err = {};
        if (!dashboardName) {
          err.dashboardName = "Dashboard Name is mandatory";
        }
        if (!dashboardEmbedId) {
          err.dashboardEmbedId = "Dashboard Embed Id is mandatory";
        }
        if (!trustedAttributeColumnName) {
          err.trustedAttributeColumnName =
            "Trusted Attribute Column Name is mandatory";
        }

        setFormError(err);
      }
    } else {
      // If trustedAttribute is not checked, only validate dashboard name and embedId
      if (dashboardName && dashboardEmbedId) {
        setFormError({});
        axios
          .post(`${baseURL}/saveDashboardDetails`, {
            dashboardName: dashboardName,
            dashboardEmbedId: dashboardEmbedId,
          })
          .then(function (response) {
            if (response.data.message == "Dashboard already exixts") {
              alert("Dashboard with same Embed Id already exists.");
            } else {
              const insertId1 = response.data.insertId;
              axios
                .post(`${baseURL}/logAdminEvent`, {
                  eventType: "dashboard saved",
                  info: `dashboardId = ${insertId1}`,
                })
                .then((res) => {
                  setAction({
                    action: "Dashboard Added",
                    value: dashboardEmbedId,
                  });
                });
              getDashboards();
              handleDashboardModalClose();
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        const err = {};
        if (!dashboardName) {
          err.dashboardName = "Dashboard Name is mandatory";
        }
        if (!dashboardEmbedId) {
          err.dashboardEmbedId = "Dashboard Embed Id is mandatory";
        }

        setFormError(err);
      }
    }
  }

  function savePowerbiDashboardInfo() {
    if (reportType) {
      if (reportType == "ClosedGroup") {
        // if trustedAttribute, validate all inputs else validate only dashboardName & embedId
        if (powerbiDashboardName && reportId && workspaceId && dashboardRole) {
          setFormError({});
          axios
            .post(`${baseURL}/savePowerbiDashboardDetailsWithSingleRole`, {
              dashboardName: powerbiDashboardName,
              reportId: reportId,
              workspaceId: workspaceId,
              dashboardRole: dashboardRole,
            })
            .then(function (response) {
              if (response.data.message == "Dashboard already exixts") {
                alert("Dashboard with same Embed Id already exists.");
              } else {
                const insertId = response.data.insertId;
                axios
                  .post(`${baseURL}/logAdminEvent`, {
                    eventType: "dashboard saved",
                    info: `dashboardId = ${insertId}`,
                  })
                  .then((res) => {
                    setAction({
                      action: "Dashboard Added",
                      value: dashboardEmbedId,
                    });
                  });
                getDashboards();
                handleDashboardModalClose();
                setReRenderSidebar((reRenderSidebar) => {
                  return !reRenderSidebar
                });
              }
            })
            .catch(function (error) {
              // console.log(error);
            });
        } else {
          const err = {};
          if (!powerbiDashboardName) {
            err.powerbiDashboardName = "Dashboard Name is mandatory";
          }
          if (!reportId) {
            err.reportId = "Report Id is mandatory";
          }
          if (!workspaceId) {
            err.workspaceId = "Workspace Id is mandatory";
          }
          if (!dashboardRole) {
            err.dashboardRole = "Role is mandatory";
          }

          setFormError(err);
        }
      } else if (reportType == "DynamicGroup") {
        if (powerbiDashboardName && reportId && workspaceId) {
          setFormError({});
          if (importedData.length) {
            // Get Array of obj properties
            const objProperties = importedData.map((obj) =>
              Object.getOwnPropertyNames(obj)
            );
            const nullColumns = objProperties.map((x) =>
              requiredFields.filter((keys) => !x.includes(keys))
            );
            const result = nullColumns.reduce(
              (accumulator, value) => accumulator.concat(value),
              []
            );
            setMissingColumns(result);

            if (!result.length) {
              // console.log("inside if");
              const formData = new FormData();
              formData.append("excel", excel);
              const jsonData = {
                dashboardName: powerbiDashboardName,
                reportId: reportId,
                workspaceId: workspaceId,
              };
              formData.append("jsonData", JSON.stringify(jsonData));

              axios
                .post(
                  baseURL + "/savePowerbiDashboardDetailsWithDynamicRole",
                  formData,
                  {}
                )
                .then((res) => {
                  // console.log("RES", res);
                  // const usersCreated = res.data.successCount;
                  // axios
                  //   .post(`${baseURL}/logAdminEvent`, {
                  //     eventType: "bulk users created",
                  //     info: `users created = ${usersCreated}`,
                  //   })
                  //   .then((res) => {});
                  getDashboards();
                  handleDashboardModalClose();
                  setImportedData([]);
                  setExcel("");
                  // handleBulkClose();
                });
              // setValidData(importedData);
              // handleBulkClose();
              handleDashboardModalClose();
            } else {
              const err = {};
              err.InvalidData = "Invalid Data";
              setFormError(err);
            }
          } else {
            const err = {};
            err.FileNotFound = "Please select a file.";
            setFormError(err);
          }
        } else {
          const err = {};
          if (!powerbiDashboardName) {
            err.powerbiDashboardName = "Dashboard Name is mandatory";
          }
          if (!reportId) {
            err.reportId = "Report Id is mandatory";
          }
          if (!workspaceId) {
            err.workspaceId = "Workspace Id is mandatory";
          }

          setFormError(err);
        }
      }
    } else {
      const err = {};
      err.groupType = "Type Selection is mandatory";
      setFormError(err);
    }
  }

  function handleChangeDashboardName(event) {
    setDashboardName(event.target.value);
  }

  function handleChangeDashboardEmbedId(event) {
    setDashboardEmbedId(event.target.value);
  }

  const viewDashboard = (dashboardId, dashboardPlatform) => {
    // console.log("dashboardPlatform",dashboardPlatform)
    history(
      "/dashboard/view-dashboard/" + dashboardId + "/" + dashboardPlatform
    );
  };

  const handleShow = () => {
    // console.log("handleShow called");
    setShow(true);
  };

  const handleDashboardFilter = (selectedOption) => {
    const selected = { ...selectedDashboard };

    if (selectedOption) {
      const found = dashboards.find(
        (dashboard) =>
          dashboard.dashboard_name === selectedOption.label &&
          dashboard.id === selectedOption.value
      );
      setFilteredDashboards([found]);
      selected.value = selectedOption.value;
      selected.label = selectedOption.label;
    } else {
      selected.value = "";
      selected.label = "Select a Dashboard";
      setFilteredDashboards(dashboards);
    }
    setSelectedDashboard(selected);
  };

  const handleDeleteDashboardClick = (id) => {
    const foundDashboard = dashboards.find((dashboard) => dashboard.id === id);
    setDeleteDashboardObj(foundDashboard);
    setDeleteModalShow(true);
  };

  const handleShareDashboardClick = (id) => {
    axios
      .get(`${baseURL}/getUsersAssignedToDashboard/${id}`)
      .then((response) => {
        //console.log(response);
        setUserDetails(response?.data?.userDetails);
        setGroupDetails(response?.data?.groups);
        const foundDashboard = dashboards.find(
          (dashboard) => dashboard.id === id
        );
        setDeleteDashboardObj(foundDashboard);
        setShareModalShow(true);
      });
  };

  const editDashboard = async (dashboard) => {
    // console.log("dashboard", dashboard);
    setIsDashboardEdit(true);
    // console.log('dashboard', dashboard)
    setDashboardEditId(dashboard.id);
    if (dashboard.dashboard_platform_flag === 0) {
      if (dashboard.trusted_attribute == 1) {
        const res = await axios.get(
          `${baseURL}/getDashboardInfoById/${dashboard.id}`
        );
        // console.log('response', res)
        const filter_info = JSON.parse(res.data.dashboard[0].filter_info);
        if (filter_info) {
          setTrustedAttributeColumnName(filter_info[0]?.column);
          setTrustedAttributeValue(filter_info[0]?.values[0]);
        }
        //Add exclude users list to excludeUsers state
        setExcludeUsers(res.data.exclude_users ? res.data.exclude_users : []);
      }
      setDashboardName(dashboard.dashboard_name);
      setDashboardEmbedId(dashboard.dashboard_embed_id);
      setIsTrustedChecked(dashboard.trusted_attribute == 1 ? true : false);
      setSelectedPlatform("DOMO");
    } else if (dashboard.dashboard_platform_flag === 1) {
      // console.log('response',)
      if (dashboard.trusted_attribute == 1) {
        setPowerbiDashboardName(dashboard.dashboard_name);
        // console.log('inside power bi')
        setReportId(dashboard.dashboard_embed_id);
        setWorkspaceId(dashboard.workspace_id);
        setIsDynamicGroup(true);
        // setDashboardRole(dashboard.single_role);
        setSelectedPlatform("PowerBi");
      } else {
        setPowerbiDashboardName(dashboard.dashboard_name);
        setReportId(dashboard.dashboard_embed_id);
        setWorkspaceId(dashboard.workspace_id);
        setIsClosedGroup(true);
        setDashboardRole(dashboard.single_role);
        setSelectedPlatform("PowerBi");
      }
    } else if (dashboard.dashboard_platform_flag === 2) {
      if (dashboard.trusted_attribute == 1) {
        const res = await axios.get(
          `${baseURL}/getTableauDashboardInfoById/${dashboard.id}`
        );
        // console.log("res", res);
        const filter_info = JSON.parse(res.data.dashboard[0].filter_info);
        if (filter_info) {
          setTableauTrustedAttributeColumnName(filter_info[0]?.column);
          setTableauTrustedAttributeValue(filter_info[0]?.values[0]);
        }
        //Add exclude users list to excludeUsers state
        // setExcludeUsers(res.data.exclude_users ? res.data.exclude_users : []);
      }
      setTableauDashboardName(dashboard.dashboard_name);
      setTableauDashboardEmbedLink(dashboard.dashboard_embed_id);
      setIsTableauTrustedChecked(
        dashboard.trusted_attribute == 1 ? true : false
      );
      setSelectedPlatform("Tableau");
    }
    //setTrustedAttributeColumnName(filter_info.column);
    //setTrustedAttributeValue(filter_info.values[0]);
    handleShow();
  };

  const handleBulkDelete = (itemsToDelete) => {
    // console.log(itemsToDelete);
    axios
      .post(`${baseURL}/deleteDashboardsInBulk`, {
        idsToDelete: itemsToDelete,
      })
      .then((res) => {
        // console.log("res", res.data.message);
        getDashboards();
        handleCloseModal();
      });
  };

  // updated - updating excludeUsers on change
  const handleExcludeUsersSelect = (selectedOptions) => {
    // console.log("users to exclude : ", selectedOptions)
    setExcludeUsers(selectedOptions);
  };

  const handleChangePowerbiValues = (event) => {
    const credential = event.target.id;
    const value = event.target.value;
    if (credential === "powerbiDashboardName") {
      setPowerbiDashboardName(value);
      // console.log("reportType", powerbiDashboardName);
    } else if (credential === "reportId") {
      setReportId(value);
      // console.log("reportType", reportId);
    } else if (credential === "workspaceId") {
      setWorkspaceId(value);
      // console.log("reportType", workspaceId);
    } else if (credential === "dashboardRole") {
      setDashboardRole(value);
      // console.log("reportType", dashboardRole);
    }
  };

  const handleChangeTableauValues = (event) => {
    const credential = event.target.id;
    const value = event.target.value;
    if (credential === "tableauDashboardName") {
      setTableauDashboardName(value);
    } else if (credential === "tableauDashboardEmbedLink") {
      setTableauDashboardEmbedLink(value);
    }
  };

  const handleReportTypeChange = (event) => {
    const value = event.target.value;
    setReportType(value);
    // console.log("reportType", value);
  };

  // const savePowerbiDashboardInfo = () => {};

  const updatePowerbiDashboard = () => {
    if (isClosedGroup) {
      if (powerbiDashboardName && reportId && workspaceId && dashboardRole) {
        setFormError({});
        axios
          .put(`${baseURL}/editPowerbiDashboard/${dashboardEditId}`, {
            powerbiDashboardName: powerbiDashboardName,
            reportId: reportId,
            workspaceId: workspaceId,
            dashboardRole: dashboardRole,
          })
          .then((res) => {
            if (res.data.message == "Dashboard already exists") {
              alert("Dashboard with same Embed Id already exists.");
            } else {
              handleDashboardModalClose();
              getDashboards();
              axios
                .post(`${baseURL}/logAdminEvent`, {
                  eventType: "dashboard updated",
                  info: `dashboardId = ${dashboardEditId}`,
                })
                .then((res) => {
                  setAction({
                    action: "Dashboard Edited",
                    value: dashboardEmbedId,
                  });
                });
            }
          });
      } else {
        const err = {};
        if (!powerbiDashboardName) {
          err.powerbiDashboardName = "Dashboard Name is mandatory";
        }
        if (!reportId) {
          err.reportId = "Report Id is mandatory";
        }
        if (!workspaceId) {
          err.workspaceId = "Workspace Id is mandatory";
        }
        if (!dashboardRole) {
          err.dashboardRole = "Role is mandatory";
        }

        setFormError(err);
      }
    } else if (isDynamicGroup) {
      if (powerbiDashboardName && reportId && workspaceId) {
        setFormError({});
        const formData = new FormData();
        if (importedData.length) {
          // Get Array of obj properties
          const objProperties = importedData.map((obj) =>
            Object.getOwnPropertyNames(obj)
          );
          const nullColumns = objProperties.map((x) =>
            requiredFields.filter((keys) => !x.includes(keys))
          );
          const result = nullColumns.reduce(
            (accumulator, value) => accumulator.concat(value),
            []
          );
          setMissingColumns(result);

          if (!result.length) {
            // console.log("inside if");
            formData.append("excel", excel);
          }
        }
        const jsonData = {
          dashboardName: powerbiDashboardName,
          reportId: reportId,
          workspaceId: workspaceId,
        };
        formData.append("jsonData", JSON.stringify(jsonData));

        axios
          .put(
            `${baseURL}/updatePowerbiDashboardDetailsWithDynamicRole/${dashboardEditId}`,
            formData,
            {}
          )
          .then((res) => {
            // console.log("RES", res);
            getDashboards();
            handleDashboardModalClose();
            setImportedData([]);
            setExcel("");
            // handleBulkClose();
          });
        // setValidData(importedData);
        // handleBulkClose();
        // handleDashboardModalClose();

        // else {
        //   const err = {};
        //   err.InvalidData = "Invalid Data";
        //   setFormError(err);
        // }
        // else {
        //   const err = {};
        //   err.FileNotFound = "Please select a file.";
        //   setFormError(err);
        // }
      } else {
        const err = {};
        if (!powerbiDashboardName) {
          err.powerbiDashboardName = "Dashboard Name is mandatory";
        }
        if (!reportId) {
          err.reportId = "Report Id is mandatory";
        }
        if (!workspaceId) {
          err.workspaceId = "Workspace Id is mandatory";
        }

        setFormError(err);
      }
    }
  };

  const handleExport = (e) => {
    e.preventDefault();
    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rolesMapping);

    XLSX.utils.book_append_sheet(wb, ws, "Bulk Import Template");

    XLSX.writeFile(wb, "Roles_Mapping.xlsx");
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wbSheetName = wb.SheetNames[0];
        const ws = wb.Sheets[wbSheetName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      setImportedData(data);
      setExcel(file);
    });
  };

  function platformVisibility(platformFlag) {
    if (platformFlag === 1) {
      setHasDomo(true);
      setHasPowerBI(false);
      setHasTableau(false);
      setSelectedPlatform("DOMO");
      // setActiveTab("Domo");
      // getClientInfoById();
    } else if (platformFlag === 2) {
      setHasPowerBI(true);
      setHasDomo(false);
      setHasTableau(false);
      setSelectedPlatform("PowerBi");
      // setActiveTab("PowerBi");
      // getPowerBiClientInfoById();
    } else if (platformFlag === 3) {
      setHasPowerBI(false);
      setHasDomo(false);
      setHasTableau(true);
      setSelectedPlatform("Tableau");
      // setActiveTab("Tableau");
      // getTableauClientInfoById();
    } else if (platformFlag === 4) {
      setHasPowerBI(true);
      setHasDomo(true);
      setHasTableau(false);
      setSelectedPlatform("DOMO");
      // setActiveTab("Domo");
      // getClientInfoById();
      // getPowerBiClientInfoById();
    } else if (platformFlag === 5) {
      setHasPowerBI(false);
      setHasDomo(true);
      setHasTableau(true);
      setSelectedPlatform("DOMO");
      // setActiveTab("Domo");
      // getClientInfoById();
      // getTableauClientInfoById();
    } else if (platformFlag === 6) {
      setHasPowerBI(true);
      setHasDomo(false);
      setHasTableau(true);
      setSelectedPlatform("PowerBi");
      // setActiveTab("PowerBi");
      // getPowerBiClientInfoById();
      // getTableauClientInfoById();
    } else if (platformFlag === 7) {
      setHasPowerBI(true);
      setHasDomo(true);
      setHasTableau(true);
      setSelectedPlatform("DOMO");
      // setActiveTab("Domo");
      // getClientInfoById();
      // getPowerBiClientInfoById();
      // getTableauClientInfoById();
    }
  }

  function modalPlatformSet(platformFlag) {
    if (
      platformFlag === 1 ||
      platformFlag === 4 ||
      platformFlag === 5 ||
      platformFlag === 7
    ) {
      setSelectedPlatform("DOMO");
    } else if (platformFlag === 2 || platformFlag === 6) {
      setSelectedPlatform("PowerBi");
    } else if (platformFlag === 3) {
      setSelectedPlatform("Tableau");
    }
  }

  const handleBulkImport = () => { };


  // ================= NEW STATES ==================================
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 300); // Reduced debounce time for responsiveness

  const [activeTab, setActiveTab] = useState('dashboard');
  const [viewTab, setViewTab] = useState('grid');

  const [error, setError] = useState('');


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // console.log(tabName);
  };

  const handleViewClick = (tabName) => {
    setViewTab(tabName);
    // console.log(tabName);
  };

  const [selectedDashboards, setSelectedDashboards] = useState([]);

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (dashboardId) => {
    setSelectedDashboards(prevSelected => {
      if (prevSelected.includes(dashboardId)) {
        return prevSelected.filter(id => id !== dashboardId);
      } else {
        return [...prevSelected, dashboardId];
      }
    });
    setIsChecked(!isChecked)
  };

  // const handleBulkDelete = () => {
  //   // Implement your bulk delete logic here
  //   console.log('Deleting dashboards:', selectedDashboards);
  //   // After deletion, clear the selection
  //   setSelectedDashboards([]);
  // };


  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked)
  // }

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);

  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const editFilters = () => {
    // console.log('edit')
  }

  const handleDelete = () => {
    // console.log('delete')
  }

  const dashboardColumns = [
    { field: 'number', headerName: 'ID', width: 70, align: 'center', headerAlign: 'center', resizable: false },
    {
      field: 'dashboard_platform_flag',
      headerName: 'Platform',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <img
          className="w-7 h-7 my-2 mx-auto"
          src={
            params.value === 1
              ? powerbiLogo
              : params.value === 0
                ? domoLogo
                : params.value === 2
                  ? tableauLogo
                  : ""
          }
          alt='dashboard_platform_flag' />
      )
      , resizable: false
    },
    { field: 'dashboard_name', headerName: 'Dashboard Name', flex: 1, minWidth: 200, resizable: false },
    { field: 'dashboardOwner', headerName: 'Dashboard Owner', flex: 1, minWidth: 100, resizable: false },
    {
      field: 'users',
      headerName: 'Users',
      width: 150,
      align: 'center',
      headerAlign: 'center'
      , resizable: false
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      align: 'center',
      headerAlign: 'center'
      , resizable: false
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="Options"
            onClick={(event) => handleOpenMenu(event, params.row)}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && currentRow?.id === params.row.id}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => viewDashboard(params.row.id, params.row.dashboard_platform_flag)}>View</MenuItem>
            {/* <MenuItem onClick={() => viewDashboard(params.row.id, params.row.platform)}>View</MenuItem> */}
            <MenuItem onClick={() => handleShareDashboardClick(params.row.id)}>Share</MenuItem>
            <MenuItem onClick={() => {
              // console.log("Edit clicked, params.row:", params.row);
              editDashboard(params.row);
            }}>Edit</MenuItem>
            {/* <MenuItem onClick={() => deleteDashboard(params.row.id)}>Delete</MenuItem> */}
            <MenuItem onClick={() => {
              setDeleteDashboardObj(params.row); // Set the dashboard to delete
              setDeleteModalShow(true); // Show the delete confirmation modal
            }}>
              Delete
            </MenuItem>

          </Menu>
        </>
      )
      , resizable: false
    },

  ];

  const dashboardRows = filteredDashboards && filteredDashboards
    .sort((a, b) => {
      if (a.dashboard_name < b.dashboard_name) {
        return -1;
      }
      if (a.dashboard_name > b.dashboard_name) {
        return 1;
      }
      return 0;
    })
    .map((dashboard, index) => ({
      id: dashboard.id,
      number: index + 1,
      dashboard_name: dashboard.dashboard_name,
      dashboardOwner: `${dashboard.first_name} ${dashboard.last_name}`,
      dashboard_platform_flag: dashboard.dashboard_platform_flag,
      date: `${dashboard.created_on.split("T")[0] || 'N/A'}`,
      users: dashboard.count == null ? 0 : dashboard.count,
      dashboard_embed_id: dashboard.dashboard_embed_id,
      workspace_id: dashboard.workspace_id,
      single_role: dashboard.single_role,
      trusted_attribute: dashboard.trusted_attribute,
      admin_id: dashboard.admin_id,

      // Additional fields needed for actions
      // dashboardId: dashboard.id,
      // dashboardPlatformFlag: dashboard.dashboard_platform_flag
    }));

  return (
    <>
      {/* Tab Version : 1 */}
      {/* <div className="mt-1 flex justify-between items-center ">
        <div className="flex overflow-x-auto items-center p-1 space-x-1 rtl:space-x-reverse text-sm text-gray-600 bg-gray-500/5 rounded-xl">

          <button
            role="tab"
            type="button"
            className={`flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none focus:ring-2 focus:ring-transparent focus:ring-inset ${activeTab === 'dashboard'
              ? 'text-blue-500 shadow bg-white'
              : 'hover:text-gray-800 '
              }`}
            aria-selected={activeTab === 'dashboard'}
            onClick={() => handleTabClick('dashboard')}
          >
            Dashboard
          </button>

          <button
            role="tab"
            type="button"
            className={`flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none focus:ring-2 focus:ring-transparent focus:ring-inset ${activeTab === 'table'
              ? 'text-blue-500 shadow bg-white'
              : 'hover:text-gray-800 '
              }`}
            aria-selected={activeTab === 'table'}
            onClick={() => handleTabClick('table')}
          >
            Table
          </button>

        </div>
      </div> */}

      <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
        Dashboards
      </label>

      <div className="mt-3 flex justify-between items-center border-b border-gray-200">
        <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
          <div className="relative mt-3 mb-3 text-sm">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <IoSearchOutline className="w-5 h-5 text-gray-400" />
            </span>
            <input
              type="text"
              className="w-full py-1.5 pl-10 pr-4 text-gray-700 bg-gray-50 border rounded outline-none"
              placeholder="Search Dashboard"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          {selectedDashboards.length > 0 && (
            <button
              type="button"
              onClick={handleBulkDelete}
              className="inline-flex justify-center items-center rounded bg-red-500 px-3 py-2 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              <IoTrashOutline className="w-5 h-5 mr-1" />
              <span className="text-sm">Delete Selected</span>
            </button>
          )}

          <div className="flex items-center p-1 space-x-1 text-sm text-gray-600 bg-gray-500/5 rounded-xl">
            <button
              role="tab"
              type="button"
              className={`flex items-center h-8 px-3 font-medium rounded-lg outline-none focus:ring-2 focus:ring-transparent focus:ring-inset ${viewTab === 'grid'
                ? `text-${selectedColor}-500 shadow bg-white`
                : 'hover:text-gray-800'
                }`}
              aria-selected={viewTab === 'grid'}
              onClick={() => handleViewClick('grid')}
            >
              <IoGridOutline className='w-5 h-5' />
            </button>

            <button
              role="tab"
              type="button"
              className={`flex items-center h-8 px-3 font-medium rounded-lg outline-none focus:ring-2 focus:ring-transparent focus:ring-inset ${viewTab === 'tabular'
                ? `text-${selectedColor}-500 shadow bg-white`
                : 'hover:text-gray-800'
                }`}
              aria-selected={viewTab === 'tabular'}
              onClick={() => handleViewClick('tabular')}
            >
              <CiBoxList className='w-5 h-5' />
            </button>
          </div>

          <button
            type="button"
            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
            onClick={() => {
              handleShow();
              setIsDashboardEdit(false);
              setTrustedAttributeValue("Email");
            }}

          >
            <IoIosAdd className="w-5 h-5 mr-1" />
            <span className="text-sm">Add Dashboard</span>
          </button>
        </div>
      </div>

      {/* Grid View - only visible when activeTab === 'dashboard' and viewTab === 'grid' */}
      {activeTab === 'dashboard' && viewTab === 'grid' && (
        <div className="py-4 px-2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {filteredDashboards &&
              filteredDashboards
                .sort((a, b) => {
                  if (a.dashboard_name < b.dashboard_name) return -1;
                  if (a.dashboard_name > b.dashboard_name) return 1;
                  return 0;
                })
                .map((dashboard, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-md rounded-lg px-5 pt-5 pb-4 transition-transform hover:scale-105"
                  >
                    <div className="flex justify-between items-center mb-3">
                      <img
                        src={
                          dashboard.dashboard_platform_flag === 1
                            ? powerbiLogo
                            : dashboard.dashboard_platform_flag === 0
                              ? domoLogo
                              : dashboard.dashboard_platform_flag === 2
                                ? tableauLogo
                                : ""
                        }
                        alt={dashboard.dashboard_name}
                        className="w-8 h-8"
                      />
                      {/* <input
                        type="checkbox"
                        className={`form-checkbox h-4 w-4 text-${selectedColor}-600`}
                        checked={selectedDashboards.includes(dashboard.id)}
                        onChange={() => handleCheckboxChange(dashboard.id)}
                      /> */}
                    </div>
                    <h3 className="text-md font-semibold text-gray-600 truncate">{dashboard.dashboard_name}</h3>
                    <p className="text-sm text-slate-400 mt-2">Owned by {dashboard.first_name + " " + dashboard.last_name}</p>
                    <div className="flex items-center text-slate-400 mt-2 text-sm gap-1">
                      <FaUsers className="w-4 h-4 mr-1" />
                      <span>{dashboard.count == null ? 0 : dashboard.count} {dashboard.count === 1 ? 'user' : 'users'}</span>
                    </div>
                    <div className="flex items-center text-slate-400 mt-2 text-sm gap-1">
                      <FaRegCalendarAlt className="w-4 h-4 mr-1" />
                      <span>{dashboard.created_on.split("T")[0] || 'N/A'}</span>
                    </div>
                    <div className="flex justify-end items-center mt-4 gap-4 border-t">
                      <div className="flex items-center mt-3 gap-4">
                        {/* <GoTrash
                          className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                          onClick={() => deleteDashboard(dashboard.id)}
                        /> */}

                        <GoTrash
                          className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                          onClick={() => {
                            setDeleteDashboardObj(dashboard); // Set the dashboard to delete
                            setDeleteModalShow(true); // Show the delete confirmation modal
                          }}
                        />

                        <IoShareSocialOutline
                          className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                          onClick={() => handleShareDashboardClick(dashboard.id)}
                        />
                        <BiEditAlt
                          className="w-4 h-4 text-gray-600 hover:text-gray-900 font-medium cursor-pointer"
                          onClick={() => editDashboard(dashboard)}
                        />
                        <div
                          className={`flex items-center text-${selectedColor}-500 hover:text-${selectedColor}-600 cursor-pointer`}
                          onClick={() => viewDashboard(dashboard.id, dashboard.dashboard_platform_flag)}
                        >
                          <IoEyeOutline className="w-4 h-4 mr-1" />
                          <span className="text-sm">View</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}

      {/* Tabular View - only visible when activeTab === 'dashboard' and viewTab === 'tabular' */}
      {activeTab === 'dashboard' && viewTab === 'tabular' && (
        <div className="mt-4" style={{ height: 'auto', width: '100%' }}>
          <StyledDataGrid
            rows={dashboardRows}
            columns={dashboardColumns}
            rowHeight={45}
            columnHeaderHeight={50}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            autoHeight
            // checkboxSelection
            disableSelectionOnClick
            disableRowSelectionOnClick
          // onRowSelectionModelChange={(newSelectionModel) => {
          //   // Update the selectedDashboards state
          //   setSelectedDashboards(newSelectionModel);
          // }}
          // selectionModel={selectedDashboards}
          />
        </div>
      )}

      {show && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-5/12 max-h-[80vh] overflow-y-auto transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Save Dashboard Info</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={handleDashboardModalClose}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              <form className="space-y-6">
                {!isDashboardEdit && (
                  <div className="grid grid-cols-2 gap-6 mt-4">
                    <div>
                      <label htmlFor="platformSelect" className="text-gray-700 ">
                        Select Platform
                      </label>
                      <select
                        id="platformSelect"
                        className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                        value={selectedPlatform}
                        onChange={handlePlatformChange}
                      >
                        <option value="" disabled>Select a platform</option>
                        {hasDomo && <option value="DOMO">DOMO</option>}
                        {hasPowerBI && <option value="PowerBi">PowerBi</option>}
                        {hasTableau && <option value="Tableau">Tableau</option>}
                      </select>
                    </div>
                  </div>
                )}

                {selectedPlatform === "DOMO" && (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="text-gray-700 " htmlFor="groupName">
                          Dashboard Name <span className="text-red-500 font-bold">*</span>
                        </label>
                        <input
                          type="text"
                          name="dashboardName"
                          id="dashboardName"
                          value={dashboardName}
                          onChange={handleChangeDashboardName}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Dashboard Name"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.dashboardName}</p>
                      </div>
                      <div>
                        <label className="text-gray-700 " htmlFor="groupName">
                          Embed Id <span className="text-red-500 font-bold">*</span>
                        </label>
                        <input
                          type="text"
                          name="embedId"
                          id="embedId"
                          value={dashboardEmbedId}
                          onChange={handleChangeDashboardEmbedId}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Embed Id"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.dashboardEmbedId}</p>
                      </div>
                    </div>

                    <div className="space-y-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="trustedAttribute"
                          checked={isTrustedChecked}
                          onChange={() => setIsTrustedChecked((prev) => !prev)}
                          className="h-4 w-4 text-blue-500 border-gray-300 rounded"
                        />

                        <label htmlFor="trustedAttribute" className="ml-2 block text-sm text-gray-700">
                          Filter by Trusted Attribute
                        </label>
                      </div>

                      {isTrustedChecked && (
                        <>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <label className="text-gray-700 " htmlFor="groupName">
                                Filter By <span className="text-red-500 font-bold">*</span>
                              </label>
                              <input
                                type="text"
                                name="trustedAttributeColumnName"
                                id="trustedAttributeColumnName"
                                value={trustedAttributeColumnName}
                                onChange={(e) => setTrustedAttributeColumnName(e.target.value)}
                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                placeholder="Enter Column Name..."
                              />
                              <p className="text-red-500 text-xs mt-1">{formError.trustedAttributeColumnName}</p>
                            </div>

                            <div>
                              <label className="text-gray-700 " htmlFor="groupName">
                                Trusted Attribute <span className="text-red-500 font-bold">*</span>
                              </label>
                              <select
                                id="trustedAttributeValue"
                                value={trustedAttributeValue}
                                onChange={(e) => setTrustedAttributeValue(e.target.value)}
                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              >
                                <option value="email">Email</option>
                                <option value="job_title">Job Title</option>
                                <option value="department">Department</option>
                                <option value="location">Location</option>
                              </select>
                              <p className="text-red-500 text-xs mt-1">{formError.trustedAttributeValue}</p>
                            </div>
                          </div>
                          <div>
                            <label className="text-gray-700 ">
                              Exclude Users{" "}
                              <span className="ml-1 p-1 text-sm bg-gray-100 text-gray-600 rounded">{excludeUsers.length} selected</span>
                            </label>
                            <Select
                              className="mt-1 block w-full"
                              closeMenuOnSelect={false}
                              onChange={handleExcludeUsersSelect}
                              // components={animatedComponents}
                              defaultValue={[userList[0]]}
                              isMulti
                              options={userList}
                              value={excludeUsers}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

                {selectedPlatform === "PowerBi" && (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="powerbiDashboardName" className="text-gray-700 ">
                          Dashboard Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="powerbiDashboardName"
                          id="powerbiDashboardName"
                          value={powerbiDashboardName}
                          onChange={handleChangePowerbiValues}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Dashboard Name"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.powerbiDashboardName}</p>
                      </div>
                      <div>
                        <label htmlFor="reportId" className="text-gray-700 ">
                          Report Id <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="reportId"
                          id="reportId"
                          value={reportId}
                          onChange={handleChangePowerbiValues}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Report Id"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.reportId}</p>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="workspaceId" className="text-gray-700 ">
                          Workspace Id <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="workspaceId"
                          id="workspaceId"
                          value={workspaceId}
                          onChange={handleChangePowerbiValues}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Workspace Id"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.workspaceId}</p>
                      </div>
                      <div>
                        <label className="text-gray-700 ">Type <span className="text-red-500">*</span></label>
                        <div className="flex items-center space-x-4 mt-4">
                          <div className="flex items-center">
                            <input
                              type="radio"
                              id="groupTypeClosedGroup"
                              name="groupType"
                              value="ClosedGroup"
                              checked={isClosedGroup}
                              onChange={(e) => {
                                setIsDynamicGroup(false);
                                setIsClosedGroup(true);
                                handleReportTypeChange(e);
                              }}
                              className="h-4 w-4 text-blue-500 border-gray-300"
                              disabled={isDashboardEdit && isDynamicGroup}
                            />
                            <label htmlFor="groupTypeClosedGroup" className="ml-2 block text-sm text-gray-700">
                              Single Role
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="radio"
                              id="groupTypeDynamicGroup"
                              name="groupType"
                              value="DynamicGroup"
                              checked={isDynamicGroup}
                              onChange={(e) => {
                                setIsDynamicGroup(true);
                                setIsClosedGroup(false);
                                handleReportTypeChange(e);
                              }}
                              className="h-4 w-4 text-blue-500 border-gray-300"
                              disabled={isDashboardEdit && isClosedGroup}
                            />
                            <label htmlFor="groupTypeDynamicGroup" className="ml-2 block text-sm text-gray-700">
                              Dynamic Role
                            </label>
                          </div>
                        </div>
                        <p className="text-red-500 text-xs mt-1">{formError.groupType}</p>
                      </div>
                    </div>

                    {isClosedGroup && (
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="dashboardRole" className="text-gray-700 ">
                            Input Role <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="dashboardRole"
                            id="dashboardRole"
                            value={dashboardRole}
                            onChange={handleChangePowerbiValues}
                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                            placeholder="Role"
                          />
                          <p className="text-red-500 text-xs mt-1">{formError.dashboardRole}</p>
                        </div>
                      </div>
                    )}

                    {isDynamicGroup && (
                      <>
                        {isDashboardEdit && (
                          <div className="mb-4">
                            <label className="text-gray-700 ">
                              Roles Mapping Already Saved. Upload again to Re-map the roles
                              <span className="text-red-500">*</span>
                            </label>
                          </div>
                        )}
                        <div className="px-2 py-2 w-2/3">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Download Template
                            </label>
                            <button
                              className={`bg-${selectedColor}-500 hover:bg-${selectedColor}-600 text-white font-medium py-2 px-4 rounded text-sm`}
                              onClick={(e) => handleExport(e)}
                            >
                              Download
                            </button>
                            <p className="text-sm text-gray-600 mt-4 italic">
                              Note: Please download the template and do not remove the first entry in the Excel sheet. Removing it may result in incorrect data import.
                            </p>
                          </div>
                          <div className="mb-4 mt-5">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Upload an Excel Sheet
                            </label>
                            <div className={`mt-1 flex justify-between items-center px-4 py-2 bg-${selectedColor}-50 rounded-md`}>
                              <span className="text-sm text-gray-500">
                                {excel ? excel.name : "No file chosen"}
                              </span>
                              <label className={`cursor-pointer bg-${selectedColor}-500 hover:bg-${selectedColor}-600 text-white font-medium py-1 px-3 rounded text-sm`}>
                                Choose file
                                <input
                                  type="file"
                                  className="hidden"
                                  name="bulkImport"
                                  id="bulkImport"
                                  onChange={(e) => readExcel(e.target.files[0])}
                                  accept=".xlsx,.xls"
                                />
                              </label>
                            </div>
                            <p className="text-red-500 text-xs mt-1">{formError.FileNotFound}</p>
                            {formError.InvalidData && (
                              <>
                                <p className="text-red-500 text-xs mt-1">
                                  Kindly check if the below mentioned columns are null.
                                </p>
                                <div className="flex flex-wrap gap-1 mt-1">
                                  {missingColumns.map((column, index) => (
                                    <span key={index} className="px-2 py-1 text-xs font-medium text-white bg-red-500 rounded-full">
                                      {column}
                                    </span>
                                  ))}
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                      </>
                    )}
                  </>
                )}

                {selectedPlatform === "Tableau" && (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="tableauDashboardName" className="text-gray-700 ">
                          Dashboard Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="tableauDashboardName"
                          id="tableauDashboardName"
                          value={tableauDashboardName}
                          onChange={handleChangeTableauValues}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Dashboard Name"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.tableauDashboardName}</p>
                      </div>
                      <div>
                        <label htmlFor="tableauDashboardEmbedLink" className="text-gray-700 ">
                          Embed Link <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="embedLink"
                          id="tableauDashboardEmbedLink"
                          value={tableauDashboardEmbedLink}
                          onChange={handleChangeTableauValues}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Embed Link"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.tableauDashboardEmbedLink}</p>
                      </div>
                    </div>

                    <div className="space-y-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tableauTrustedAttribute"
                          checked={isTableauTrustedChecked}
                          onChange={() => setIsTableauTrustedChecked((prev) => !prev)}
                          className="h-4 w-4 text-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="tableauTrustedAttribute" className="ml-2 block text-sm text-gray-700">
                          Filter by Trusted Attribute
                        </label>
                      </div>

                      {isTableauTrustedChecked && (
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label
                              htmlFor="trustedAttributeColumnName"
                              className="text-gray-700 "
                            >
                              Filter By <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="trustedAttributeColumnName"
                              id="trustedAttributeColumnName"
                              value={tableauTrustedAttributeColumnName}
                              onChange={(e) => setTableauTrustedAttributeColumnName(e.target.value)}
                              className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              placeholder="Enter Column Name..."
                            />
                            <p className="text-red-500 text-xs mt-1">{formError.tableauTrustedAttributeColumnName}</p>
                          </div>
                          <div>
                            <label htmlFor="trustedAttributeValue" className="text-gray-700 ">
                              Trusted Attribute <span className="text-red-500">*</span>
                            </label>
                            <select
                              id="trustedAttributeValue"
                              value={tableauTrustedAttributeValue}
                              onChange={(e) => setTableauTrustedAttributeValue(e.target.value)}
                              className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                            >
                              <option value="email">Email</option>
                              <option value="job_title">Job Title</option>
                              <option value="department">Department</option>
                              <option value="location">Location</option>
                            </select>
                            <p className="text-red-500 text-xs mt-1">{formError.tableauTrustedAttributeValue}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="flex justify-end px-6 py-4 border-t mt-4 gap-4">
              <button
                className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                onClick={handleDashboardModalClose}
              >
                Cancel
              </button>
              <button
                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                onClick={() => {
                  if (selectedPlatform === "DOMO") {
                    isDashboardEdit ? updateDashboard() : saveDashboardInfo();
                  } else if (selectedPlatform === "PowerBi") {
                    isDashboardEdit ? updatePowerbiDashboard() : savePowerbiDashboardInfo();
                  } else if (selectedPlatform === "Tableau") {
                    isDashboardEdit ? updateTableauDashboard() : saveTableauDashboardInfo();
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Share Modal */}
      {shareModalShow && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-5/12 max-h-[80vh] transition-transform transform duration-300 flex flex-col">

            {/* Header */}
            <div className="px-6 py-4 flex justify-between items-center border-b sticky top-0 bg-white z-10">
              <h2 className="text-lg font-semibold text-gray-800">
                Share {deleteDashboardObj?.dashboard_name}
              </h2>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => handleShareCancel()}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* Search Input */}
            <div className="px-6 py-4 border-b sticky top-[48px] bg-white z-10">
              <input
                type="text"
                name="searchUsers"
                onChange={(e) => setUserSearch(e.target.value)}
                value={userSearch}
                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                placeholder="Filter By Name..."
                id="searchUsers"
              />
            </div>

            {/* Scrollable Content */}
            <div className="p-4 overflow-y-auto max-h-[calc(80vh-144px)]">
              <form className="space-y-4">
                <div>
                  <div className="text-gray-700  mb-2">Shared with</div>
                  <ul className="space-y-2">
                    {userDetails
                      .filter(
                        (x) =>
                          x.filter_id != null &&
                          x.first_name.toLowerCase().includes(userSearch.toLowerCase())
                      )
                      .map((user, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-start p-2 border rounded"
                        >
                          <div className='mx-2'>
                            <p className="text-sm font-semibold mb-1">
                              {user.first_name + ' ' + user.last_name}
                            </p>
                            <p className="text-xs text-gray-600">{user.work_email}</p>
                          </div>
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700 my-2 mx-2"
                            onClick={() => handleRemoveUser(user.id)}
                          >
                            <AiOutlineMinusCircle size={20} />
                          </button>
                        </li>
                      ))}
                    {groupDetails
                      .filter(
                        (x) =>
                          x.dashboard_info_id != null &&
                          x.name.toLowerCase().includes(userSearch.toLowerCase())
                      )
                      .map((group, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-start p-2 border rounded"
                        >
                          <div className='mx-2'>
                            <p className="text-sm font-semibold mb-1">{group.name}</p>
                            <p className="text-xs text-gray-600">Group</p>
                          </div>
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700 my-2 mx-2"
                            onClick={() => handleRemoveGroup(group)}
                          >
                            <AiOutlineMinusCircle size={20} />
                          </button>
                        </li>
                      ))}
                    {!userDetails.filter(
                      (x) =>
                        x.filter_id != null &&
                        x.first_name.toLowerCase().includes(userSearch.toLowerCase())
                    ).length &&
                      !groupDetails.filter(
                        (g) =>
                          g.dashboard_info_id != null &&
                          g.name.toLowerCase().includes(userSearch.toLowerCase())
                      ).length && (
                        <li className="p-2 border rounded text-gray-600">No users found</li>
                      )}
                  </ul>
                </div>

                <div>
                  <div className="text-gray-700  mb-2">
                    Share with Additional People/Groups
                  </div>
                  <ul className="space-y-2">
                    {userDetails
                      .filter(
                        (x) =>
                          x.filter_id == null &&
                          x.first_name.toLowerCase().includes(userSearch.toLowerCase())
                      )
                      .map((user, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-start p-2 border rounded"
                        >
                          <div className='mx-2'>
                            <p className="text-sm font-semibold mb-1">
                              {user.first_name + ' ' + user.last_name}
                            </p>
                            <p className="text-xs text-gray-600">{user.work_email}</p>
                          </div>
                          <button
                            type="button"
                            className="text-blue-500 hover:text-blue-700 my-2 mx-2"
                            onClick={() => handleShare(user)}
                          >
                            <AiOutlinePlusCircle size={20} />
                          </button>
                        </li>
                      ))}
                    {groupDetails
                      .filter(
                        (g) =>
                          g.dashboard_info_id == null &&
                          g.name.toLowerCase().includes(userSearch.toLowerCase())
                      )
                      .map((group, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-start p-2 border rounded"
                        >
                          <div className='mx-2'>
                            <p className="text-sm font-semibold mb-1">{group.name}</p>
                            <p className="text-xs text-gray-600">Group</p>
                          </div>
                          <button
                            type="button"
                            className="text-blue-500 hover:text-blue-700 my-2 mx-2"
                            onClick={() => handleGroupShare(group)}
                          >
                            <AiOutlinePlusCircle size={20} />
                          </button>
                        </li>
                      ))}
                    {!userDetails.filter(
                      (x) =>
                        x.filter_id == null &&
                        x.first_name.toLowerCase().includes(userSearch.toLowerCase())
                    ).length &&
                      !groupDetails.filter(
                        (g) =>
                          g.dashboard_info_id == null &&
                          g.name.toLowerCase().includes(userSearch.toLowerCase())
                      ).length && (
                        <li className="p-2 border rounded text-gray-600">No users found</li>
                      )}
                  </ul>
                </div>
              </form>
            </div>

            {/* Footer */}
            <div className="flex justify-end border-t border-gray-200 p-4 sticky bottom-0 bg-white z-10">
              <button
                className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                onClick={() => handleShareCancel()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Modal */}

      {deleteModalShow && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300 z-50">
          <div className="bg-white rounded-lg shadow-xl w-1/2 2xl:w-1/3 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Delete Dashboard</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => {
                  setDeleteModalShow(false);
                  setDeleteDashboardObj(null);
                }}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              <p className="text-center text-gray-700 text-sm">
                Are you sure you want to delete{" "}
                {deleteDashboardObj ? `"${deleteDashboardObj.dashboard_name}"` : ""}
                {" "}dashboard?
              </p>
            </div>
            <div className="px-6 py-4 border-t flex justify-end space-x-5">
              <button
                className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                onClick={() => {
                  setDeleteModalShow(false);
                  setDeleteDashboardObj(null);
                }}
              >
                Cancel
              </button>

              <button
                className="inline-flex justify-center items-center rounded bg-red-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"
                onClick={() =>
                  deleteDashboard(
                    deleteDashboardObj.id,
                    deleteDashboardObj.dashboard_name
                  )
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}


    </>


  )
}

export default DashboardTab
