import React, { useState } from 'react';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme

const About = () => {
    const { selectedColor } = useTheme();
    const [activePanel, setActivePanel] = useState(null);

    const handlePanelClick = (panelIndex) => {
        setActivePanel(panelIndex === activePanel ? null : panelIndex);
    };

    const renderPanel = (title, content, panelIndex) => (
        <div key={panelIndex} className="my-8">
            <button
                className="flex items-center focus:outline-none"
                onClick={() => handlePanelClick(panelIndex)}
            >
                <svg
                    className={`flex-shrink-0 w-6 h-6 text-${selectedColor}-500`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={panelIndex === activePanel ? "M20 12H4" : "M12 4v16m8-8H4"}
                    />
                </svg>
                <h1 className="mx-4 text-xl text-gray-700">{title}</h1>
            </button>
            <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${panelIndex === activePanel ? 'max-h-screen opacity-100 mt-4' : 'max-h-0 opacity-0'
                    }`}
            >
                {content}
            </div>
        </div>
    );

    return (
        <section className="bg-white">
            <div className="container px-6 py-10 mx-auto">
                <h1 className="text-2xl font-semibold text-gray-800 lg:text-3xl">
                    DIACTO Embed
                </h1>
                <p className="mt-4 text-gray-700 leading-6">
                    Welcome to Diacto Embed A DOMO Everywhere Platform, designed specifically
                    for companies seeking to maximize their data sharing capabilities and enhance
                    collaboration with external users through programmatic filtering. Our platform
                    offers a prebuilt and intuitive solution that is both user-friendly and efficient.
                </p>
                <p className="mt-4 text-gray-700 leading-6">
                    Getting started is a breeze. In just a matter of minutes, you can effortlessly
                    add external users and seamlessly integrate your dashboards. Once set up, you can
                    instantly begin consuming and sharing your valuable dashboards, empowering your
                    organization with valuable insights.
                </p>
                <p className="mt-4 text-gray-700 leading-6">
                    With our robust DOMO Everywhere Platform, you can unlock the full potential
                    of your data, streamline communication, and foster collaborative decision-making.
                    Experience the power of seamless dashboard sharing today.
                </p>

                {renderPanel(
                    'Prerequisites',
                    <>
                        <p className="mt-4 text-gray-700">
                            Before diving into the DOMO Everywhere Platform, let's ensure you have
                            the necessary pre-requisites in place:
                        </p>
                        <ol className="list-decimal list-inside mt-4 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Client ID & Client Secret:</span> To access and utilize the platform's
                                features, you'll need a unique Client ID and Client Secret. These
                                credentials provide secure access to your data and ensure the
                                confidentiality of your information. Go to developers.domo.com to
                                create your own Credentials.
                            </li>
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Embed IDs of Dashboards:</span> To seamlessly integrate your dashboards,
                                you'll need the specific Embed IDs associated with each dashboard.
                                These IDs allow you to effortlessly incorporate your desired dashboards
                                into the platform, making them readily available for sharing with
                                external users.
                            </li>
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Columns from Datasets and values to filter on:</span> To enhance your data
                                filtering capabilities, you can specify the columns from your datasets
                                and the corresponding values on which you want to apply filters. This
                                empowers you to tailor the information presented in your dashboards
                                and provide relevant insights to your external users.
                            </li>
                        </ol>
                        <p className="mt-4 text-gray-700">
                            Moreover, our platform goes beyond standard filtering options. You can
                            also leverage trusted attributes, such as Email or Role, which are securely
                            stored within the platform. This functionality enables you to apply filters
                            based on these trusted attributes, ensuring seamless data access and
                            personalized experiences for your users.
                        </p>
                    </>,
                    0
                )}

                {renderPanel(
                    'Process to Configure',
                    <>
                        <p className="mt-4 text-gray-700">
                            Once the customer is created, a person is assigned as admin. Once Admin logs into the Portal, they must configure following:
                        </p>
                        <h6 className="mt-4 text-xl font-semibold text-gray-800">
                            Configure credentials:
                        </h6>
                        <ol className="list-decimal list-inside mt-4 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Customer Creation:</span> When creating a customer account, an admin is
                                assigned.
                            </li>
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Admin Login:</span> The admin logs into the portal to begin the
                                configuration process.
                            </li>
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Configure Credentials:</span>
                                <ol className="list-decimal list-inside mt-2 text-gray-700">
                                    <li className='mt-2 leading-8 px-4'>
                                        <span className='font-semibold'> Add Client ID & Client Secret:</span> Obtain a Client ID & Client
                                        Secret from developers.domo.com and enter them into the
                                        platform. Save the credentials for future use.
                                    </li>
                                    <li className='mt-2 leading-8 px-4'>
                                        <span className='font-semibold'>Edit Credentials: </span>If needed, the admin can edit the Client
                                        ID & Client Secret by selecting the "Edit Credentials"
                                        option.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </>,
                    1
                )}

                {renderPanel(
                    'Dashboard Management',
                    <>
                        <ol className="list-decimal list-inside mt-4 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>Click on <span className='font-semibold'>"Add Dashboard"</span> to begin.</li>
                            <li className='mt-2 leading-8 px-4'>Assign a name to the dashboard.</li>
                            <li className='mt-2 leading-8 px-4'>Enter the Embed ID associated with the dashboard.</li>
                            <li className='mt-2 leading-8 px-4'>
                                Optionally, enable the "Filter by Trusted Attribute" flag for automatic filtering based on trusted attributes like Email, Role, etc. Alternatively, keep it disabled.
                            </li>
                        </ol>
                        <p className="mt-4 text-gray-700">
                            Note: Ensure that the column names mentioned in the configuration match those in the dataset powering the dashboard.
                        </p>
                        <h6 className="mt-4 text-xl font-semibold text-gray-800">
                            Dashboard Actions:
                        </h6>
                        <ol className="list-decimal list-inside mt-4 text-gray-700">
                            <li className='mt-2 leading-8 px-4'><span className='font-semibold'>View: </span> Admins can preview how the dashboard will appear after embedding.</li>
                            <li className='mt-2 leading-8 px-4'><span className='font-semibold'>Share: </span> Share the dashboard with other users.</li>
                            <li className='mt-2 leading-8 px-4'><span className='font-semibold'>Edit: </span> Modify the configuration settings for the dashboard.</li>
                            <li className='mt-2 leading-8 px-4'><span className='font-semibold'>Delete: </span> Remove the dashboard from the platform.</li>
                        </ol>
                        <p className="mt-4 text-gray-700">
                            With these straightforward steps and actions, you can effortlessly add, manage, and share your dashboards within the platform.
                        </p>
                    </>,
                    2
                )}


                {renderPanel(
                    'User Management',
                    <>
                        <ol className="list-decimal list-inside mt-4 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>
                                <span className='font-semibold'>Add Single User:</span>
                                <ol className="list-decimal list-inside mt-2 text-gray-700">
                                    <li className='mt-2 leading-8 px-4'>Fill in the required details for the user.</li>
                                    <li className='mt-2 leading-8 px-4'>Save the user's information to add them to the system.</li>
                                </ol>
                            </li>
                            <li className="mt-4 leading-8 px-4">
                                <span className='font-semibold'>Bulk Import Users:</span>
                                <ol className="list-decimal list-inside mt-2 text-gray-700">
                                    <li className='mt-2 leading-8 px-4'>Download the provided template.</li>
                                    <li className='mt-2 leading-8 px-4'>Fill in the necessary information for each user.</li>
                                    <li className='mt-2 leading-8 px-4'>Reupload the file.</li>
                                    <li className='mt-2 leading-8 px-4'>The system automatically checks for empty fields or duplicate user entries.</li>
                                </ol>
                            </li>
                        </ol>
                        <span className="mt-4 block font-semibold text-gray-800">
                            File Template:
                        </span>
                        <ol className="list-decimal list-inside mt-2 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>Mandatory fields: First Name, Last Name, Role, Email, and Job Title.</li>
                            <li className='mt-2 leading-8 px-4'>Other fields can be left blank.</li>
                        </ol>
                        <p className="mt-4 text-gray-700">
                            By utilizing these user management features, you can easily add users individually or in bulk, ensuring efficient administration of your system.
                        </p>
                    </>,
                    3
                )}


                {renderPanel(
                    'Group Management',
                    <>
                        <p className="mt-4 text-gray-700">
                            Add users to groups to facilitate dashboard sharing at scale.
                        </p>
                        <p className="mt-4 font-semibold text-gray-800">
                            Closed Group:
                        </p>
                        <ol className="list-decimal list-inside mt-2 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>Directly add users to this group type.</li>
                            <li className='mt-2 leading-8 px-4'>Ideal for static groups with fixed members.</li>
                        </ol>
                        <p className="mt-4 font-semibold text-gray-800">
                            Dynamic Group:
                        </p>
                        <ol className="list-decimal list-inside mt-2 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>Automatically add users based on specific criteria.</li>
                            <li className='mt-2 leading-8 px-4'>Examples include Department = Marketing, Job Title, Role, location, etc.</li>
                            <li className='mt-2 leading-8 px-4'>Leverage various fields to dynamically populate the group.</li>
                        </ol>
                        <p className="mt-4 text-gray-700">
                            With the ability to manage both closed and dynamic groups, you can efficiently organize users and ensure seamless sharing of dashboards within your organization.
                        </p>
                    </>,
                    4
                )}


                {renderPanel(
                    'Programmatic Filtering',
                    <>
                        <p className="mt-4 text-gray-700">
                            Configure your dashboards to filter and display data based on assigned data permissions at the user and group levels.
                        </p>
                        <p className="mt-4 font-semibold text-gray-800">
                            Assigning Filters at the User Level:
                        </p>
                        <ol className="list-decimal list-inside mt-2 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>Go to Manage Users.</li>
                            <li className='mt-2 leading-8 px-4'>Select the Edit option for the respective user.</li>
                            <li className='mt-2 leading-8 px-4'>Navigate to Assigned Dashboards.</li>
                            <li className='mt-2 leading-8 px-4'>Choose the required dashboard.</li>
                            <li className='mt-2 leading-8 px-4'>Click on Edit.</li>
                            <li className='mt-2 leading-8 px-4'>Assign or update the filter as needed.</li>
                            <li className='mt-2 leading-8 px-4'>Save the changes.</li>
                        </ol>
                        <p className="mt-4 font-semibold text-gray-800">
                            Assigning Filters at the Group Level:
                        </p>
                        <ol className="list-decimal list-inside mt-2 text-gray-700">
                            <li className='mt-2 leading-8 px-4'>Go to Manage Groups.</li>
                            <li className='mt-2 leading-8 px-4'>Select the Edit option for the respective group.</li>
                            <li className='mt-2 leading-8 px-4'>Navigate to Assigned Dashboards.</li>
                            <li className='mt-2 leading-8 px-4'>Choose the required dashboard.</li>
                            <li className='mt-2 leading-8 px-4'>Click on Edit.</li>
                            <li className='mt-2 leading-8 px-4'>Assign or update the filter as needed.</li>
                            <li className='mt-2 leading-8 px-4'>Save the changes.</li>
                        </ol>
                        <p className="mt-4 text-gray-700">
                            For Dynamic Filtering using Trusted Attribute: During the addition of a dashboard from the Dashboards section in the SETTINGS option, enable the option for Dynamic Filtering using Trusted Attribute.
                        </p>
                        <p className="mt-4 text-gray-700">
                            Note: Ensure that the column names mentioned in the configuration match those in the dataset powering the dashboard.
                        </p>
                        <p className="mt-4 text-gray-700">
                            By utilizing programmatic filtering, you can customize data access and visibility, ensuring that users and groups only see the relevant information within the assigned dashboards.
                        </p>
                    </>,
                    5
                )}


                {renderPanel(
                    'Analytics Section',
                    <>
                        <p className="mt-4 text-gray-700">
                            The Analytics Section is a privileged feature exclusively accessible to administrators. It provides comprehensive insights into the user engagement and performance metrics of the platform, focusing on key performance indicators (KPIs).
                        </p>
                        <p className="mt-4 text-gray-700">
                            Within the Analytics Section, administrators gain visibility into critical metrics such as sessions, views, and user activity. These metrics serve as sessions,
                            views, and user activity. These metrics serve as essential indicators of user interaction, allowing
                            administrators to assess platform utilization, identify trends, and make data-driven decisions to optimize
                            user engagement and overall platform performance.
                        </p>
                        <p className="mt-4 text-gray-700">
                            By leveraging the Analytics Section, administrators can harness the power of data analytics to gain valuable
                            insights and drive continuous improvement in user experiences and platform effectiveness.
                        </p>
                    </>,
                    6
                )}
            </div>
        </section>
    );
};

export default About;
