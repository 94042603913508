import React, { useState, useEffect } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useDebounce } from 'use-debounce';
import { FiDownload } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import axios from "axios";
import { baseURL } from "../../constants.js";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { useTheme } from '../../ThemeContext.js'; // Import useTheme


const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: `#f1f5f9`, // Optional: Change column headers background color
    borderBottom: '1px solid #dbeafe', // Added border bottom
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: `#f1f5f9`, // Tailwind CSS bg-grey-50 equivalent
  },
  '& .user-column-bold': {
    fontWeight: 'bold',
  },
});

const ManageUsers = () => {
  const { selectedColor } = useTheme();

  const [superAdmin, setSuperAdmin] = useState({});
  const navigate = useNavigate()
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    label: "Search Users",
    value: "",
  });
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 300); // Reduced debounce time for responsiveness
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);

  const decoded = jwt_decode(localStorage.getItem("auth"));
  const loggedInUserId = decoded.adminId;
  const loggedInUser = [
    {
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      role: "Admin",
      email: decoded.workEmail,
      contact: decoded.phone,
      jobTitle: decoded.jobTitle,
      department: decoded.department,
      location: decoded.location,
    },
  ];

  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handleChangeJobTitle = (event) => {
    setJobTitle(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleChangeDepartment = (event) => {
    setDepartment(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const getSuperAdminDetails = () => {
    axios.get(`${baseURL}/getSuperAdminDetails`).then((res) => {
      setSuperAdmin(res.data.superAdmin);
    })
  }

  useEffect(() => {
    getAllUsers();
    getSuperAdminDetails();
  }, []);


  const [formError, setFormError] = useState({});

  // ---------- Add User States Start

  const [showAddUser, setShowAddUser] = useState(false);
  const handleShowAddUser = () => setShowAddUser(true);
  const handleCloseAddUser = () => {
    setShowAddUser(false);
    // Reset form fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
    setJobTitle("");
    setPhone("");
    setDepartment("");
    setLocation("");
    setFormError({});
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();

    if (firstName && lastName && email && role && jobTitle) {
      setLoading(true);
      axios
        .post(`${baseURL}/registerUsers`, {
          firstName: firstName,
          lastName: lastName,
          workEmail: email,
          jobTitle: jobTitle,
          phone: phone ? phone : null,
          role: role,
          department: department ? department : null,
          location: location ? location : null,
        })
        .then(function (response) {
          if (response.data.message === "User already registered!") {
            toast.error("User with the same Email already exists.");
            setLoading(false);
          } else {
            const insertId = response.data.insertId;
            axios
              .post(`${baseURL}/logAdminEvent`, {
                eventType: "user created",
                info: `userName = ${firstName + " " + lastName}`,
              })
              .then((res) => { });
            getAllUsers();
            setLoading(false);
            handleCloseAddUser();
            toast.success("User Added Successfully");
          }
        })
        .catch(function (error) {
          // console.log(error);
          toast.error("Something went wrong, try again later");
        });
    } else {
      const err = {};
      if (!firstName) {
        err.firstName = "First Name is mandatory";
      }
      if (!lastName) {
        err.lastName = "Last Name is mandatory";
      }
      if (!email) {
        err.email = "Email is mandatory";
      }
      if (!role) {
        err.role = "Role is mandatory";
      }
      if (!jobTitle) {
        err.jobTitle = "Job Title is mandatory";
      }
      setFormError(err);
    }
  };


  // ---------- Add User States End

  // ---------- Bulk Import Modal Start

  const [showBulkImport, setShowBulkImport] = useState(false);
  const [excel, setExcel] = useState("");
  const [importedData, setImportedData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [missingColumns, setMissingColumns] = useState([]);

  const handleShowBulkImport = () => setShowBulkImport(true);
  const requiredFields = ["firstName", "lastName", "role", "email"];

  const handleBulkClose = () => {
    setShowBulkImport(false);
    setFormError({});
    setImportedData([]);
    setExcel("");
  };

  const handleExport = (e) => {
    e.preventDefault();
    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(loggedInUser);

    XLSX.utils.book_append_sheet(wb, ws, "Bulk Import Template");

    XLSX.writeFile(wb, "BulkUserImport.xlsx");
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wbSheetName = wb.SheetNames[0];
        const ws = wb.Sheets[wbSheetName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      setImportedData(data);
      setExcel(file);
    });
  };

  const handleBulkImport = () => {
    setFormError({});
    if (importedData.length) {
      // Get Array of obj properties
      const objProperties = importedData.map((obj) =>
        Object.getOwnPropertyNames(obj)
      );
      const nullColumns = objProperties.map((x) =>
        requiredFields.filter((keys) => !x.includes(keys))
      );

      const result = nullColumns.reduce(
        (accumulator, value) => accumulator.concat(value),
        []
      );
      setMissingColumns(result);

      if (!result.length) {
        const formData = new FormData();
        formData.append("excel", excel);
        axios.post(baseURL + "/uploadUsersFile", formData, {})
          .then((res) => {
            const usersCreated = res.data.successCount;
            axios.post(`${baseURL}/logAdminEvent`, {
              eventType: "bulk users created",
              info: `users created = ${usersCreated}`,
            })
              .then(() => {
                getAllUsers();
                handleBulkClose();
                toast.success("Data imported successfully");
              })
              .catch(() => {
                toast.error("Something went wrong. Please try again");
              });
          })
          .catch(() => {
            toast.error("Something went wrong. Please try again");
          });
      } else {
        const err = {};
        err.InvalidData = "Invalid Data";
        setFormError(err);
        toast.error("Invalid data. Please check your file and try again");
      }
    } else {
      const err = {};
      err.FileNotFound = "Please select a file.";
      setFormError(err);
      // toast.error("Please select a file");
    }
  };

  // ---------- Bulk Import Modal End

  // ----------Delete Row Start

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteUserObj, setDeleteUserObj] = useState([]);

  // function deleteUsers() {
  //   const deletePromises = deleteUserObj.map(userObject =>
  //     axios.delete(`${baseURL}/deleteUser/${userObject.id}/${userObject.role}`)
  //       .then(() =>
  //         axios.post(`${baseURL}/logAdminEvent`, {
  //           eventType: "user deleted",
  //           info: `userName = ${userObject.first_name} ${userObject.last_name}`,
  //         })
  //       )
  //   );

  //   Promise.all(deletePromises)
  //     .then(() => {
  //       getAllUsers();
  //       setFormError({});
  //       setDeleteModalShow(false);
  //       setDeleteUserObj([]);
  //       setSelectedRows([]);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // Handle error (e.g., show error message to user)
  //     });
  // }


  function deleteUsers() {
    const deletePromises = deleteUserObj.map(userObject =>
        axios.delete(`${baseURL}/deleteUser/${userObject.id}/${userObject.role}`)
            .then(() =>
                axios.post(`${baseURL}/logAdminEvent`, {
                    eventType: "user deleted",
                    info: `userName = ${userObject.first_name} ${userObject.last_name}`,
                })
            )
    );

    Promise.all(deletePromises)
        .then(() => {
            getAllUsers();
            setFormError({});
            setDeleteModalShow(false);
            setDeleteUserObj([]);
            
            if (deleteUserObj.length === 1) {
                toast.success(`User ${deleteUserObj[0].first_name} ${deleteUserObj[0].last_name} has been successfully deleted.`);
            } else {
                toast.success(`${deleteUserObj.length} users have been successfully deleted.`);
            }
        })
        .catch((error) => {
            console.error('Error deleting users:', error);
            
            if (deleteUserObj.length === 1) {
                toast.error(`Failed to delete user ${deleteUserObj[0].first_name} ${deleteUserObj[0].last_name}. Please try again.`);
            } else {
                toast.error(`Failed to delete users. Please try again.`);
            }
        });
}
 

  const handleDeleteUserClick = (ids) => {
    const idsArray = Array.isArray(ids) ? ids : [ids];
    const foundUsers = users.filter((user) => idsArray.includes(user.id));
    setDeleteUserObj(foundUsers);
    setDeleteModalShow(true);
  };

  // ----------Delete Row End 

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter(user =>
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(debouncedSearchText.toLowerCase())
      )
    );
  }, [debouncedSearchText, users]);


  function getAllUsers() {
    axios
      .get(`${baseURL}/getAllUsersByOrgId`)
      .then(function (response) {
        const loggedInUserRemoved = response.data.users.filter((u) => u.id !== loggedInUserId);
        setUsers(loggedInUserRemoved);
        const dashboardOptions = response.data?.users.map((user) => ({
          value: user.id,
          label: user.first_name + " " + user.last_name,
          key: user.role,
        }));
        setUserList(dashboardOptions);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
    // console.log('Opening menu for row:', row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };


  const editUser = (userId) => {
    navigate("/dashboard/settings/manage-users/edit-users/" + userId);
  };

  // const handleDeleteUserClick = (id) => {
  //   const foundUser = users.find((user) => user.id === id);
  //   setDeleteUserObj(foundUser);
  //   setDeleteModalShow(true);
  // };

  const columns = [
    { field: 'number', headerName: 'Id', width: 70, align: 'left', headerAlign: 'left' },
    { field: 'user', headerName: 'Users', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1 },
    { field: 'dashboards', headerName: 'Dashboards', type: 'number', flex: 1, align: 'left', headerAlign: 'left' },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="Options"
            onClick={(event) => handleOpenMenu(event, params.row)}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && currentRow?.id === params.row.id}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => {
              editUser(params.row.id);
              handleCloseMenu();
            }}>
              View
            </MenuItem>
            <MenuItem onClick={() => {
              handleDeleteUserClick(params.row.id);
              handleCloseMenu();
            }}>
              Delete
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const rows = filteredUsers.map((user, index) => ({
    id: user.id,
    number: index + 1,
    user: `${user.first_name} ${user.last_name}`,
    role: user.role === 2 ? 'User' : 'Admin',
    dashboards: user.count == null ? 0 : user.count, // Assuming there is a dashboards_count field in the response
  }));

  return (
    <>
      <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
        User Details
      </label>

      <div className="mt-3 flex justify-between items-center border-b border-gray-200">
        <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
          <div className="relative mt-3 mb-3 text-sm">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <IoSearchOutline className="w-5 h-5 text-gray-400" />
            </span>
            <input
              type="text"
              className="w-full py-1.5 pl-10 pr-4 text-gray-700 bg-gray-50 border rounded outline-none"
              placeholder="Search Users"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <div className="flex space-x-5">
          {selectedRows.length > 0 && (
            <button
              type="button"
              className="inline-flex justify-center items-center rounded bg-red-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => handleDeleteUserClick(selectedRows)}
            >
              <FiTrash2 className="w-4 h-4 mx-1" />
              <span className="text-sm">Delete Selected ({selectedRows.length})</span>
            </button>
          )}
          <button
            type="button"
            className={`inline-flex justify-center items-center rounded bg-white px-3 py-2 mb-1 text-xs font-semibold text-${selectedColor}-500 border border-${selectedColor}-500 shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`} onClick={handleShowBulkImport}
          >
            <FiDownload className="w-4 h-4 mx-1" />
            <span className="text-sm">Bulk Import</span>
          </button>
          <button
            type="button"
            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
            onClick={handleShowAddUser} // ---------- Add User Event Binding
          >
            <IoIosAdd className="w-5 h-5 mx-1" />
            <span className="text-sm">Add Users</span>
          </button>
        </div>
      </div>

      <div className="mt-6" style={{ height: 'auto', width: '100%' }}>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          rowHeight={45}
          columnHeaderHeight={50}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
        />
      </div>

      {/* ---------- Add User Modal Start */}

      {showAddUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-1/3 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Add User</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={handleCloseAddUser}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              <form className="add-user-modal w-75 text-sm" onSubmit={handleSaveInfo}>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <div>
                    <label className="text-gray-700" htmlFor="firstName">
                      First Name <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      onChange={handleChangeFirstName}
                      value={firstName}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter first name"
                      id="firstName"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.firstName}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="lastName">
                      Last Name <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChangeLastName}
                      value={lastName}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter last name"
                      id="lastName"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.lastName}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="jobTitle">
                      Job Title <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="jobTitle"
                      onChange={handleChangeJobTitle}
                      value={jobTitle}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter job title"
                      id="jobTitle"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.jobTitle}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="role">
                      Role <span className="text-red-500 font-bold">*</span>
                    </label>
                    <select
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      aria-label="Default select example"
                      onChange={handleChangeRole}
                      value={role}
                    >
                      <option value="" disabled>Select role</option>
                      <option value="User">User</option>
                      <option value="Admin">Admin</option>
                    </select>
                    <p className="text-red-500 text-xs mt-1">{formError.role}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="email">
                      Email <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChangeEmail}
                      value={email}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter email"
                      id="email"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.email}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="phone">
                      Phone
                    </label>
                    <input
                      type="text"
                      name="phone"
                      onChange={handleChangePhone}
                      value={phone}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter contact number"
                      id="phone"
                    />
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="department">
                      Department
                    </label>
                    <input
                      type="text"
                      name="department"
                      onChange={handleChangeDepartment}
                      value={department}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter department"
                      id="department"
                    />
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="location">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      onChange={handleChangeLocation}
                      value={location}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter location"
                      id="location"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-8 gap-4">
                  <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={handleCloseAddUser}>
                    Cancel
                  </button>
                  <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`} onClick={handleSaveInfo}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* ---------- Add User Modal End */}

      {/* ---------- Bulk Import Modal Start */}

      {showBulkImport && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/5 2xl:w-1/4 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Bulk Import Users</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={handleBulkClose}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              <div className="">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Download Template
                </label>
                <button
                  className={`bg-${selectedColor}-500 hover:bg-${selectedColor}-600 text-white font-medium py-2 px-4 rounded text-sm`}
                  onClick={handleExport}
                >
                  Download
                </button>
                <p className="text-sm text-gray-600 mt-4 italic">
                  Note: Please download the template and do not remove the first entry in the Excel sheet. Removing it may result in incorrect data import.
                </p>
              </div>
              <div className="mb-4 mt-5">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Upload an excel sheet
                </label>
                <div className={`mt-1 flex justify-between items-center px-4 py-2 bg-${selectedColor}-50 rounded-md`}>
                  <span className="text-sm text-gray-500">
                    {excel ? excel.name : "No file chosen"}
                  </span>
                  <label className={`cursor-pointer bg-${selectedColor}-500 hover:bg-${selectedColor}-600 text-white font-medium py-1 px-3 rounded text-sm`}>
                    Choose file
                    <input
                      type="file"
                      className="hidden"
                      onChange={(e) => readExcel(e.target.files[0])}
                      accept=".xlsx,.xls"
                    />
                  </label>
                </div>
              </div>
              {formError.FileNotFound && (
                <p className="text-red-500 text-xs mt-1">{formError.FileNotFound}</p>
              )}
              {formError.InvalidData && (
                <>
                  <p className="text-red-500 text-xs mt-1">
                    Kindly check if the below mentioned columns are null.
                  </p>
                  <div className="flex flex-wrap gap-1 mt-1">
                    {missingColumns.map((column) => (
                      <span
                        key={column}
                        className="px-2 py-1 text-xs font-medium text-white bg-red-500 rounded-full"
                      >
                        {column}
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="px-6 py-3 flex justify-end gap-4">
              <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={handleBulkClose}>
                Cancel
              </button>
              <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`} onClick={handleBulkImport}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteModalShow && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-1/3 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Delete User{deleteUserObj.length > 1 ? 's' : ''}</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setDeleteModalShow(false)}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              Are you sure you want to delete {deleteUserObj.length === 1 ? 'this user' : 'these users'}?
              {deleteUserObj.some(user => user.role === 1) && (
                <> If yes, all the ownership would be transferred to Super Admin '{superAdmin.first_name} {superAdmin.last_name}'.</>
              )}
              {deleteUserObj.length > 0 && (
                <ul className="mt-2 list-disc list-inside">
                  {deleteUserObj.map(user => (
                    <li key={user.id}>
                      {user.first_name} {user.last_name}
                      {user.role === 1 && " (Admin)"}
                      {user.role === 2 && " (User)"}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="px-6 py-4 border-t flex justify-end space-x-5">
              <button
                className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                onClick={() => {
                  setDeleteModalShow(false);
                  setDeleteUserObj([]);
                }}
              >
                Cancel
              </button>

              <button
                className="inline-flex justify-center items-center rounded bg-red-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"
                onClick={() => deleteUsers()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ---------- Bulk Import Modal End */}
    </>
  );
};

export default ManageUsers;
