import React, { useState, useRef, useEffect } from 'react';
import CompanyLogo from '../../assets/companyLogo.png';
import axios from "axios";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import userIcon from "../../assets/user.png";
import { baseURL } from '../../constants.js';
import toast from "react-hot-toast";

import { LuLayoutDashboard } from 'react-icons/lu';
import { IoAnalytics, IoSettingsOutline, IoTicketOutline, IoSearchOutline } from 'react-icons/io5';
import { PiPaintBucket } from 'react-icons/pi';
import { PiWrench } from "react-icons/pi";
import { LuUser2 } from "react-icons/lu";
import { HiOutlineUsers } from "react-icons/hi2";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineAttachEmail } from "react-icons/md";

import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Tooltip } from 'react-tooltip'
import { FiEdit } from "react-icons/fi";
import { LuChevronsLeft, LuChevronsRight } from "react-icons/lu";

import { BsPersonCircle } from "react-icons/bs";
import { TbLogout2 } from "react-icons/tb";

import { IoInformationCircleOutline } from "react-icons/io5";


import tableauLogo from '../../assets/tableau.png'
import powerbiLogo from '../../assets/powerbi.png'
import domoLogo from '../../assets/domo.png'

import { useTheme } from '../../ThemeContext.js'; // Import useTheme

const Sidebar = ({ reRenderSidebar }) => {
    // console.log("re-rendering sidebar")

    const navigate = useNavigate();
    const locationName = useLocation();
    const { selectedColor, setSelectedColor, previewColor, setPreviewColor } = useTheme();
    const { userId, groupId, dashboardPlatform, id } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
    const [isSecondarySidebarOpen, setIsSecondarySidebarOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('dashboard');
    const [profileTab, setProfileTab] = useState('userProfile');
    const [activeSubTab, setActiveSubTab] = useState('');
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText] = useDebounce(searchText, 300);
    const [profileImage, setProfileImage] = useState(userIcon);

    const decoded = localStorage.getItem("auth")
        ? jwt_decode(localStorage.getItem("auth"))
        : null;
    const userRole = decoded?.role;
    const fullName = decoded?.firstName + " " + decoded?.lastName;
    const workEmail = decoded?.workEmail;

    useEffect(() => {
        // console.log("called the useEffect with reRenderSidebar")
        getAllDashboards();
        axios.get(`${baseURL}/retrieveProfileImage`).then((response) => {
            const profileImageUrl = response.data.dataUrl;
            const image = new Image();
            image.onload = () => setProfileImage(profileImageUrl);
            image.onerror = () => setProfileImage(userIcon);
            image.src = profileImageUrl;
        });
    }, [reRenderSidebar]);

    const getAllDashboards = () => {
        axios.get(`${baseURL}/getDashboardsById`)
            .then(function (response) {
                const dashboards = response.data.dashboards;
                const dashboardOptions = dashboards.map((dashboard) => ({
                    label: dashboard.dashboard_name,
                    icon: dashboard.dashboard_platform_flag === 1 ? powerbiLogo :
                        dashboard.dashboard_platform_flag === 0 ? domoLogo :
                            dashboard.dashboard_platform_flag === 2 ? tableauLogo :
                                LuLayoutDashboard,
                    description: `Dashboard platform: ${dashboard.dashboard_platform_flag}`,
                    dashboardOwner: `${dashboard.first_name} ${dashboard.last_name}`,
                    value: userRole === 1 ? dashboard.id : dashboard.dashboard_id,
                    key: dashboard.dashboard_platform_flag,
                }));
                setSecondarySidebarContent(prevContent => ({
                    ...prevContent,
                    dashboard: dashboardOptions
                }));
            })
            .catch(function (error) {
                // console.log(error);
            });
    }


    useEffect(() => {
        const path = locationName.pathname.toLowerCase();
        if (path.startsWith("/dashboard/settings")) {
            setActiveTab('settings');
            const subTabMapping = {
                "/dashboard/settings/branding": 'Branding',
                "/dashboard/settings/configure": 'Configure',
                "/dashboard/settings/manage-users": 'Manage Users',
                "/dashboard/settings/manage-groups": 'Manage Groups',
                "/dashboard/settings/add-dashboards": 'Add Dashboards',
                "/dashboard/settings/smtp-setup": 'SMTP Setup',
            };
            const matchingSubTab = Object.entries(subTabMapping).find(([route]) => path.startsWith(route));
            if (matchingSubTab) {
                setActiveSubTab(matchingSubTab[1]);
            } else {
                setActiveSubTab('');
            }
        } else if (path.startsWith("/dashboard/view-dashboard")) {
            setActiveTab('dashboard');
            const dashboardPath = path.split('/').slice(2, 5).join('/');
            setActiveSubTab(dashboardPath);
        } else if (path === "/dashboard/about") {
            setActiveTab('about');
            setActiveSubTab('About');
        } else if (path === "/dashboard/orgs") {
            setActiveTab('manage');
            setActiveSubTab('Manage Organisations');
        } else if (path === "/dashboard/view-analytics-dashboard") {
            setActiveTab('analytics');
            setActiveSubTab('Analytics Dashboard');
        } else {
            setActiveTab('dashboard');
            setActiveSubTab('');
        }
    }, [locationName.pathname, userId, groupId, id, dashboardPlatform]);

    const sidebarItems = [
        { id: 'dashboard', icon: LuLayoutDashboard, label: 'Dashboard' },
        // { id: 'analytics', icon: IoAnalytics, label: 'Analytics' },
        { id: 'settings', icon: IoSettingsOutline, label: 'Settings' },
        { id: 'about', icon: IoInformationCircleOutline, label: 'About' },
        ...(userRole === 0 ? [{ id: 'manage', icon: IoSettingsOutline, label: 'Manage' }] : []),
    ];

    const [secondarySidebarContent, setSecondarySidebarContent] = useState({
        dashboard: [],
        analytics: [
            { label: 'Reports', icon: IoAnalytics, description: 'Lorem ipsum dolor sit amet, consectetur dio oadipiscing elit, sed do eiusmod consrm' },
            { label: 'Performance', icon: IoAnalytics, description: 'Lorem ipsum dolor sit amet, consectetur dio oadipiscing elit, sed do eiusmod consrm' },
        ],
        settings: [
            { label: 'Branding', icon: PiPaintBucket, description: `Customize your app's color scheme, logo, and login page banner for a unified brand identity.` },
            { label: 'Configure', icon: PiWrench, description: 'Adjust app settings and credentials for importing assets, tailoring functionality to your needs.' },
            { label: 'Manage Users', icon: LuUser2, description: 'Add, edit, or remove users and control their access permissions.' },
            { label: 'Manage Groups', icon: HiOutlineUsers, description: 'Organize users into groups and manage group-level permissions.' },
            { label: 'Add Dashboards', icon: MdOutlineDashboardCustomize, description: 'Add and manage assets like Dashboards and Tables.' },
            { label: 'SMTP Setup', icon: MdOutlineAttachEmail, description: 'Configure email server settings for sending notifications and alerts.' },
        ],
        about: [
            { label: 'About', icon: IoInformationCircleOutline, description: 'Learn more about our application and its features.' },
        ],
        ...(userRole === 0 ? {
            manage: [
                { label: 'Manage Organisations', icon: HiOutlineUsers, description: 'Add, edit, or remove organizations and manage their settings.' },
            ],
        } : {}),
    });

    const filteredSidebarContent = secondarySidebarContent[activeTab].filter(item =>
        item?.label?.toLowerCase().includes(debouncedSearchText.toLowerCase()) ||
        (item?.description && item?.description?.toLowerCase().includes(debouncedSearchText.toLowerCase()))
    );

    const toggleSecondarySidebar = () => {
        setIsSecondarySidebarOpen(!isSecondarySidebarOpen);
    };

    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const toggleProfileDropdown = () => {
        setIsProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const handleSignOut = () => {
        setIsLogoutModalOpen(true);
    };

    const confirmSignOut = () => {
        signOut();
        setIsLogoutModalOpen(false);
    };

    function signOut() {
        axios
            .post(`${baseURL}/logEvent`, {
                eventType: "Logout",
                dashboardId: null,
                userId: decoded.adminId,
            }).then((res) => {
                removeCookie("auth");
                localStorage.removeItem("auth");
                navigate("/");
            });
    }

    const [logo, setLogo] = useState("");
    const orgId = decoded.organisationId;

    const fetchLogo = async () => {
        try {
            const response = await axios.get(`${baseURL}/retrieveOrgIcon/${orgId}`);
            const logoUrl = response.data.dataUrl;

            try {
                await checkImageExists(logoUrl);
                setLogo(logoUrl);
            } catch {
                setLogo(CompanyLogo);
            }
        } catch (error) {
            console.error("Error retrieving logo:", error);
            toast.error("Error retrieving logo. Please try again.");
            setLogo(CompanyLogo);
        }
    };

    useEffect(() => {
        fetchLogo();
    }, []);
    // ----------- PROFILE SECTION STATES AND FUNCTIONS ----------------------

    //let { userId } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [dashboardList, setDashboardList] = useState([]);
    const [groups, setGroups] = useState([]);
    // const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
    const [formError, setFormError] = useState({});
    // const [profileImage, setProfileImage] = useState(userIcon);
    const [isImageOpen, setIsImageOpen] = useState(false);
    const MyContext = React.createContext();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [department, setDepartment] = useState("");
    const [location, setLocation] = useState("");
    // const decoded = localStorage.getItem("auth")
    //     ? jwt_decode(localStorage.getItem("auth"))
    //     : null;


    // const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const profileDropdownRef = useRef(null); // Reference to the profile dropdown

    const handleClickOutside = (event) => {
        if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
            setIsProfileDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (isProfileDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Clean up the event listener on component unmount or when dropdown closes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isProfileDropdownOpen]);

    const userDetails = {
        adminId: decoded.adminId,
        companyName: decoded.companyName,
        department: decoded.department,
        fullName: decoded.firstName + " " + decoded.lastName,
        firstName: decoded.firstName,
        lastName: decoded.lastName,
        iat: decoded.iat,
        jobTitle: decoded.jobTitle,
        location: decoded.location,
        organisationId: decoded.organisationId,
        phone: decoded.phone,
        role: decoded.role,
        workEmail: decoded.workEmail,
    };

    // const [editUserData, setEditUserData] = useState({ ...userDetails });

    function getUserInfo() {
        axios
            .get(`${baseURL}/getUserInfoById/${userDetails.adminId}`)
            .then(function (response) {
                setFirstName(response.data.user[0].first_name);
                setLastName(response.data.user[0].last_name);
                setJobTitle(response.data.user[0].job_title);
                setRole(response.data.user[0].role == 2 ? "User" : "Admin");
                setEmail(response.data.user[0].work_email);
                setPhone(response.data.user[0].phone);
                setDepartment(response.data.user[0].department);
                setLocation(response.data.user[0].location);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        getUserInfo();
        getDashboards();
        getAllGroups();
        getUserImage();
    }, []);

    const getUserImage = () => {
        axios.get(`${baseURL}/retrieveProfileImage`).then((response) => {
            const profileImageUrl = response.data.dataUrl;
            checkImageExists(profileImageUrl)
                .then(() => {
                    // console.log("Image exists");
                    setProfileImage(profileImageUrl);
                })
                .catch(() => {
                    // console.log("Image does not exist");
                    setProfileImage(userIcon);
                });
        });
    };

    const handleImageClick = () => {
        setIsImageOpen(true);
    };

    const handleClose = () => {
        setIsImageOpen(false);
    };

    const handleFullNameChange = (e) => {
        const fieldValue = e.target.value;
        const fullname = fieldValue.split(" ");
        setFirstName(fullname[0].charAt(0).toUpperCase() + fullname[0].slice(1));
        setLastName(fullname[1]?.charAt(0).toUpperCase() + fullname[1]?.slice(1));
    };
    const handleJobtitleChange = (e) => {
        setJobTitle(e.target.value);
    };
    const handleDepartmentChange = (e) => {
        setDepartment(e.target.value);
    };
    const handleLocationChange = (e) => {
        setLocation(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };


    const editUserSubmit = (e) => {
        e.preventDefault();
        if (
            firstName &&
            lastName &&
            phone &&
            email &&
            jobTitle &&
            department &&
            location
        ) {
            axios
                .put(`${baseURL}/updateUsers/${userDetails.adminId}`, {
                    firstName: firstName,
                    lastName: lastName,
                    workEmail: email,
                    jobTitle: jobTitle,
                    phone: phone,
                    department: department,
                    location: location,
                })
                .then(function (response) {
                    axios
                        .post(`${baseURL}/logAdminEvent`, {
                            eventType: "user updated",
                            info: `userId = ${userDetails.adminId}`,
                        })
                        .then((res) => {
                            // Log success message if necessary
                        });

                    toast.success("User information updated successfully!");
                    setEditMode(false);
                    getUserInfo(); // Refresh user info after successful update
                })
                .catch(function (error) {
                    toast.error("Failed to update user information. Please try again.");
                    console.error("Error updating user:", error);
                });
        } else {
            const err = {};
            if (!firstName) {
                err.firstName = "First Name is mandatory";
            }
            if (!lastName) {
                err.lastName = "Last Name is mandatory";
            }
            if (!email) {
                err.email = "Email is mandatory";
            }
            if (!phone) {
                err.phone = "Contact number is mandatory";
            }
            if (!jobTitle) {
                err.jobTitle = "Job Title is mandatory";
            }
            if (!department) {
                err.department = "Department is mandatory";
            }
            if (!location) {
                err.location = "Location is mandatory";
            }
            setFormError(err);
        }
    };


    const handleUserImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) {
            toast.error("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("image", file);

        axios.post(`${baseURL}/uploadProfileImage`, formData)
            .then((response) => {
                toast.success("Profile image updated successfully!");
                getUserImage();  // Assuming this function is defined elsewhere
                // Optionally, refresh the page if needed
                // window.location.reload(true);
            })
            .catch((error) => {
                toast.error("Error uploading profile image. Please try again.");
                console.error("Error uploading profile image:", error);
            });
    };

    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    }

    const getAllGroups = () => {
        if (userDetails.role == 1) {
            axios.get(baseURL + `/getAllGroupsByOrgId`).then((response) => {
                setGroups(response.data.groups);
                // console.log(response.data.groups);
            });
        } else if (userDetails.role == 2) {
            axios.get(baseURL + `/getAllUserGroups`).then((response) => {
                setGroups(response.data.groups);
                // console.log(response.data.groups);
            });
        }
    };

    function getDashboards() {
        axios
            .get(`${baseURL}/getDashboardsById`)
            .then(function (response) {
                const dashboards = response.data.dashboards;
                // setDashboards(dashboards);
                // setFilteredDashboards(dashboards);
                const dashboardOptions = dashboards.map((dashboard) => ({
                    value: dashboard.id,
                    label: dashboard.dashboard_name,
                }));
                // console.log(dashboardOptions);
                setDashboardList(dashboardOptions);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const handleTabClick = (tabName) => {
        setProfileTab(tabName);
        // console.log(tabName);
    };

    useEffect(() => {
        const fetchSavedTheme = async () => {
            try {
                const token = localStorage.getItem("auth");
                const decoded = jwt_decode(token);
                const org_id = decoded.organisationId;
                const userRole = decoded.userRole; // Assuming userRole is in the token payload

                // Check if the userRole is 2
                if (userRole === 2) {
                    // console.log('User role is 2, fetching saved theme...');
                    const response = await axios.get(`${baseURL}/getColorTheme/${org_id}`);
                    const savedTheme = response.data.colorTheme;
                    setSelectedColor(savedTheme);
                    setPreviewColor(savedTheme);
                }
            } catch (error) {
                // console.log("Error fetching saved theme:", error);
                toast.error('Failed to fetch the saved theme.');
            }
        };

        fetchSavedTheme();
    }, []); // Add dependencies if necessary



    return (
        <>
            <div className="flex h-screen overflow-hidden">
                <aside className="flex flex-col items-center w-16 h-screen py-8 bg-white relative">
                    <nav className="flex flex-col items-center flex-1 space-y-8">
                        <div className="relative rounded p-2">
                            {logo ? (
                                <img className="w-full h-full object-contain" src={logo} alt="Logo" />
                            ) : (
                                <img className="w-full h-full object-contain" src={CompanyLogo} alt="Logo" />
                            )}
                        </div>
                        {sidebarItems.map((item, index) => {
                            if (userRole === 0) {
                                if (index === 4) {
                                    return (
                                        <button
                                            key={item.id}
                                            onClick={() => {
                                                setActiveTab(item.id);
                                                if (item.id === 'analytics') {
                                                    navigate('/dashboard/view-analytics-dashboard');
                                                }
                                            }}
                                            className={`p-1.5 inline-block transition-colors duration-200 rounded-lg 
                                    ${activeTab === item.id ? `text-white bg-${selectedColor}-500` : 'text-gray-900 hover:bg-gray-100'}`}
                                        >
                                            <item.icon className="w-6 h-6" />
                                        </button>
                                    )
                                } else {
                                    return null
                                }
                            } if (userRole === 2) {
                                if (index === 0) {
                                    return (
                                        <button
                                            key={item.id}
                                            onClick={() => {
                                                setActiveTab(item.id);
                                                if (item.id === 'analytics') {
                                                    navigate('/dashboard/view-analytics-dashboard');
                                                }
                                            }}
                                            className={`p-1.5 inline-block transition-colors duration-200 rounded-lg 
                                    ${activeTab === item.id ? `text-white bg-${selectedColor}-500` : 'text-gray-900 hover:bg-gray-100'}`}
                                        >
                                            <item.icon className="w-6 h-6" />
                                        </button>
                                    )
                                } else {
                                    return null
                                }
                            }
                            else {
                                return (
                                    <button
                                        key={item.id}
                                        onClick={() => {
                                            setActiveTab(item.id);
                                            if (item.id === 'analytics') {
                                                navigate('/dashboard/view-analytics-dashboard');
                                            }
                                        }}
                                        className={`p-1.5 inline-block transition-colors duration-200 rounded-lg 
                                ${activeTab === item.id ? `text-white bg-${selectedColor}-500` : 'text-gray-900 hover:bg-gray-100'}`}
                                    >
                                        <item.icon className="w-6 h-6" />
                                    </button>
                                )
                            }
                        })}
                    </nav>
                    {/* Profile dropdown */}
                    {/* <div className="flex flex-col items-center mt-4 relative">
                        <button
                            onClick={toggleProfileDropdown}
                            className={`focus:outline-none transition-all duration-200 ease-in-out rounded-full
                        ${isProfileDropdownOpen ? `ring-2 ring-offset-2 ring-${selectedColor}-500` : ''}`}
                        >
                            <img
                                className="object-cover w-8 h-8 rounded-full"
                                src={profileImage}
                                alt="avatar"
                            />
                        </button>
                        {isProfileDropdownOpen && (
                            <div className="absolute z-50 left-full ml-2 p-1 bottom-0 bg-white border border-gray-200 rounded-md shadow-md w-56">
                                <div className="flex items-center w-full text-left px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-sm">
                                    <img
                                        className="object-cover w-9 h-9 rounded-full"
                                        src={profileImage}
                                        alt="avatar"
                                    />
                                    <div className='flex flex-col space-y-1 ml-1'>
                                        <p className="ml-2 text-sm text-gray-900 font-medium">{fullName}</p>
                                        <p className="ml-2 text-xs text-gray-400">{userRole === 1 ? "Admin" : userRole === 2 ? "User" : userRole === 0 ? "Super Admin" : ""}</p>
                                    </div>
                                </div>
                                <hr class="border-gray-200" />
                                <button
                                    className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 rounded-sm"
                                    onClick={() => {
                                        // Handle view profile action
                                        console.log("View profile clicked");
                                        setIsProfileDropdownOpen(false);
                                        openModal();
                                    }}
                                >
                                    <BsPersonCircle className="mr-2" />
                                    View profile
                                </button>
                                <button
                                    className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 rounded-sm"
                                    onClick={() => {
                                        setIsProfileDropdownOpen(false);
                                        handleSignOut();
                                    }}
                                >
                                    <TbLogout2 className="mr-2" />
                                    Logout
                                </button>
                            </div>
                        )}

                    </div> */}

                    <div className="flex flex-col items-center mt-4 relative">
                        <button
                            onClick={toggleProfileDropdown}
                            className={`focus:outline-none transition-all duration-200 ease-in-out rounded-full
                        ${isProfileDropdownOpen ? `ring-2 ring-offset-2 ring-${selectedColor}-500` : ''}`}
                        >
                            <img
                                className="object-cover w-8 h-8 rounded-full"
                                src={profileImage}
                                alt="avatar"
                            />
                        </button>

                        {isProfileDropdownOpen && (
                            <div ref={profileDropdownRef} className="absolute z-50 left-full ml-2 p-1 bottom-0 bg-white border border-gray-200 rounded-md shadow-md w-56">
                                {/* Dropdown content */}
                                <div className="flex items-center w-full text-left px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-sm">
                                    <img
                                        className="object-cover w-9 h-9 rounded-full"
                                        src={profileImage}
                                        alt="avatar"
                                    />
                                    <div className='flex flex-col space-y-1 ml-1'>
                                        <p className="ml-2 text-sm text-gray-900 font-medium">{fullName}</p>
                                        <p className="ml-2 text-xs text-gray-400">{userRole === 1 ? "Admin" : userRole === 2 ? "User" : userRole === 0 ? "Super Admin" : ""}</p>
                                    </div>
                                </div>
                                <hr className="border-gray-200" />
                                <button
                                    className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 rounded-sm"
                                    onClick={() => {
                                        setIsProfileDropdownOpen(false);
                                        openModal();
                                    }}
                                >
                                    <BsPersonCircle className="mr-2" />
                                    View profile
                                </button>
                                <button
                                    className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 rounded-sm"
                                    onClick={() => {
                                        setIsProfileDropdownOpen(false);
                                        handleSignOut();
                                    }}
                                >
                                    <TbLogout2 className="mr-2" />
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </aside>

                {/* Secondary sidebar toggle button (when closed) */}
                {!isSecondarySidebarOpen && (
                    <button
                        onClick={toggleSecondarySidebar}
                        className={`fixed top-8 left-16 z-20 p-2 bg-${selectedColor}-500 text-white rounded-r-md hover:bg-${selectedColor}-600 transition-all duration-300 cursor-pointer`}
                    >
                        <LuChevronsRight className="w-5 h-5" />
                    </button>
                )}

                {/* Secondary sidebar */}
                <div className={`h-screen overflow-y-auto bg-${selectedColor}-50 border-l border-r lg:overflow-y-scroll transition-all duration-300 ${isSecondarySidebarOpen ? 'sm:w-64 w-60 px-5 py-8' : 'w-0'}`}>
                    {isSecondarySidebarOpen && (
                        <>
                            <div className="flex justify-between items-center px-3 mb-5">
                                <label className="text-2xl text-gray-900 capitalize font-medium">
                                    {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
                                </label>
                                <button
                                    onClick={toggleSecondarySidebar}
                                    className={`rounded-sm p-1 bg-${selectedColor}-500 text-white hover:bg-${selectedColor}-600 cursor-pointer`}
                                >
                                    <LuChevronsLeft className="w-5 h-5" />
                                </button>
                            </div>

                            {(activeTab === 'settings' || activeTab === 'dashboard' || activeTab === 'about' || activeTab === 'manage') && (
                                <div className="relative mb-5">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <IoSearchOutline className="w-5 h-5 text-gray-400" />
                                    </span>
                                    <input
                                        type="text"
                                        className={`w-full py-1.5 pl-10 pr-4 text-gray-700 bg-${selectedColor}-100 border rounded-md focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                            )}

                            <nav className="mt-5 -mx-3 space-y-3">
                                <div className="space-y-3">
                                    {activeTab === 'dashboard' && filteredSidebarContent.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`/dashboard/view-dashboard/${item.value}/${item.key}`}
                                            className={`flex flex-col items-start px-3 py-2 text-gray-900 transition-colors duration-300 transform rounded-lg 
                ${activeSubTab === `view-dashboard/${item.value}/${item.key}` ? `bg-${selectedColor}-500 text-white` : `hover:bg-${selectedColor}-400 hover:text-white`} group`}
                                        >
                                            <div className="flex flex-col items-left">
                                                <div className='flex flex-row gap-1'>
                                                    <span className="text-sm font-medium">{item.label}</span>
                                                </div>
                                                <p className={`text-xs transition-colors duration-300 transform ${activeSubTab === `view-dashboard/${item.value}/${item.key}` ? 'text-white' : 'text-gray-400'} group-hover:text-white mt-3 leading-5`}>
                                                    Owned by {item.dashboardOwner}
                                                </p>
                                            </div>
                                        </Link>
                                    ))}

                                    {activeTab === 'settings' && filteredSidebarContent.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`/dashboard/${activeTab}/${item.label.toLowerCase().replace(/ /g, '-')}`}
                                            className={`flex flex-col items-start px-3 py-2 text-gray-900 transition-colors duration-300 transform rounded-lg 
                ${activeSubTab === item.label ? `bg-${selectedColor}-500 text-white` : `hover:bg-${selectedColor}-400 hover:text-white`} group`}
                                        >
                                            <div className="flex flex-col items-left">
                                                <div className='flex flex-row gap-1'>
                                                    <item.icon className={`w-5 h-5 ${activeSubTab === item.label ? 'text-white' : 'text-gray-900'} group-hover:text-white`} />
                                                    <span className="mx-2 text-sm font-medium">{item.label}</span>
                                                </div>
                                                <p className={`text-xs transition-colors duration-300 transform ${activeSubTab === item.label ? 'text-white' : 'text-gray-400'} group-hover:text-white mt-3 leading-5`}>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </Link>
                                    ))}

                                    {activeTab === 'about' && (
                                        <Link
                                            to="/dashboard/about"
                                            className={`flex flex-col items-start px-3 py-2 text-gray-900 transition-colors duration-300 transform rounded-lg 
                ${activeSubTab === 'About' ? `bg-${selectedColor}-500 text-white` : `hover:bg-${selectedColor}-400 hover:text-white`} group`}
                                        >
                                            <div className="flex flex-col items-left">
                                                <div className='flex flex-row gap-1'>
                                                    <IoInformationCircleOutline className={`w-5 h-5 ${activeSubTab === 'About' ? 'text-white' : 'text-gray-900'} group-hover:text-white`} />
                                                    <span className="mx-2 text-sm font-medium">About</span>
                                                </div>
                                                <p className={`text-xs transition-colors duration-300 transform ${activeSubTab === 'About' ? 'text-white' : 'text-gray-400'} group-hover:text-white mt-3 leading-5`}>
                                                    Learn more about our application and its features.
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                    {activeTab === 'manage' && (
                                        <Link
                                            to="/dashboard/orgs"
                                            className={`flex flex-col items-start px-3 py-2 text-gray-900 transition-colors duration-300 transform rounded-lg 
                ${activeSubTab === 'Manage Organisations' ? `bg-${selectedColor}-500 text-white` : `hover:bg-${selectedColor}-400 hover:text-white`} group`}
                                        >
                                            <div className="flex flex-col items-left">
                                                <div className='flex flex-row gap-1'>
                                                    <IoInformationCircleOutline className={`w-5 h-5 ${activeSubTab === 'Manage Organisations' ? 'text-white' : 'text-gray-900'} group-hover:text-white`} />
                                                    <span className="mx-2 text-sm font-medium">Manage Organisations</span>
                                                </div>
                                                <p className={`text-xs transition-colors duration-300 transform ${activeSubTab === 'Manage Organisations' ? 'text-white' : 'text-gray-400'} group-hover:text-white mt-3 leading-5`}>
                                                    Add, edit, or remove organizations and manage their settings.
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                    {activeTab === 'analytics' && (
                                        <Link
                                            to="/dashboard/view-analytics-dashboard"
                                            className={`flex flex-col items-start px-3 py-2 bg-${selectedColor}-500 text-white transition-colors duration-300 transform rounded-lg group`}
                                        >
                                            <div className="flex flex-col items-left">
                                                <div className='flex flex-row gap-1'>
                                                    <IoAnalytics className="w-5 h-5 text-white" />
                                                    <span className="mx-2 text-sm font-medium">Analytics</span>
                                                </div>
                                                <p className="text-xs transition-colors duration-300 transform text-white mt-3 leading-5">
                                                    Gain valuable insights into your organization's performance with our Analytics dashboard.
                                                </p>
                                            </div>
                                        </Link>

                                    )}

                                </div>
                            </nav>
                        </>

                    )}
                </div>
                {/* Main content */}
                <main className="flex-1 overflow-y-auto">
                    {/* Your main content goes here */}
                </main>
                <>
                    {/* Logout Confirmation Modal */}
                    {isLogoutModalOpen && (
                        <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                            <div className="bg-white rounded shadow-xl w-1/3 2xl:w-1/3 transition-transform transform duration-300">
                                <div className="px-6 py-4 flex justify-between items-center border-b">
                                    <h3 className="text-lg font-semibold text-gray-800">Logout Confirmation</h3>
                                    <button
                                        className="text-gray-400 hover:text-gray-500"
                                        onClick={() => setIsLogoutModalOpen(false)}
                                    >
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="px-6 py-4">
                                    Are you sure you want to log out?
                                </div>
                                <div className="px-6 py-4 border-t flex justify-end space-x-5">
                                    <button
                                        className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                        onClick={() => setIsLogoutModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="inline-flex justify-center items-center rounded bg-red-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"
                                        onClick={confirmSignOut}
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {isOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white rounded-lg p-6 pb-0 w-full max-w-4xl max-h-[85vh] overflow-y-auto">
                                {/* Modal Header */}
                                <div className="flex justify-between items-center border-b pb-2">
                                    <h2 className="text-xl font-semibold">User Profile</h2>
                                    <button
                                        className="text-gray-400 hover:text-gray-500"
                                        onClick={closeModal}
                                    >
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                {/* Modal Tabs */}
                                <div className="mt-8 flex justify-between items-center border-b border-gray-200">
                                    <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
                                        <button
                                            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${profileTab === 'userProfile'
                                                ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                                                : 'text-gray-700 border-b-2 border-transparent'
                                                } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                                            onClick={() => handleTabClick('userProfile')}
                                        >
                                            <LuUser2 className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
                                            <span className="mx-1 text-xs sm:text-sm font-medium">
                                                User Information
                                            </span>
                                        </button>

                                        <button
                                            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${profileTab === 'assignedDashboardsAndGroups'
                                                ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                                                : 'text-gray-700 border-b-2 border-transparent'
                                                } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                                            onClick={() => handleTabClick('assignedDashboardsAndGroups')}
                                        >
                                            <LuLayoutDashboard className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
                                            <span className="mx-1 text-xs sm:text-sm font-medium">
                                                Assigned Dashboards
                                            </span>
                                        </button>
                                    </div>

                                    {profileTab === 'userProfile' && (
                                        <button
                                            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-2 py-2 mb-1 leading-5 font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                            onClick={() => setEditMode(true)}
                                        >
                                            <FiEdit className="w-4 h-4 mx-1 sm:w-4 sm:h-4" />
                                            <span className="mx-1 text-xs sm:text-sm font-medium">Edit Profile</span>
                                        </button>
                                    )}
                                </div>

                                {/* User Profile Content */}
                                <div className="my-4 relative">
                                    {profileTab === 'userProfile' && (
                                        <>
                                            <div className="px-4 py-2">
                                                <form className="add-user-modal w-75 text-sm" onSubmit={editUserSubmit}>
                                                    <div className="relative flex justify-center items-center w-36 mx-auto p-2">
                                                        <img
                                                            src={profileImage}
                                                            alt="avatar"
                                                            className="rounded-full w-32 h-32 object-cover"
                                                            onClick={handleImageClick}
                                                        />
                                                        <label
                                                            className={`absolute bottom-4 right-3 transform -translate-x-1/2 translate-y-1/2 bg-gray-100 rounded-full p-2 cursor-pointer `}
                                                            htmlFor="profilePic"
                                                        >
                                                            <FiEdit className="text-gray-700 w-4 h-4" />
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            accept=".jpg, .jpeg, .png"
                                                            id="profilePic"
                                                            onChange={handleUserImageUpload}
                                                        />
                                                    </div>

                                                    <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                                                        <div>
                                                            <label className="text-gray-700" htmlFor="firstName">
                                                                Name
                                                            </label>
                                                            <input
                                                                readOnly={!editMode}
                                                                onChange={handleFullNameChange}
                                                                value={firstName + " " + lastName}
                                                                title="Input title"
                                                                name="fullName"
                                                                type="text"
                                                                id="email_field"
                                                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                                placeholder="Enter first name"
                                                            />
                                                            <p className="text-red-500 text-xs mt-1">{formError.firstName}</p>
                                                        </div>

                                                        <div>
                                                            <label className="text-gray-700" htmlFor="jobTitle">
                                                                Job Title
                                                            </label>
                                                            <input
                                                                type="text"
                                                                readOnly={!editMode}
                                                                value={jobTitle}
                                                                onChange={handleJobtitleChange}
                                                                name="jobTitle"
                                                                title="Job Title"
                                                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                                placeholder="Enter job title"
                                                            />
                                                            <p className="text-red-500 text-xs mt-1">{formError.jobTitle}</p>
                                                        </div>
                                                        <div>
                                                            <label className="text-gray-700" htmlFor="email">
                                                                Email
                                                            </label>
                                                            <input
                                                                readOnly={!editMode || role === "User"}
                                                                onChange={handleEmailChange}
                                                                value={email}
                                                                name="workEmail"
                                                                type="text"
                                                                id="email_field"
                                                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                                placeholder="Enter email"
                                                            />
                                                            <p className="text-red-500 text-xs mt-1">{formError.email}</p>
                                                        </div>
                                                        <div>
                                                            <label className="text-gray-700" htmlFor="phone">
                                                                Phone
                                                            </label>
                                                            <input
                                                                readOnly={!editMode}
                                                                onChange={handlePhoneChange}
                                                                title="Phone Number"
                                                                value={phone}
                                                                name="phone"
                                                                type="text"
                                                                id="phone"
                                                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                                placeholder="Enter contact number"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="text-gray-700" htmlFor="department">
                                                                Department
                                                            </label>
                                                            <input
                                                                readOnly={!editMode}
                                                                onChange={handleDepartmentChange}
                                                                value={department}
                                                                title="Department"
                                                                name="department"
                                                                type="text"
                                                                id="Department"
                                                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                                placeholder="Enter department"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="text-gray-700" htmlFor="location">
                                                                Location
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="location"
                                                                readOnly={!editMode}
                                                                onChange={handleLocationChange}
                                                                value={location}
                                                                id="Location"
                                                                title="Location"
                                                                className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                                                placeholder="Enter location"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='flex justify-between'>
                                                        <div className="mt-8 gap-4">
                                                            <Link to="/reset-password">
                                                                <button className={`inline-flex justify-center items-center text-center rounded bg-white my-1 leading-5 text-sm font-semibold text-${selectedColor}-500 hover:text-${selectedColor}-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}>
                                                                    Reset Password
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        {editMode ? (
                                                            <div className="flex justify-end mt-8 gap-4">
                                                                <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={() => setEditMode(false)}>
                                                                    Cancel
                                                                </button>
                                                                <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/* Assigned Dashboards and Groups Content */}
                                <div className="my-4 relative flex">
                                    {profileTab === 'assignedDashboardsAndGroups' && (
                                        <>
                                            <div className="w-1/2 pr-6">
                                                <h2 className="text-lg font-semibold border-b border-gray-300 pb-3 mb-4">Assigned Dashboards</h2>
                                                <ul className="divide-y divide-gray-200">
                                                    {dashboardList.map((dashboard, index) => (
                                                        <li
                                                            key={index}
                                                            className="py-3 flex items-center justify-between text-sm text-gray-700"
                                                        >
                                                            {dashboard.label}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className="border-r border-gray-300 mx-4"></div> {/* Vertical line */}

                                            <div className="w-1/2 pl-6">
                                                <h2 className="text-lg font-semibold border-b border-gray-300 pb-3 mb-4">Assigned Groups</h2>
                                                <ul className="divide-y divide-gray-200">
                                                    {groups.map((group, index) => (
                                                        <li
                                                            key={index}
                                                            className="py-3 flex items-center justify-between text-sm text-gray-700"
                                                        >
                                                            {group.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}


                </>

                {/* Tooltip */}
                <Tooltip id="sidebar-tooltip" />
            </div>
        </>


    );
};

export default Sidebar;
