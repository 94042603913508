import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../constants";
import { MdOutlineMail } from "react-icons/md";
import { ImInsertTemplate } from "react-icons/im";
import ConfigureWrench from '../../assets/Configure.png';
import { GrConfigure } from "react-icons/gr";
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { FiEdit } from "react-icons/fi";
import toast, { Toaster } from 'react-hot-toast';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme

const SmtpSetup = () => {
  const { selectedColor } = useTheme();
  const [editMode, setEditMode] = useState(false);
  const [emailVisibility, setEmailVisibility] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [subject, setSubject] = useState("");
  // const [emailText, setEmailText] = useState("");
  const [formError, setFormError] = useState({});
  const [activeTab, setActiveTab] = useState('emailService');
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [hasOrgId, setHasOrgId] = useState(false);
  const [emailPlatform, setEmailPlatform] = useState("");

  useEffect(() => {
    getSmtpInfoById();
  }, []);

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  // const handleSubjectChange = (event) => setSubject(event.target.value);
  // const handleEmailTextChange = (event) => setEmailText(event.target.value);
  const handleEmailPlatformChange = (event) => setEmailPlatform(event.target.value);
  

  const handleEdit = () => {
    setEditMode(true);
    setIsConfiguring(true);
  };

  const handleToggleVisibility = (event) => {
    const spanId = event.target.id;
    if (spanId === "emailVisibility") {
      setEmailVisibility(!emailVisibility);
    } else if (spanId === "passwordVisibility") {
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const getSmtpInfoById = () => {
    axios.get(`${baseURL}/getSmtpInfoByid`)
      .then((res) => {
        if (res.data.org_id) {
          // console.log('Response Data:', res.data);
          setEmail(res.data.email);
          setPassword(res.data.password);
          setEmailPlatform(res.data.email_platform);
          // setSubject(res.data.subject);
          // setEmailText(res.data.content);
          setIsUpdate(true);
          setHasOrgId(true);
        } else {
          setIsUpdate(false);
          setHasOrgId(false);
        }
        setEditMode(false);
        setIsConfiguring(false);
      })
      .catch(error => {
        // console.log(error);
        toast.error("Failed to fetch SMTP information");
      });
  };

  const saveData = () => {
    const endpoint = isUpdate ? `${baseURL}/editSmtpData` : `${baseURL}/saveSmtpData`;
    const method = isUpdate ? 'put' : 'post';
    // console.log("emailPlatform", emailPlatform);
    axios[method](endpoint, {
      email,
      password,
      emailPlatform
    })
      .then((res) => {
        // console.log(res);
        setEditMode(false);
        setIsConfiguring(false);
        toast.success(isUpdate ? "SMTP settings updated successfully" : "SMTP settings saved successfully");
        getSmtpInfoById();
      })
      .catch(error => {
        // console.log(error);
        toast.error("Failed to save SMTP settings");
      });
  };

  const handleTabClick = (tabName) => setActiveTab(tabName);

  const handleCancel = () => {
    setEditMode(false);
    setIsConfiguring(false);
    setFormError({});
    getSmtpInfoById();
  };

  const renderForm = () => (
    <form className="space-y-10">
      <div className="flex items-center space-x-10">
        <label htmlFor="email-platform" className="w-36 text-sm font-medium text-gray-700">
          Email Platform <span className='text-red-600'>*</span>
        </label>
        <div className="flex-grow relative">
          <select
            // type="text"  
            disabled={!editMode}
            onChange={handleEmailPlatformChange}
            value={emailPlatform}
            className={editMode
              ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40`
              : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
            // placeholder="example@example.com"
          >
           <option value="" disabled>Select role</option>
           <option value="Outlook">Outlook</option>
           <option value="Gmail">Gmail</option>
           </select>
        </div>
      </div>
      <div className="flex items-center space-x-10">
        <label htmlFor="email" className="w-36 text-sm font-medium text-gray-700">
          Email <span className='text-red-600'>*</span>
        </label>
        <div className="flex-grow relative">
          <input
            type="text"
            readOnly={!editMode}
            onChange={handleEmailChange}
            value={email}
            className={editMode
              ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40`
              : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
            placeholder="example@example.com"
          />
        </div>
      </div>

      <div className="flex items-center space-x-10">
        <label htmlFor="password" className="w-36 text-sm font-medium text-gray-700">
          Password <span className='text-red-600'>*</span>
        </label>
        <div className="flex-grow relative">
          <input
            type={editMode && passwordVisibility ? "text" : "password"}
            readOnly={!editMode}
            onChange={handlePasswordChange}
            value={password}
            className={`${editMode
              ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40`
              : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`} pr-10`} // Added pr-10 for consistent padding
            placeholder="Enter Password"
          />
          {editMode && (
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 flex items-center justify-center"
              onClick={handleToggleVisibility}
            >
              {passwordVisibility ? (
                <HiOutlineEyeOff id='passwordVisibility' className="h-5 w-5 text-gray-400" />
              ) : (
                <HiOutlineEye id='passwordVisibility' className="h-5 w-5 text-gray-400" />
              )}
            </button>
          )}
        </div>
      </div>

      <p className="text-sm text-red-600">{formError.duplicateClientData}</p>

      {editMode && (
        <div className="mt-4 flex justify-end space-x-3">
          <button
            type="button"
            className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
            onClick={saveData}
          >
            Save
          </button>
        </div>
      )}
    </form>
  );

  return (
    <>
      <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
        SMTP Setup
      </label>

      {/* Modal Tabs */}
      <div className="mt-8 flex justify-between items-center border-b border-gray-200">
        <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
          <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'emailService' ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500` : 'text-gray-700 border-b-2 border-transparent'} bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('emailService')}
          >
            <MdOutlineMail className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">E-mail Service</span>
          </button>

          {/* <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'emailTemplate' ? 'text-blue-600 border-b-2 border-blue-500' : 'text-gray-700 border-b-2 border-transparent'} bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('emailTemplate')}
          >
            <ImInsertTemplate className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">E-mail Template Design</span>
          </button> */}
        </div>

        <button
          type="button"
          className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
          onClick={handleEdit}
        >
          <FiEdit className="w-4 h-4 mr-2" />
          <span className="text-sm">Edit Credentials</span>
        </button>
      </div>

      {activeTab === 'emailService' && (
        hasOrgId ? (
          <div className="bg-white rounded-2xl shadow-lg p-6 mt-6">
            {renderForm()}
          </div>
        ) : (
          <div className='bg-white rounded-2xl shadow-lg py-6 px-8 mt-6'>
            <div>
              <img src={ConfigureWrench} alt='wrench' className='w-16 h-16' />
            </div>
            <h2 className="mt-5 text-lg text-gray-900 capitalize font-medium">
              Set Up SMTP
            </h2>
            <div className='flex flex-row justify-between mt-3'>
              <div className='flex flex-col text-gray-600 text-sm gap-2'>
                <p>To use email notifications and alerts, you need to configure your SMTP settings.</p>
                <p>Please proceed with the setup.</p>
              </div>
              <button
                type="button"
                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}

                onClick={() => {
                  setEditMode(true);
                  setIsConfiguring(true);
                }}
              >
                <GrConfigure className="w-4 h-4 mr-2" />
                <span className="text-sm">Set Up</span>
              </button>
            </div>
            {isConfiguring && renderForm()}
          </div>
        )
      )}
    </>
  );
};

export default SmtpSetup;