import { React, useState, useEffect } from 'react'
import { IoSearchOutline } from "react-icons/io5";
import { useDebounce } from 'use-debounce';
import { IoGridOutline } from "react-icons/io5";
import { IoTrashOutline } from 'react-icons/io5';
import { IoIosAdd } from "react-icons/io";
import { BsTable } from "react-icons/bs";
import { IoEyeOutline } from 'react-icons/io5';
import { FaUsers, FaRegCalendarAlt } from 'react-icons/fa';
import { IoShareSocialOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import axios from "axios";
import { baseURL } from "../../constants";
import tableauLogo from '../../assets/tableau.png'
import powerbiLogo from '../../assets/powerbi.png'
import domoLogo from '../../assets/domo.png'
import * as XLSX from "xlsx";
import { useNavigate, useOutletContext } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { CiBoxList } from "react-icons/ci";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme

import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: `#f1f5f9`
        , // Optional: Change column headers background color
        borderBottom: '1px solid #dbeafe', // Added border bottom
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: `#f1f5f9`
        , // Tailwind CSS bg-grey-50 equivalent
    },
    '& .user-column-bold': {
        fontWeight: 'bold',
    },
});


const UserDashboard = () => {
    const { selectedColor } = useTheme();

    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [userList, setUserList] = useState([]);

    const [dashboards, setDashboards] = useState([]);
    const [dashboardList, setDashboardList] = useState([]);
    const [filteredDashboards, setFilteredDashboards] = useState([...dashboards]);

    useState("");

    const [selectedPlatform, setSelectedPlatform] = useState('');

    const [importedData, setImportedData] = useState([]);
    const [excel, setExcel] = useState("");
    const decoded = jwt_decode(localStorage.getItem("auth"));
    
    const [hasDomo, setHasDomo] = useState(false);
    const [hasPowerBI, setHasPowerBI] = useState(false);
    const [hasTableau, setHasTableau] = useState(false);
    // const [flag, setFlag] = useState(null);
    const [selectedDashboard, setSelectedDashboard] = useState({
        label: "Select a Dashboard",
        value: "",
    });
    const rolesMapping = [
        {
            Roles: "",
            Email: "",
        },
    ];
    let flag = 0;

    let history = useNavigate();
    const colourStylesRow = {
        control: (styles) => ({
            ...styles,
            borderRadius: "6px",
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "black",
            fontSize: "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            width: "0px",
        }),
    };

    //updated - calling getAllUsers function
    useEffect(() => {
        const decodedToken = localStorage.getItem("auth")
            ? jwt_decode(localStorage.getItem("auth"))
            : null;
        flag = decodedToken.platformFlag;
        platformVisibility(flag);

        getDashboards();
        getAllUsers();
    }, []);

    //updated - added function to get list of users
    function getAllUsers() {
        axios
            .get(`${baseURL}/getAllUsersByOrgId`)
            .then(function (response) {
                // console.log("response.data.users", response.data.users);
                const usersData = response.data.users.filter((user) => {
                    return user.role === 2;
                });
                // setUsers(response.data.users);
                setUsers(usersData);
                // const userOptions = response.data?.users.map((user) => ({
                //   value: user.id,
                //   label: user.first_name + " " + user.last_name,
                // }));
                const userOptions = usersData.map((user) => ({
                    value: user.id,
                    label: user.first_name + " " + user.last_name,
                }));
                setUserList(userOptions);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    // ==================== Get all Dashboard =============

    function getDashboards() {
        axios
            .get(`${baseURL}/getDashboardsById`)
            .then(function (response) {
                const dashboards = response.data.dashboards;
                // console.log("dashboards", dashboards);
                setDashboards(dashboards);
                setFilteredDashboards(dashboards);
                const dashboardOptions = dashboards.map((dashboard) => ({
                    value: dashboard.id,
                    label: dashboard.dashboard_name,
                }));
                setDashboardList(dashboardOptions);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }


    const viewDashboard = (dashboardId, dashboardPlatform) => {
        // console.log("dashboardPlatform",dashboardPlatform)
        history(
            "/dashboard/view-dashboard/" + dashboardId + "/" + dashboardPlatform
        );
    };

    const handleShow = () => {
        // console.log("handleShow called");
        setShow(true);
    };




    const handleExport = (e) => {
        e.preventDefault();
        var wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(rolesMapping);

        XLSX.utils.book_append_sheet(wb, ws, "Bulk Import Template");

        XLSX.writeFile(wb, "Roles_Mapping.xlsx");
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wbSheetName = wb.SheetNames[0];
                const ws = wb.Sheets[wbSheetName];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((data) => {
            setImportedData(data);
            setExcel(file);
        });
    };

    function platformVisibility(platformFlag) {
        if (platformFlag === 1) {
            setHasDomo(true);
            setHasPowerBI(false);
            setHasTableau(false);
            setSelectedPlatform("DOMO");
            // setActiveTab("Domo");
            // getClientInfoById();
        } else if (platformFlag === 2) {
            setHasPowerBI(true);
            setHasDomo(false);
            setHasTableau(false);
            setSelectedPlatform("PowerBi");
            // setActiveTab("PowerBi");
            // getPowerBiClientInfoById();
        } else if (platformFlag === 3) {
            setHasPowerBI(false);
            setHasDomo(false);
            setHasTableau(true);
            setSelectedPlatform("Tableau");
            // setActiveTab("Tableau");
            // getTableauClientInfoById();
        } else if (platformFlag === 4) {
            setHasPowerBI(true);
            setHasDomo(true);
            setHasTableau(false);
            setSelectedPlatform("DOMO");
            // setActiveTab("Domo");
            // getClientInfoById();
            // getPowerBiClientInfoById();
        } else if (platformFlag === 5) {
            setHasPowerBI(false);
            setHasDomo(true);
            setHasTableau(true);
            setSelectedPlatform("DOMO");
            // setActiveTab("Domo");
            // getClientInfoById();
            // getTableauClientInfoById();
        } else if (platformFlag === 6) {
            setHasPowerBI(true);
            setHasDomo(false);
            setHasTableau(true);
            setSelectedPlatform("PowerBi");
            // setActiveTab("PowerBi");
            // getPowerBiClientInfoById();
            // getTableauClientInfoById();
        } else if (platformFlag === 7) {
            setHasPowerBI(true);
            setHasDomo(true);
            setHasTableau(true);
            setSelectedPlatform("DOMO");
            // setActiveTab("Domo");
            // getClientInfoById();
            // getPowerBiClientInfoById();
            // getTableauClientInfoById();
        }
    }

    function modalPlatformSet(platformFlag) {
        if (
            platformFlag === 1 ||
            platformFlag === 4 ||
            platformFlag === 5 ||
            platformFlag === 7
        ) {
            setSelectedPlatform("DOMO");
        } else if (platformFlag === 2 || platformFlag === 6) {
            setSelectedPlatform("PowerBi");
        } else if (platformFlag === 3) {
            setSelectedPlatform("Tableau");
        }
    }


    // ================= NEW STATES ==================================
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText] = useDebounce(searchText, 300); // Reduced debounce time for responsiveness

    const [activeTab, setActiveTab] = useState('dashboard');
    const [viewTab, setViewTab] = useState('grid');


    const handleViewClick = (tabName) => {
        setViewTab(tabName);
        // console.log(tabName);
    };



    const dashboardColumns = [
        { field: 'number', headerName: 'ID', width: 70, align: 'center', headerAlign: 'center', resizable: false },
        { field: 'dashboard_name', headerName: 'Dashboard Name', flex: 1, minWidth: 200, resizable: false },
        { field: 'dashboardOwner', headerName: 'Assined By', flex: 1, minWidth: 100, resizable: false },
        {
            field: 'date',
            headerName: 'Assigned On',
            width: 150,
            align: 'center',
            headerAlign: 'center'
            , resizable: false
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => (
                <>
                    <Button
                        onClick={() => viewDashboard(params.row.id, params.row.dashboard_platform_flag)}
                        size="small"
                        variant="outlined" startIcon={<VisibilityIcon />}
                        className='lowercase'
                    >
                        View
                    </Button>
                </>
            )
            , resizable: false
        },

    ];

    const dashboardRows = filteredDashboards && filteredDashboards
        .sort((a, b) => {
            if (a.dashboard_name < b.dashboard_name) {
                return -1;
            }
            if (a.dashboard_name > b.dashboard_name) {
                return 1;
            }
            return 0;
        })
        .map((dashboard, index) => ({
            id: dashboard.dashboard_id,
            number: index + 1,
            dashboard_name: dashboard.dashboard_name,
            dashboardOwner: `${dashboard.first_name} ${dashboard.last_name}`,
            dashboard_platform_flag: dashboard.dashboard_platform_flag,
            date: `${dashboard.created_on.split("T")[0] || 'N/A'}`,
            users: dashboard.count == null ? 0 : dashboard.count,
            dashboard_embed_id: dashboard.dashboard_embed_id,
            workspace_id: dashboard.workspace_id,
            single_role: dashboard.single_role,
            trusted_attribute: dashboard.trusted_attribute,
            admin_id: dashboard.admin_id,

            // Additional fields needed for actions
            // dashboardId: dashboard.id,
            // dashboardPlatformFlag: dashboard.dashboard_platform_flag
        }));

    return (
        <>
            <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
                Dashboards
            </label>

            <div className="mt-3 flex justify-between items-center border-b border-gray-200">
                <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
                    <div className="relative mt-3 mb-3 text-sm">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <IoSearchOutline className="w-5 h-5 text-gray-400" />
                        </span>
                        <input
                            type="text"
                            className="w-full py-1.5 pl-10 pr-4 text-gray-700 bg-gray-50 border rounded outline-none"
                            placeholder="Search Dashboard"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-4">

                    <div className="flex items-center p-1 space-x-1 text-sm text-gray-600 bg-gray-500/5 rounded-xl">
                        <button
                            role="tab"
                            type="button"
                            className={`flex items-center h-8 px-3 font-medium rounded-lg outline-none focus:ring-2 focus:ring-transparent focus:ring-inset ${viewTab === 'grid'
                                ? `text-${selectedColor}-500 shadow bg-white`
                                : 'hover:text-gray-800'
                                }`}
                            aria-selected={viewTab === 'grid'}
                            onClick={() => handleViewClick('grid')}
                        >
                            <IoGridOutline className='w-5 h-5' />
                        </button>

                        <button
                            role="tab"
                            type="button"
                            className={`flex items-center h-8 px-3 font-medium rounded-lg outline-none focus:ring-2 focus:ring-transparent focus:ring-inset ${viewTab === 'tabular'
                                ? `text-${selectedColor}-500 shadow bg-white`
                                : 'hover:text-gray-800'
                                }`}
                            aria-selected={viewTab === 'tabular'}
                            onClick={() => handleViewClick('tabular')}
                        >
                            <CiBoxList className='w-5 h-5' />
                        </button>
                    </div>
                </div>
            </div>

            {/* Grid View - only visible when activeTab === 'dashboard' and viewTab === 'grid' */}
            {activeTab === 'dashboard' && viewTab === 'grid' && (
                <div className="py-4 px-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                        {filteredDashboards &&
                            filteredDashboards
                                .sort((a, b) => {
                                    if (a.dashboard_name < b.dashboard_name) return -1;
                                    if (a.dashboard_name > b.dashboard_name) return 1;
                                    return 0;
                                })
                                .map((dashboard, index) => (
                                    <div
                                        key={index}
                                        className="bg-white shadow-md rounded-lg px-5 pt-5 pb-4 transition-transform hover:scale-105"
                                    >

                                        <h3 className="text-md font-semibold text-gray-600 truncate">{dashboard.dashboard_name}</h3>
                                        <p className="text-sm text-slate-400 mt-2">Assigned by {dashboard.first_name + " " + dashboard.last_name}</p>
                                        <div className="flex items-center text-slate-400 mt-2 text-sm gap-1">
                                            <FaRegCalendarAlt className="w-4 h-4 mr-1" />
                                            <span>{dashboard.created_on.split("T")[0] || 'N/A'}</span>
                                        </div>
                                        <div className="flex justify-end items-center mt-4 gap-4 border-t">
                                            <div className="flex items-center mt-3 gap-4">
                                                <div
                                                    className={`flex items-center text-${selectedColor}-500 hover:text-${selectedColor}-600 cursor-pointer`}
                                                    onClick={() => viewDashboard(dashboard.dashboard_id, dashboard.dashboard_platform_flag)}
                                                >
                                                    <IoEyeOutline className="w-4 h-4 mr-1" />
                                                    <span className="text-sm">View</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            )}

            {/* Tabular View - only visible when activeTab === 'dashboard' and viewTab === 'tabular' */}
            {activeTab === 'dashboard' && viewTab === 'tabular' && (
                <div className="mt-4" style={{ height: 'auto', width: '100%' }}>
                    <StyledDataGrid
                        rows={dashboardRows}
                        columns={dashboardColumns}
                        rowHeight={45}
                        columnHeaderHeight={50}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        autoHeight
                        // checkboxSelection
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                    // onRowSelectionModelChange={(newSelectionModel) => {
                    //   // Update the selectedDashboards state
                    //   setSelectedDashboards(newSelectionModel);
                    // }}
                    // selectionModel={selectedDashboards}
                    />
                </div>
            )}



        </>


    )
}

export default UserDashboard
