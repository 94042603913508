import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LuLayoutDashboard, LuUser2, LuTable2 } from 'react-icons/lu';
import { MdOutlineEdit } from 'react-icons/md';
import { PiWrench } from "react-icons/pi";
import axios from "axios";
import { baseURL } from "../../constants.js";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: `#f1f5f9`, // Optional: Change column headers background color
    borderBottom: '1px solid #dbeafe', // Added border bottom
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: `#f1f5f9`, // Tailwind CSS bg-grey-50 equivalent
  },
  '& .user-column-bold': {
    fontWeight: 'bold',
  },
});

const EditUsers = () => {
  const { selectedColor } = useTheme();
  // console.log('rendering')
  const [error, setError] = useState('');
  const { userId } = useParams();
  const [activeTab, setActiveTab] = useState('userInformation');
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [formError, setFormError] = useState({});
  const [showEditUser, setShowEditUser] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // console.log(tabName);
  };

  const getUserInfo = () => {
    axios
      .get(`${baseURL}/getUserInfoById/${userId}`)
      .then((response) => {
        const user = response.data.user[0];
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setJobTitle(user.job_title);
        setRole(user.role === 2 ? "User" : "Admin");
        setEmail(user.work_email);
        setPhone(user.phone);
        setDepartment(user.department);
        setLocation(user.location);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, [userId]);

  const handleChangeFirstName = (event) => setFirstName(event.target.value);
  const handleChangeLastName = (event) => setLastName(event.target.value);
  const handleChangeRole = (event) => setRole(event.target.value);
  const handleChangeJobTitle = (event) => setJobTitle(event.target.value);
  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangePhone = (event) => setPhone(event.target.value);
  const handleChangeDepartment = (event) => setDepartment(event.target.value);
  const handleChangeLocation = (event) => setLocation(event.target.value);

  const handleShowEditUser = () => setShowEditUser(true);

  const handleCloseEditUser = () => {
    setShowEditUser(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
    setJobTitle("");
    setPhone("");
    setDepartment("");
    setLocation("");
    setFormError({});
    getUserInfo()
  };

  const updateUserInfo = (e) => {
    e.preventDefault();
    setLoading(true);
    setFormError({});

    if (firstName && lastName && role && email && jobTitle) {
      axios
        .put(`${baseURL}/updateUsers/${userId}`, {
          firstName,
          lastName,
          workEmail: email,
          jobTitle,
          phone: phone || null,
          department: department || null,
          location: location || null,
        })
        .then((response) => {
          axios.post(`${baseURL}/logAdminEvent`, {
            eventType: "user updated",
            info: `userId = ${userId}`,
          });
          toast.success("User updated successfully!");
          setLoading(false);
          handleCloseEditUser();
          getUserInfo()
        })
        .catch((error) => {
          toast.error(error.response.status === 409 ? "User with same email id already exists" : "Failed to update user information.");
          console.error(error);
          setLoading(false);
        });
    } else {
      const err = {};
      if (!firstName) err.firstName = "First Name is mandatory";
      if (!lastName) err.lastName = "Last Name is mandatory";
      if (!email) err.email = "Email is mandatory";
      if (!role) err.role = "Role is mandatory";
      if (!jobTitle) err.jobTitle = "Job Title is mandatory";
      setFormError(err);
      setLoading(false);
    }
  };


  // -----------------Assigned Dashbaord States and Functions

  const filterObject = {
    column: "",
    operator: "",
    values: [],
  };
  const [show, setShow] = useState(false);
  const [dashboardId, setDashboardId] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [assignedDashboards, setAssignedDashboards] = useState([]);
  const [groupsAssignedDashboards, setGroupsAssignedDashboards] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [filterRowId, setFilterRowId] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteDashboardObj, setDeleteDashboardObj] = useState([]);
  const [dashboardPlatform, setDashboardPlatform] = useState("");
  const [filterArray, setFilterArray] = useState([filterObject]);

  const selectRef = useRef(null);
  const ref = useRef(null);

  const handleClose = () => {
    setShow(false);
    clearModalFields();
    setEditMode(false);
  };

  const [handleInitialPlatform, setHandleInitialPlatform] = useState(false);

  // useEffect(() => {
  //   if (selectRef.current) {
  //     const firstOption = selectRef.current.options[0];
  //     const firstDashboardId = firstOption ? firstOption.value : "";
  //     // console.log("firstDashboardId", firstDashboardId)
  //     const platform_flag = dashboards.find(
  //       (e) => {
  //         // console.log('e', e)
  //         return e.id == firstDashboardId
  //       }
  //     ).dashboard_platform_flag;
  //     if (platform_flag === 0) {
  //       setDashboardPlatform("DOMO");
  //     } else if (platform_flag === 1) {
  //       setDashboardPlatform("Powerbi");
  //     } else if (platform_flag === 2) {
  //       setDashboardPlatform("Tableau");
  //     }
  //   }
  // }, [handleInitialPlatform]);


  useEffect(() => {
    if (selectRef.current && dashboards.length > 0) {
      const firstOption = selectRef.current.options[0];
      const firstDashboardId = firstOption ? firstOption.value : "";
      console.log("firstDashboardId", firstDashboardId);

      const selectedDashboard = dashboards.find(e => e.id == firstDashboardId);

      if (selectedDashboard) {
        const platform_flag = selectedDashboard.dashboard_platform_flag;

        if (platform_flag === 0) {
          setDashboardPlatform("DOMO");
        } else if (platform_flag === 1) {
          setDashboardPlatform("Powerbi");
        } else if (platform_flag === 2) {
          setDashboardPlatform("Tableau");
        }
        else {
          console.warn(`Unknown platform flag: ${platform_flag}`);
          setDashboardPlatform("Unknown");
        }
      } else {
        console.warn(`No dashboard found with id: ${firstDashboardId}`);
        setDashboardPlatform("Unknown");
      }
    } else {
      console.warn("No dashboards available or select element not rendered");
      setDashboardPlatform("Unknown");
    }
  }, [handleInitialPlatform, dashboards]);




  const handleShow = () => setShow(true);



  function clearModalFields() {
    setDashboardId(assignedDashboards[0]?.dashboard_id);
    setFilterArray([filterObject]);
  }

  function deleteUserDashboard(id) {
    axios
      .delete(`${baseURL}/deleteUserDashboards/${id}`)
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "dashboard unshared",
            info: `dashboardId = ${id}, user = ${userId}`,
          })
          .then((res) => {
            // console.log(res);
          });
        getDashboardsByUserId();
        setDeleteModalShow(false);
        setDeleteDashboardObj({}); // Reset deleteDashboardObj
        toast.success('Dashboard deleted successfully')
      })
      .catch(function (error) {
        // console.log(error);
        toast.error("Failed to delete dashboard")
      });
  }

  function getDashboards() {
    axios
      .get(`${baseURL}/getDashboardsById`)
      .then(function (response) {
        setDashboards(response.data.dashboards);
        setDashboardId(response.data.dashboards[0].id);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function handleChangeDashboard(event) {
    setDashboardId(event.target.value);
    const platform_flag = dashboards.find(
      (e) => e.id == event.target.value
    ).dashboard_platform_flag;
    if (platform_flag === 0) {
      setDashboardPlatform("DOMO");
    } else if (platform_flag === 1) {
      setDashboardPlatform("Powerbi");
    } else if (platform_flag === 2) {
      setDashboardPlatform("Tableau");
    }
  }

  function handleChangeColumn(event, i) {
    const some_array = [...filterArray];
    some_array[i].column = event.target.value;
    setFilterArray([...some_array]);
  }


  function handleChangeOperator(event, i) {
    const some_array = [...filterArray];
    some_array[i].operator = event.target.value;
    setFilterArray([...some_array]);
  }

  // function handleChangeValue(event, i) {
  //   const some_array = [...filterArray];
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     some_array[i].values.push(event.target.value);
  //     setFilterArray([...some_array]);
  //     ref.current.value = "";
  //   }
  // }

  function handleChangeValue(event, i) {
    const some_array = [...filterArray];
    if (event.key === "Enter") {
      event.preventDefault();
      some_array[i].values.push(event.target.value);
      setFilterArray([...some_array]);

      // Safely clear the input value
      if (ref.current) {
        ref.current.value = "";
      } else {
        // If ref.current is null, we can clear the input value directly from the event target
        event.target.value = "";
      }
    }
  }

  function removeValues(value, i) {
    const some_array = [...filterArray];
    const indexToSplice = some_array[i].values.findIndex((a) => a == value);
    some_array[i].values.splice(indexToSplice, 1);
    setFilterArray([...some_array]);
  }

  function removeFilterObject(index) {
    const some_array = [...filterArray];
    some_array.splice(index, 1);
    setFilterArray([...some_array]);
  }

  function addFilter(event) {
    event.preventDefault(); // Prevent the default form submission
    setFilterArray([...filterArray, filterObject]);
  }

  const handleDashboardDefault = (event) => {
    setDashboardId(document.getElementById("dashboardSelect").value);
  };

  const getDashboardsAssignedByGroup = () => {
    axios
      .get(`${baseURL}/getGroupsAssignedDashboardsByUserId/${userId}`)
      .then(function (response) {
        // console.log("RESP", response);
        setGroupsAssignedDashboards(response.data.dashboards);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    getUserInfo();
    getDashboards();
    getDashboardsByUserId();
    getDashboardsAssignedByGroup();
  }, []);


  const getDashboardsByUserId = () => {
    axios
      .get(`${baseURL}/getDashboardsByUserId/${userId}`)
      .then((response) => {
        // console.log("response", response);
        setAssignedDashboards(response.data.dashboards);
        // console.log("DATA", response.data.dashboards);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getDashboardsByUserId();
  }, []);

  function editFilters(index) {
    setEditMode(true);
    const objectToEdit = assignedDashboards[index];
    // console.log("objectToEdit", objectToEdit);
    setFilterRowId(objectToEdit.id);
    setDashboardId(objectToEdit.dashboard_id);
    setFilterArray(JSON.parse(objectToEdit.filter));
    if (objectToEdit.dashboard_platform_flag === 1) {
      setDashboardPlatform("Powerbi");
    } else if (objectToEdit.dashboard_platform_flag === 0) {
      setDashboardPlatform("DOMO");
    } else if (objectToEdit.dashboard_platform_flag === 2) {
      setDashboardPlatform("Tableau");
    }
    handleShow();
  }

  function updateFilter() {
    let isUnfiltered = false;
    if (
      filterArray.length >= 1 &&
      (((dashboardPlatform === 'DOMO' || dashboardPlatform === 'Tableau') && (filterArray[0].column == "" || filterArray[0].values.length === 0)) || (filterArray[0].values.length === 0))
    ) {
      isUnfiltered = true;
    }
    axios
      .put(`${baseURL}/updateFilters/${filterRowId}`, {
        filter: isUnfiltered ? [] : filterArray,
      })
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "filter updated",
            info: `filter rowId = ${filterRowId}`,
          })
          .then((res) => {
            // console.log(res);
          });
        getDashboardsByUserId();
        handleClose();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handleDeleteUserDashboardClick = (index) => {
    const foundDashboard = assignedDashboards.find(
      (assignedDashboard, i) => i === index
    );
    setDeleteDashboardObj(foundDashboard);
    setDeleteModalShow(true);
  };

  function checkDashboard(dashboardId, index) {
    const res = assignedDashboards.find((e) => e.dashboard_id == dashboardId);
    if (res) {
      return false;
    } else {
      return true;
    }
  }

  function saveInfo() {
    let isUnfiltered = false;
    if (
      filterArray.length >= 1 &&
      (((dashboardPlatform === 'DOMO' || dashboardPlatform === 'Tableau') && (filterArray[0].column == "" || filterArray[0].values.length === 0)) || (filterArray[0].values.length === 0))
    ) {
      isUnfiltered = true;
    }
    axios
      .post(`${baseURL}/saveFilterDetails`, {
        filter: isUnfiltered ? [] : filterArray,
        dashboardId: dashboardId,
        userId: userId,
      })
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "dashboard & filter assigned",
            info: `dashboardId = ${dashboardId}, user = ${userId}`,
          })
          .then((res) => {
            // console.log(res);
          });
        getDashboardsByUserId();
        handleClose();
        toast.success('Dashboard added successfully')
      })
      .catch(function (error) {
        // console.log(error);
        toast.error('Failed to add dashboard')
      });
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);


  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const handleEdit = (row) => {
    // Log the objectToEdit
    // console.log("objectToEdit", row);

    // Set the state for edit mode
    setEditMode(true);
    setFilterRowId(row.id);
    setDashboardId(row.dashboard_id);

    // Handle the case where filter might be undefined or not a valid JSON string
    try {
      const parsedFilter = row.filter ? JSON.parse(row.filter) : [];
      setFilterArray(parsedFilter);
    } catch (error) {
      console.error("Error parsing filter:", error);
      setFilterArray([]);
    }

    // Set the dashboard platform based on the flag
    // switch (row.dashboard_platform_flag) {
    //   case 1:
    //     setDashboardPlatform("Powerbi");
    //     break;
    //   case 0:
    //     setDashboardPlatform("DOMO");
    //     break;
    //   case 2:
    //     setDashboardPlatform("Tableau");
    //     break;
    //   default:
    //     setDashboardPlatform("");
    // }

    // setFilterArray(JSON.parse(row.filter));

    if (row.dashboard_platform_flag === 1) {
      setDashboardPlatform("Powerbi");
    } else if (row.dashboard_platform_flag === 0) {
      setDashboardPlatform("DOMO");
    } else if (row.dashboard_platform_flag === 2) {
      setDashboardPlatform("Tableau");
    }

    // Show the modal
    handleShow();

    // Close any open menus if necessary
    handleCloseMenu();
  };

  const handleDelete = (id) => {
    setDeleteDashboardObj({ id }); // Set the id in the deleteDashboardObj
    setDeleteModalShow(true);      // Show the delete modal
  };

  const columns = [
    { field: 'number', headerName: 'Id', width: 70, align: 'center', headerAlign: 'center' },
    { field: 'dashboardName', headerName: 'Dashboard Name', flex: 1 },
    { field: 'createdOn', headerName: 'Created On', flex: 1 },
    ...(role !== 'Admin' ? [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <>
            <GridActionsCellItem
              icon={<MoreVertIcon />}
              label="Options"
              onClick={(event) => handleOpenMenu(event, params.row)}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && currentRow?.id === params.row.id}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => editFilters(params.row.index)}>Edit</MenuItem>
              <MenuItem onClick={() => handleDelete(params.row.id)}>Delete</MenuItem>
            </Menu>
          </>
        )
      }
    ] : []),
  ];


  const rows = assignedDashboards.map((dashboard, index) => ({
    number: index + 1,
    id: dashboard.id || `row-${index}`,
    dashboardName: dashboard.dashboard_name,
    createdOn: dashboard.created_on.split("T")[0],
    role: role !== "Admin" ? 'User' : 'Admin',
    index: index
  }));

  const columnsTwo = [
    { field: 'number', headerName: 'Id', width: 70, align: 'left', headerAlign: 'left' },
    {
      field: 'dashboardName',
      headerName: 'Dashboard Name',
      flex: 1,
      renderHeader: (params) => (
        <strong className="font-semibold text-gray-900">{params.colDef.headerName}</strong>
      ),
    },
    {
      field: 'assignedByGroups',
      headerName: 'Assigned By Groups',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderHeader: (params) => (
        <strong className="font-semibold text-gray-900">{params.colDef.headerName}</strong>
      ),
    },
  ];

  const rowsTwo = groupsAssignedDashboards.map((dashboard, index) => ({
    number: index + 1,
    id: `${dashboard.id}-${index}`,
    dashboardName: dashboard.dashboard_name,
    assignedByGroups: dashboard.name,
  }));


  return (
    <>
      <label className="px-3 text-sm text-gray-900 capitalize py-1 flex items-center overflow-x-auto whitespace-nowrap">
        <Link to={'/dashboard/settings/manage-users'} className="text-gray-600 hover:underline">
          User Details
        </Link>

        <span className="mx-3 text-gray-500 rtl:-scale-x-100">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </span>

        <p className={`text-${selectedColor}-600 hover:underline`}>
          User Info
        </p>
      </label>

      <div className="mt-8 flex justify-between items-center border-b border-gray-200">
        <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
          <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'userInformation'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('userInformation')}
          >
            <LuUser2 className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              User Information
            </span>
          </button>

          <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'assignedDashboards'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('assignedDashboards')}
          >
            <LuLayoutDashboard className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              Assigned Dashboards
            </span>
          </button>
          {/* <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'assignedTables'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('assignedTables')}
          >
            <LuTable2 className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              Assigned Tables
            </span>
          </button> */}
        </div>
      </div>

      <div className="my-4 relative">
        {activeTab === 'userInformation' && (
          <>
            <div className='flex justify-between mb-2'>
              <p className='text-sm text-gray-900 my-auto font-medium ml-4'>User Info</p>
              <button
                type="button"
                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`} onClick={handleShowEditUser}
              >
                <MdOutlineEdit className="w-5 h-5 mr-2" />
                <span className="text-sm">Edit Info</span>
              </button>
            </div>
            <div className="p-8 bg-white shadow rounded-lg">

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">First Name</h3>
                  <p className="mt-2 text-base text-gray-900">{firstName}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Last Name</h3>
                  <p className="mt-2 text-base text-gray-900">{lastName}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Job Title</h3>
                  <p className="mt-2 text-base text-gray-900">{jobTitle}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Role</h3>
                  <p className="mt-2 text-base text-gray-900">{role}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">E-mail</h3>
                  <p className="mt-2 text-base text-gray-900">{email}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Phone Number</h3>
                  <p className="mt-2 text-base text-gray-900">{phone}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Department</h3>
                  <p className="mt-2 text-base text-gray-900">{department}</p>
                </div>
                <div className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Location (Optional)</h3>
                  <p className="mt-2 text-base text-gray-900">{location}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showEditUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-1/3 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Edit User</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={handleCloseEditUser}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              <form className="add-user-modal w-75 text-sm" onSubmit={updateUserInfo}>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <div>
                    <label className="text-gray-700" htmlFor="firstName">
                      First Name <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      onChange={handleChangeFirstName}
                      value={firstName}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter first name"
                      id="firstName"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.firstName}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="lastName">
                      Last Name <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChangeLastName}
                      value={lastName}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter last name"
                      id="lastName"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.lastName}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="jobTitle">
                      Job Title <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="jobTitle"
                      onChange={handleChangeJobTitle}
                      value={jobTitle}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter job title"
                      id="jobTitle"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.jobTitle}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="role">
                      Role <span className="text-red-500 font-bold">*</span>
                    </label>
                    <select
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      aria-label="Default select example"
                      onChange={handleChangeRole}
                      value={role}
                      disabled
                    >
                      <option value="" disabled>Select role</option>
                      <option value="User">User</option>
                      <option value="Admin">Admin</option>
                    </select>
                    <p className="text-red-500 text-xs mt-1">{formError.role}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="email">
                      Email <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChangeEmail}
                      value={email}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter email"
                      id="email"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.email}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="phone">
                      Phone
                    </label>
                    <input
                      type="text"
                      name="phone"
                      onChange={handleChangePhone}
                      value={phone}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter contact number"
                      id="phone"
                    />
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="department">
                      Department
                    </label>
                    <input
                      type="text"
                      name="department"
                      onChange={handleChangeDepartment}
                      value={department}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter department"
                      id="department"
                    />
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="location">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      onChange={handleChangeLocation}
                      value={location}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter location"
                      id="location"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-8 gap-4">
                  <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={handleCloseEditUser}>
                    Cancel
                  </button>
                  <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`} onClick={updateUserInfo}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}


      <div className="my-4 relative">
        {activeTab === 'assignedDashboards' && (
          <>
            <div className='flex justify-between mb-2'>
              <p className='text-sm text-gray-900 my-auto font-medium ml-4'>Dashboard Details</p>
              {role !== "Admin" && (
                <button
                  type="button"
                  className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                  onClick={() => {
                    handleShow();
                    setHandleInitialPlatform(!handleInitialPlatform);
                  }}
                >
                  <PiWrench className="w-5 h-5 mr-2" />
                  <span className="text-sm">Configure</span>
                </button>
              )}
            </div>
            <div className="mt-4" style={{ height: 'auto', width: '100%' }}>
              <StyledDataGrid
                rows={rows}
                columns={columns}
                rowHeight={45}
                columnHeaderHeight={50}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                autoHeight
                disableSelectionOnClick
                disableRowSelectionOnClick
              />
            </div>
            {groupsAssignedDashboards && groupsAssignedDashboards.length > 0 && role !== "Admin" && (
              <div className="mt-8">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-sm text-gray-900 my-auto font-medium ml-4">Groups Assigned Dashboards</p>
                </div>
                <div className="mt-4" style={{ height: 'auto', width: '100%' }}>
                  <StyledDataGrid
                    rows={rowsTwo}
                    columns={columnsTwo}
                    rowHeight={45}
                    columnHeaderHeight={50}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    autoHeight
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                  />
                </div>
              </div>
            )}
          </>
        )}


        <>
          {show && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
              <div className="bg-white rounded shadow-xl w-4/5 2xl:w-3/6 transition-transform transform duration-300">
                <div className="px-6 py-4 flex justify-between items-center border-b">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {editMode ? "Edit Dashboard Filters" : "Add Dashboard Filters"}
                  </h3>
                  <button
                    className="text-gray-400 hover:text-gray-500"
                    onClick={handleClose}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="px-6 py-4">
                  <form className="w-75 text-sm">
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                      <div className="col mb-2 w-full">
                        <label htmlFor="InputRole" className="text-gray-700">
                          Select Dashboard
                        </label>
                        <select
                          ref={selectRef}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          id="dashboardSelect"
                          aria-label="Default select example"
                          onChange={handleChangeDashboard}
                          value={dashboardId}
                          disabled={editMode ? true : false}
                        >
                          {dashboards.map((dashboard, index) => {
                            if (editMode) {
                              return (
                                <option value={dashboard.id} key={dashboard.id}>
                                  {dashboard.dashboard_name +
                                    " [ " +
                                    dashboard.dashboard_embed_id +
                                    " ]"}{" "}
                                </option>
                              );
                            } else if (checkDashboard(dashboard.id, index)) {
                              return (
                                <option value={dashboard.id} key={dashboard.id}>
                                  {dashboard.dashboard_name +
                                    " [ " +
                                    dashboard.dashboard_embed_id +
                                    " ]"}{" "}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </select>
                      </div>
                    </div>

                    {dashboardPlatform === 'DOMO' &&
                      filterArray.map((e, i) => (
                        <div className="mt-4" key={i}>
                          <p className="text-sm text-gray-600 mt-4 italic">
                            Enter column, select operator, and press Enter to add values.
                          </p>
                          <div className="flex items-center gap-4 mt-4" key={i}>
                            <div className="flex-1">
                              <input
                                type="text"
                                name="column"
                                onChange={(event) => handleChangeColumn(event, i)}
                                value={
                                  filterArray && filterArray.length
                                    ? filterArray[i]?.column
                                    : ""
                                }
                                placeholder="Column"
                                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              />
                            </div>
                            <div className="flex-1">
                              <select
                                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                onChange={(event) => handleChangeOperator(event, i)}
                                value={
                                  filterArray && filterArray.length
                                    ? filterArray[i]?.operator
                                    : ""
                                }
                              >
                                <option value="" disabled>Select Operator</option>
                                <option value="IN">IN</option>
                                <option value="NOT_IN">NOT_IN</option>
                                <option value="EQUALS">EQUALS</option>
                                <option value="NOT_EQUALS">NOT_EQUALS</option>
                                <option value="GREATER_THAN">GREATER_THAN</option>
                                <option value="GREAT_THAN_EQUALS_TO">GREATER_THAN_EQUALS_TO</option>
                                <option value="LESS_THAN">LESS_THAN</option>
                                <option value="LESS_THAN_EQUALS_TO">LESS_THAN_EQUALS_TO</option>
                              </select>
                            </div>
                            <div className="flex-1">
                              <input
                                type="text"
                                name="values"
                                ref={ref}
                                onKeyDown={(event) => handleChangeValue(event, i)}
                                placeholder="Press enter to save values"
                                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              />
                            </div>


                          </div>
                          {error && (
                            <p className="text-red-500 text-sm mb-2">{error}</p>
                          )}
                          {/* <div className="flex flex-wrap gap-2 mt-2">
                            {filterArray && filterArray.length && filterArray[i]?.values?.map((v, valueIndex) => (
                              <span
                                key={`${i}-${valueIndex}`}
                                className="flex items-center bg-gray-50 rounded-lg px-3 py-1 text-sm"
                              >
                                <span className="text-gray-700 mx-2">{v}</span>
                                <button
                                  onClick={() => removeValues(v, i)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  <CiCircleRemove className="w-5 h-5" />
                                </button>
                              </span>
                            ))}
                          </div>
                          <button
                            className="mt-2 text-red-500 hover:text-red-700"
                            onClick={() => removeFilterObject(i)}
                          >
                            Remove Filter
                          </button> */}

                          <div className="flex flex-wrap gap-2 mt-2">
                            {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => {
                              return (
                                <div className="flex items-center bg-gray-50 rounded px-3 py-1 text-sm">
                                  <span className="text-black mr-1">{v}</span>
                                  <CiCircleRemove
                                    className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={() => removeValues(v, i)}
                                  />
                                </div>
                              );
                            })}
                          </div>

                          <div className="mt-2 text-red-500 hover:text-red-700 cursor-pointer">
                            <span
                              className="pb-1"
                              onClick={() => removeFilterObject(i)}
                            >
                              Remove Filter
                            </span>
                          </div>
                        </div>
                      ))
                    }

                    {dashboardPlatform === "Tableau" &&
                      filterArray.map((e, i) => (
                        <div className="mt-4" key={i}>
                          <p className="text-sm text-gray-600 mt-4 italic">
                            Enter column name and press Enter to add values.
                          </p>
                          <div className="flex items-center gap-4 mt-4" key={i}>
                            <div className="flex-1">
                              <input
                                type="text"
                                name="column"
                                onChange={(event) => handleChangeColumn(event, i)}
                                value={
                                  filterArray && filterArray.length
                                    ? filterArray[i]?.column
                                    : ""
                                }
                                placeholder="Column"
                                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                id="column"
                              />
                            </div>
                            <div className="flex-1">
                              <input
                                type="text"
                                name="values"
                                ref={ref}
                                onKeyDown={(event) => handleChangeValue(event, i)}
                                placeholder="Press enter to save values"
                                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              />
                            </div>

                          </div>
                          {error && (
                            <p className="text-red-500 text-sm mb-2">{error}</p>
                          )}
                          {/* <div className="flex flex-wrap gap-2 mt-2">
                            {filterArray && filterArray.length && filterArray[i]?.values?.map((v, valueIndex) => (
                              <span
                                key={`${i}-${valueIndex}`}
                                className="flex items-center bg-gray-50 rounded-lg px-3 py-1 text-sm"
                              >
                                <span className="text-gray-700 mx-2">{v}</span>
                                <button
                                  onClick={() => removeValues(v, i)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  <CiCircleRemove className="w-5 h-5" />
                                </button>
                              </span>
                            ))}
                          </div>
                          <button
                            className="mt-2 text-red-500 hover:text-red-700"
                            onClick={() => removeFilterObject(i)}
                          >
                            Remove Filter
                          </button> */}

                          <div className="flex flex-wrap gap-2 mt-2">
                            {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => {
                              return (
                                <div className="flex items-center bg-gray-50 rounded px-3 py-1 text-sm">
                                  <span className="text-black mr-1">{v}</span>
                                  <CiCircleRemove
                                    className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={() => removeValues(v, i)}
                                  />
                                </div>
                              );
                            })}
                          </div>

                          <div className="mt-2 text-red-500 hover:text-red-700 cursor-pointer">
                            <span
                              className="pb-1"
                              onClick={() => removeFilterObject(i)}
                            >
                              Remove Filter
                            </span>
                          </div>
                        </div>
                      ))
                    }

                    {dashboardPlatform === "Powerbi" &&
                      filterArray.map((e, i) => (
                        <div className="mt-4" key={i}>
                          <p className="text-sm text-gray-600 mt-4 italic">
                            Press Enter to add a new role or value.
                          </p>
                          <div className="flex items-center gap-4 mt-4" key={i}>
                            <div>
                              <input
                                type="text"
                                name="values"
                                ref={ref}
                                onKeyDown={(event) => handleChangeValue(event, i)}
                                placeholder="Press enter to save values"
                                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              />

                            </div>
                            {/* <div className="flex-shrink-0">
                              {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => (
                                <span
                                  className="blue-pills"
                                  onClick={() => removeValues(v, i)}
                                  key={v}
                                >
                                  {v} <i className="bi bi-x-circle"></i>
                                </span>
                              ))}
                              <span
                                className="cursor-pointer text-red-500"
                                onClick={() => removeFilterObject(i)}
                              >
                                <CiCircleRemove className="w-6 h-6" />
                              </span>
                            </div> */}
                          </div>
                          {error && (
                            <p className="text-red-500 text-sm mb-2">{error}</p>
                          )}
                          {/* <div className="flex flex-wrap gap-2 mt-2">
                            {filterArray && filterArray.length && filterArray[i]?.values?.map((v, valueIndex) => (
                              <span
                                key={`${i}-${valueIndex}`}
                                className="flex items-center bg-gray-50 rounded-lg px-3 py-1 text-sm"
                              >
                                <span className="text-gray-700 mx-2">{v}</span>
                                <button
                                  onClick={() => removeValues(v, i)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  <CiCircleRemove className="w-5 h-5" />
                                </button>
                              </span>
                            ))}
                          </div>
                          <button
                            className="mt-2 text-red-500 hover:text-red-700"
                            onClick={() => removeFilterObject(i)}
                          >
                            Remove Filter
                          </button> */}

                          <div className="flex flex-wrap gap-2 mt-2">
                            {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => {
                              return (
                                <div className="flex items-center bg-gray-50 rounded px-3 py-1 text-sm">
                                  <span className="text-black mr-1">{v}</span>
                                  <CiCircleRemove
                                    className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={() => removeValues(v, i)}
                                  />
                                </div>
                              );
                            })}
                          </div>

                          <div className="mt-2 text-red-500 hover:text-red-700 cursor-pointer">
                            <span
                              className="pb-1"
                              onClick={() => removeFilterObject(i)}
                            >
                              Remove Filter
                            </span>
                          </div>
                        </div>
                      ))
                    }


                    {/* <button
                      type="button"
                      className={`mt-4 inline-flex justify-center items-center rounded text-xs font-light bg-transparent text-${selectedColor}-500 transition duration-300 ease-in-out hover:text-${selectedColor}-700`}
                      onClick={addFilter}// ---------- Add User Event Binding
                    >
                      <IoIosAdd className="w-5 h-5" />
                      <span className="text-sm">  {dashboardPlatform === "Powerbi" ? "New Role" : dashboardPlatform === "DOMO" || dashboardPlatform === "Tableau" ? "New Filter" : "New Filter"}</span>
                    </button> */}

                    {dashboardPlatform !== "Unknown" && (
                      <button
                        type="button"
                        className={`mt-4 inline-flex justify-center items-center rounded text-xs font-light bg-transparent text-${selectedColor}-500 transition duration-300 ease-in-out hover:text-${selectedColor}-700`}
                        onClick={addFilter} // ---------- Add User Event Binding
                      >
                        <IoIosAdd className="w-5 h-5" />
                        <span className="text-sm">
                          {dashboardPlatform === "Powerbi"
                            ? "New Role"
                            : dashboardPlatform === "DOMO" || dashboardPlatform === "Tableau"
                              ? "New Filter"
                              : "New Filter"}
                        </span>
                      </button>
                    )}

                  </form>
                </div>

                <div className="px-6 py-4 border-t flex justify-end space-x-5">
                  <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={handleClose}>
                    Cancel
                  </button>
                  <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`} onMouseOver={handleDashboardDefault}
                    onClick={editMode ? updateFilter : saveInfo}>
                    {editMode ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>

        {deleteModalShow && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
            <div className="bg-white rounded shadow-xl w-2/3 2xl:w-1/3 transition-transform transform duration-300">
              <div className="px-6 py-4 flex justify-between items-center border-b">
                <h3 className="text-lg font-semibold text-gray-800">Delete Dashboard</h3>
                <button
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => setDeleteModalShow(false)}
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-6 py-4">
                Are you sure you want to delete this dashboard?
              </div>
              <div className="px-6 py-4 border-t flex justify-end space-x-5">
                <button
                  className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"

                  onClick={() => {
                    setDeleteModalShow(false);
                    setDeleteDashboardObj({}); // Reset deleteDashboardObj
                  }}
                >
                  Cancel
                </button>

                <button
                  className="inline-flex justify-center items-center rounded bg-red-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"
                  onClick={() => deleteUserDashboard(deleteDashboardObj.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}


      </div>
    </>
  );
};

export default EditUsers;

