import axios from "axios";
import { baseURL } from "../../constants";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuLayoutDashboard } from "react-icons/lu";
import { LuTable2 } from "react-icons/lu";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineEdit } from "react-icons/md";
import { PiWrench } from "react-icons/pi";
import toast from "react-hot-toast";
import { CiCircleRemove } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
// import toast from "react-hot-toast";

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme
import { FiEdit } from "react-icons/fi";

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: `#f1f5f9`, // Optional: Change column headers background color
    borderBottom: '1px solid #dbeafe', // Added border bottom
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: `#f1f5f9`, // Tailwind CSS bg-grey-50 equivalent
  },
  '& .user-column-bold': {
    fontWeight: 'bold',
  },
});


const EditGroups = () => {
  const { selectedColor } = useTheme();

  const [editMode, setEditMode] = useState(false);
  // const [userEditMode, setUserEditMode] = useState(false);
  const ref = useRef();
  let navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  let { groupId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [dashboardId, setDashboardId] = useState("");
  const [filter, setFilter] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [assignedDashboards, setAssignedDashboards] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [cookies, setCookie] = useCookies(["auth"]);
  // const decoded = jwt_decode(cookies.auth);
  const decoded = jwt_decode(localStorage.getItem("auth"));
  const [filterRowId, setFilterRowId] = useState("");
  const [rowIdToDelete, setRowIdToDelete] = useState("");
  const [formError, setFormError] = useState({});
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);
  const [addToGroupModal, setAddToGroupModal] = useState(false);
  const [deleteDashboardObj, setDeleteDashboardObj] = useState([]);
  const [deleteUserObj, setDeleteUserObj] = useState([]);
  const [usersToAssignGroup, setUsersToAssignGroups] = useState([]);
  const [assignedUserOptions, setAssignedUserOptions] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  // ----------------------------------------------------------------------------------
  const [selectedValues, setSelectedValues] = useState([]);
  const [department, setDepartment] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [location, setLocation] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const filterObject = {
    column: "",
    operator: "IN",
    values: [],
  };
  const [filterArray, setFilterArray] = useState([filterObject]);
  const [MemberCriteriaList, setMemberCriteriaList] = useState([]);
  const [selectedValueList, setSelectedValueList] = useState([]);
  const [existingParameter, setExistingParameter] = useState("");
  const [groupCategory, setGroupCategory] = useState(0);
  const [reset, setReset] = useState(false);
  const [dynamicGroupUsers, setDynamicGroupUsers] = useState([]);
  const [groupCreationDate, setGroupCreationDate] = useState([]);
  const [dashboardPlatform, setDashboardPlatform] = useState("DOMO");
  const selectRef = useRef(null);
  const [handleInitialPlatform, setHandleInitialPlatform] = useState(false);

  const groupData = {
    groupName: "",
    groupDescription: "",
    groupType: "",
    targetColumn: "",
    usersToSend: [],
  };


  const [addGroupData, setAddGroupData] = useState({});
  const [selectedTab, setSelectedTab] = useState("editGroupTab");

  useEffect(() => {
    // nav();
    setSelectedTab("editGroupTab");
  }, []);

  // const nav = (e) => {
  //   const tab = e?.target.id;
  //   setSelectedTab(tab);
  //   const nav = document.querySelector(".js-nav");
  //   const navLinks = nav.querySelectorAll(".nav__link");
  //   const slideRect = nav.querySelector(".nav__slider-rect");

  //   nav.addEventListener("click", (evt) => {
  //     if (!evt.target.classList.contains("nav__link")) {
  //       return;
  //     }
  //     evt.preventDefault();

  //     navLinks.forEach((item) => {
  //       item.classList.remove("nav__link_active");
  //     });

  //     if (!evt.target.classList.contains("nav__link_active")) {
  //       evt.target.classList.add("nav__link_active");
  //     }

  //     slideRect.style.transform = `translateX(${evt.target.dataset.transform}%)`;
  //   });
  // };

  useEffect(() => {
    // console.log("existingParameterrrrrrrrrrrrrrrrrrrrrrrrr", existingParameter);
    const newGroupData = { ...addGroupData };
    if (existingParameter === "department") {
      setSelectedValueList(selectedDepartment);
      setMemberCriteriaList(department);
      newGroupData.targetColumn = "Department";
    }
    if (existingParameter === "jobTitle") {
      setSelectedValueList(selectedJobTitle);
      setMemberCriteriaList(jobTitle);
      newGroupData.targetColumn = "Job Title";
    }
    if (existingParameter === "location") {
      setSelectedValueList(selectedLocation);
      setMemberCriteriaList(location);
      newGroupData.targetColumn = "Location";
    }

    setAddGroupData(newGroupData);
    // console.log("addGroupData", newGroupData);
  }, [existingParameter, reset]);

  useEffect(() => {
    getGroupInfo();
    getDashboards();
    getAllUsersByGroupId();
    getDashboardsByGroupId();
  }, []);

  // useEffect(() => {
  //   if (selectRef.current) {
  //     const firstOption = selectRef.current.options[0];
  //     const firstDashboardId = firstOption ? firstOption.value : "";
  //     const platform_flag = dashboards.find(
  //       (e) => e.id == firstDashboardId
  //     ).dashboard_platform_flag;
  //     if (platform_flag === 0) {
  //       setDashboardPlatform("DOMO");
  //     } else if (platform_flag === 1) {
  //       setDashboardPlatform("Powerbi");
  //     } else if (platform_flag === 2) {
  //       setDashboardPlatform("Tableau");
  //     }
  //   }
  // }, [handleInitialPlatform,dashboards]);


  useEffect(() => {
    if (selectRef.current && dashboards.length > 0) {
      const firstOption = selectRef.current.options[0];
      const firstDashboardId = firstOption ? firstOption.value : "";
      console.log("firstDashboardId", firstDashboardId);

      const selectedDashboard = dashboards.find(e => e.id == firstDashboardId);

      if (selectedDashboard) {
        const platform_flag = selectedDashboard.dashboard_platform_flag;

        if (platform_flag === 0) {
          setDashboardPlatform("DOMO");
        } else if (platform_flag === 1) {
          setDashboardPlatform("Powerbi");
        } else if (platform_flag === 2) {
          setDashboardPlatform("Tableau");
        }
        else {
          console.warn(`Unknown platform flag: ${platform_flag}`);
          setDashboardPlatform("Unknown");
        }
      } else {
        console.warn(`No dashboard found with id: ${firstDashboardId}`);
        setDashboardPlatform("Unknown");
      }
    } else {
      console.warn("No dashboards available or select element not rendered");
      setDashboardPlatform("Unknown");
    }
  }, [handleInitialPlatform, dashboards]);


  function getGroupInfo() {
    axios
      .get(`${baseURL}/getGroupInfoById/${groupId}`)
      .then(function (response) {
        const departmentValues = [];
        const locationValues = [];
        const jobTitleValues = [];
        // console.log("response", response);
        axios
          .get(`${baseURL}/getAllDynamicValuesForDynamicGroup`)
          .then((res) => {
            const values = res.data.values;
            values.forEach((v) => {
              if (v.department) {
                departmentValues.push({
                  value: v.department,
                  label: v.department,
                });
              }
              if (v.location) {
                locationValues.push({ value: v.location, label: v.location });
              }
              if (v.job_title) {
                jobTitleValues.push({ value: v.job_title, label: v.job_title });
              }
            });
          });

        setDepartment(departmentValues);
        setLocation(locationValues);
        setJobTitle(jobTitleValues);

        // console.log(response.data.groups[0]);
        setGroupCategory(response.data.groups[0].category);

        // Run if it's a dynamic group
        if (response.data.groups[0].category === 1) {
          getUsersOfDynamicGroup();
          // console.log("runnnnnnnnnnnnnnnnnnnnnnnnnnn info");
          const selectedParameter = JSON.parse(
            response.data.groups[0].dynamic_parameter
          );

          const parameterKey = Object.keys(selectedParameter)[0];

          const valueList = [];
          const value = [];
          // console.log("parameterKey", parameterKey);
          if (parameterKey === "Department") {
            for (let i = 0; i < selectedParameter.Department.length; i++) {
              const element = selectedParameter.Department[i];
              valueList.push({ value: element, label: element });
              value.push(element);
            }
            setSelectedValues(value);
            setSelectedDepartment(valueList);
            setExistingParameter("department");
          }
          if (parameterKey === "Job Title") {
            for (let i = 0; i < selectedParameter["Job Title"].length; i++) {
              const element = selectedParameter["Job Title"][i];
              valueList.push({ value: element, label: element });
              value.push(element);
            }
            setSelectedValues(value);
            setSelectedJobTitle(valueList);
            setExistingParameter("jobTitle");
          }
          if (parameterKey === "Location") {
            for (let i = 0; i < selectedParameter.Location.length; i++) {
              const element = selectedParameter.Location[i];
              valueList.push({ value: element, label: element });
              value.push(element);
            }
            setSelectedValues(value);
            setSelectedLocation(valueList);
            setExistingParameter("location");
          }
        }

        setGroupName(response.data.groups[0].name);

        setGroupDescription(response.data.groups[0].description);
        setReset(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handleGroupDataChange = (e) => {
    const fieldName = e.target.id;
    const fieldValue = e.target.value;

    const newGroupData = { ...addGroupData };
    newGroupData[fieldName] = fieldValue;

    setAddGroupData(newGroupData);

    if (fieldName === "targetColumn") {
      if (fieldValue === "Department") {
        setMemberCriteriaList(department);
        setSelectedValueList(selectedDepartment);
      }
      if (fieldValue === "Job Title") {
        setMemberCriteriaList(jobTitle);
        setSelectedValueList(selectedJobTitle);
      }
      if (fieldValue === "Location") {
        setMemberCriteriaList(location);
        setSelectedValueList(selectedLocation);
      }
    }
    // console.log("selectedValueList", selectedValueList);
  };

  const handleMemberCriteriaChange = (selectedOption) => {
    // console.log("selectedOption", selectedOption);

    if (addGroupData.targetColumn === "Department") {
      setMemberCriteriaList(department);
      setSelectedDepartment(selectedOption);
      setSelectedValueList(selectedOption);
    }
    if (addGroupData.targetColumn === "Job Title") {
      setMemberCriteriaList(jobTitle);
      setSelectedJobTitle(selectedOption);
      setSelectedValueList(selectedOption);
    }
    if (addGroupData.targetColumn === "Location") {
      setMemberCriteriaList(location);
      setSelectedLocation(selectedOption);
      setSelectedValueList(selectedOption);
    }
    // console.log("selectedValueList", selectedValueList);
  };

  const handleDefaultDepartments = () => {
    setMemberCriteriaList(department);
  };

  const handleClose = () => {
    setShow(false);
    clearModalFields();
    setEditMode(false);
  };

  const handleShow = () => setShow(true);

  function addFilter() {
    setFilterArray([...filterArray, filterObject]);
  }

  function clearModalFields() {
    setDashboardId(assignedDashboards[0]?.dashboard_id);
    setFilterArray([filterObject]);
  }

  function removeValues(value, i) {
    const some_array = [...filterArray];
    const indexToSplice = some_array[i].values.findIndex((a) => a == value);
    some_array[i].values.splice(indexToSplice, 1);
    setFilterArray([...some_array]);
  }

  function removeFilterObject(index) {
    const some_array = [...filterArray];
    some_array.splice(index, 1);
    setFilterArray([...some_array]);
  }


  const handleSelectedUsersChange = (e) => {
    setUsersToAssignGroups(e);
  };

  const handleChangeGroupName = (event) => {
    setGroupName(event.target.value);
  };

  const handleChangeGroupDescription = (event) => {
    setGroupDescription(event.target.value);
  };

  const assignGroupToUsers = () => {
    axios
      .post(`${baseURL}/assignGroupToUsers/${groupId}`, {
        users: usersToAssignGroup.map((u) => u.value),
      })
      .then((res) => {
        getAllUsersByGroupId();
        setAddToGroupModal(false);
        ref.current.clearValue();
        toast.success("Users assigned successfully!",)
      })
      .catch((err) => {
        toast.error("Failed to assign users.",)
      });
  };

  function deleteGroupDashboard(id) {
    axios
      .delete(`${baseURL}/deleteGroupDashboard/${id}`)
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "dashboard unshared",
            info: `group dashboard Id = ${id}`,
          })
          .then((res) => {
            // console.log(res);
          });
        getDashboardsByGroupId();
        setDeleteModalShow(false);
        setDeleteDashboardObj({});
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function getDashboards() {
    axios
      .get(`${baseURL}/getDashboardsById`)
      .then(function (response) {
        // console.log(response.data.dashboards);
        setDashboards(response.data.dashboards);
        setDashboardId(response.data.dashboards[0].id);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function handleChangeColumn(event, i) {
    const some_array = [...filterArray];
    some_array[i].column = event.target.value;
    setFilterArray([...some_array]);
  }

  function handleChangeOperator(event, i) {
    const some_array = [...filterArray];
    some_array[i].operator = event.target.value;
    setFilterArray([...some_array]);
  }

  // function handleChangeValue(event, i) {
  //   const some_array = [...filterArray];
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     some_array[i].values.push(event.target.value);
  //     setFilterArray([...some_array]);
  //     ref.current.value = "";
  //   }
  // }

  function handleChangeValue(event, i) {
    const some_array = [...filterArray];
    if (event.key === "Enter") {
      event.preventDefault();
      some_array[i].values.push(event.target.value);
      setFilterArray([...some_array]);
      
      // Safely clear the input value
      if (ref.current) {
        ref.current.value = "";
      } else {
        // If ref.current is null, we can clear the input value directly from the event target
        event.target.value = "";
      }
    }
  }

  function handleChangeDashboard(event) {
    setDashboardId(event.target.value);
    const platform_flag = dashboards.find(
      (e) => e.id == event.target.value
    ).dashboard_platform_flag;
    if (platform_flag === 0) {
      setDashboardPlatform("DOMO");
    } else if (platform_flag === 1) {
      setDashboardPlatform("Powerbi");
    } else if (platform_flag === 2) {
      setDashboardPlatform("Tableau");
    }
  }

  const handleDashboardDefault = (event) => {
    setDashboardId(document.getElementById("dashboardSelect").value);
  };

  function handleChangeFilter(event) {
    setFilter(event.target.value);
  }

  const getUsersOfDynamicGroup = () => {
    axios
      .get(`${baseURL}/getUsersOfDynamicGroup/${groupId}`)
      .then(function (res) {
        setDynamicGroupUsers(res.data.users);
        setGroupCreationDate(res.data.groupDate);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  function getDashboardsByGroupId() {
    axios
      .get(`${baseURL}/getDashboardsByGroupId/${groupId}`)
      .then(function (response) {
        setAssignedDashboards(response.data.dashboards);
        console.log(response.data.dashboards);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // function editFilters(index) {
  //   // console.log(index);
  //   setEditMode(true);
  //   const objectToEdit = assignedDashboards[index];
  //   setFilterRowId(objectToEdit.rowId);
  //   setDashboardId(objectToEdit.id);
  //   setFilterArray(JSON.parse(objectToEdit.filter));
  //   if (objectToEdit.dashboard_platform_flag === 1) {
  //     setDashboardPlatform("Powerbi");
  //   } else if (objectToEdit.dashboard_platform_flag === 0) {
  //     setDashboardPlatform("DOMO");
  //   } else if (objectToEdit.dashboard_platform_flag === 2) {
  //     setDashboardPlatform("Tableau");
  //   }
  //   handleShow();
  // }


  function updateFilter() {
    let isUnfiltered = false;

    if (
      filterArray.length >= 1 &&
      (((dashboardPlatform === 'DOMO' || dashboardPlatform === 'Tableau') && (filterArray[0].column === "" || filterArray[0].values.length === 0)) || (filterArray[0].values.length === 0))
    ) {
      isUnfiltered = true;
    }

    axios
      .put(`${baseURL}/updateGroupFilters/${filterRowId}`, {
        filter: isUnfiltered ? [] : filterArray,
      })
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "group filter updated",
            info: `filter rowId = ${filterRowId}`,
          })
          .then((res) => {
            // console.log(res);
          });
        getDashboardsByGroupId();
        handleClose();
        toast.success("Filter updated successfully!"); // Success toast
      })
      .catch(function (error) {
        console.error("Failed to update filter: ", error);
        toast.error("Failed to update filter. Please try again."); // Error toast
      });
  }

  function updateGroupInfo() {
    if (groupName) {
      axios
        .put(`${baseURL}/updateGroup/${groupId}`, {
          groupName: groupName,
          groupDescription: groupDescription,
          dynamic_parameter: {
            [addGroupData.targetColumn]: selectedValueList.map((e) => e.value),
          },
          category: groupCategory,
        })
        .then(function (response) {
          setSelectedValues(selectedValueList.map((e) => e.value));
          if (groupCategory == 1) {
            getUsersOfDynamicGroup();
          }
          axios
            .post(`${baseURL}/logAdminEvent`, {
              eventType: "group updated",
              info: `groupId = ${groupId}`,
            })
            .then((res) => {
              // console.log(res);
            });

          // Show success toast message
          toast.success('Group updated successfully!')

          setUserEditMode(false);
        })
        .catch(function (error) {
          // console.log(error);

          // Show error toast message
          toast.error('Failed to update group. Please try again.');
        });
    } else {
      const err = {};
      if (!groupName) {
        err.groupName = "Group Name is mandatory";
      }
      setFormError(err);

    }
  }

  const handleDeleteUserDashboardClick = (index) => {
    // console.log(index);
    const foundDashboard = assignedDashboards.find(
      (assignedDashboard, i) => i === index
    );
    // console.log(foundDashboard);
    setDeleteDashboardObj(foundDashboard);
    // console.log(deleteDashboardObj);
    setDeleteModalShow(true);
  };

  function checkDashboard(dashboardId) {
    // console.log(assignedDashboards);
    const res = assignedDashboards.find((e) => e.id == dashboardId);
    // console.log(res);
    if (res) {
      return false;
    } else {
      return true;
    }
  }

  // function handleUserEdit() {
  //   setUserEditMode(true);
  // }

  const handleRemoveUserClick = (userId) => {
    const foundUser = assignedUsers.find(
      (assigneduser) => assigneduser.user_id == userId
    );
    // console.log(foundUser);
    setDeleteUserObj(foundUser);
    setDeleteUserModalShow(true);
  };

  const deleteGroupUser = (userId) => {
    axios
      .delete(`${baseURL}/removeUserFromGroup/${groupId}/${userId}`)
      .then(function (response) {
        getAllUsersByGroupId();
        setDeleteUserModalShow(false);
        setDeleteUserObj({});
        toast.success('User removed successfully!');
      })
      .catch(function (error) {
        // console.log(error);
        toast.error('Failed to remove user. Please try again.'); // Show error toast
      });
  };

  const getAllUsersByGroupId = () => {
    axios
      .get(`${baseURL}/getAllUsersByGroupId/${groupId}`)
      .then(function (response) {
        // console.log(response.data.users)
        if (response.data.users) {
          const options = response.data.users.map((user) => ({
            value: user.user_id,
            label: user.first_name + " " + user.last_name,
          }));

          setAssignedUserOptions(options);
        }

        setAssignedUsers(response.data.users);
        setFilteredUsers(response.data.users);
        getAllUsers(response.data.users);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const getAllUsers = (users) => {
    const dropdownUsers = [];
    setUsers([]);
    axios
      .get(`${baseURL}/getAllUsersByOrgId`)
      .then(function (response) {
        // console.log(response);
        response.data.users.forEach((element) => {
          // console.log(element);
          // console.log(users);
          const findUsers = users.find((u) => u.user_id == element.id);
          if (!findUsers) {
            dropdownUsers.push({
              value: element.id,
              label: element.first_name + " " + element.last_name,
            });
          }
        });
        // const filteredUsers = dropdownUsers.filter(d => )
        // console.log("dropdownUsers",dropdownUsers)
        setUsers(dropdownUsers);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleBackClick = () => {
    navigate("/dashboard/settings/manage-group");
  };

  // function saveInfo() {
  //   let isUnfiltered = false;
  //   if (
  //     filterArray.length >= 1 &&
  //     (((dashboardPlatform === 'DOMO' || dashboardPlatform === 'Tableau') && (filterArray[0].column == "" || filterArray[0].values.length === 0)) || (filterArray[0].values.length === 0))
  //   ) {
  //     isUnfiltered = true;
  //   }
  //   axios
  //     .post(`${baseURL}/saveGroupFilterDetails`, {
  //       filter: isUnfiltered ? [] : filterArray,
  //       dashboardId: dashboardId,
  //       groupId: groupId,
  //     })
  //     .then(function (response) {
  //       axios
  //         .post(`${baseURL}/logAdminEvent`, {
  //           eventType: "dashboard & filter assigned to group",
  //           info: `dashboardId = ${dashboardId}, group = ${groupId}`,
  //         })
  //         .then((res) => {
  //           // console.log(res);
  //         });
  //       getDashboardsByGroupId();
  //       handleClose();
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  function saveInfo() {
    let isUnfiltered = false;

    if (
      filterArray.length >= 1 &&
      (((dashboardPlatform === 'DOMO' || dashboardPlatform === 'Tableau') && (filterArray[0].column === "" || filterArray[0].values.length === 0)) || (filterArray[0].values.length === 0))
    ) {
      isUnfiltered = true;
    }

    axios
      .post(`${baseURL}/saveGroupFilterDetails`, {
        filter: isUnfiltered ? [] : filterArray,
        dashboardId: dashboardId,
        groupId: groupId,
      })
      .then(function (response) {
        axios
          .post(`${baseURL}/logAdminEvent`, {
            eventType: "dashboard & filter assigned to group",
            info: `dashboardId = ${dashboardId}, group = ${groupId}`,
          })
          .then((res) => {
            console.log(res);
          });
        getDashboardsByGroupId();
        handleClose();
        toast.success("Filter details saved successfully!"); // Success toast
      })
      .catch(function (error) {
        console.error("Failed to save filter details: ", error);
        toast.error("Failed to save filter details. Please try again."); // Error toast
      });
  }

  const handleUserFilter = (selectedOption) => {
    // const selected = {  };

    if (selectedOption) {
      const found = assignedUsers.find(
        (user) => user.user_id === selectedOption.value
      );
      // console.log("found",found,selectedOption,assignedUsers)
      setFilteredUsers([found]);
    } else {
      // selected.value = "";
      // selected.label = "Search Users";
      setFilteredUsers(assignedUsers);
    }
    // setSelectedUser(selected);
  };


  // ----------- NEW STATES AND FUNCTIONS --------------------

  const [activeTab, setActiveTab] = useState('groupInformation');
  const [error, setError] = useState('');


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // console.log(tabName);
  };

  const [userEditMode, setUserEditMode] = useState(false);

  const handleEditClick = () => {
    setUserEditMode(true);
  };

  const handleCancelClick = () => {
    setUserEditMode(false);
  };

  const handleDashboardClick = () => {
    setEditMode(false); // Set to false if it's for adding filters, or true if editing
    setShow(true); // Show the modal
    setHandleInitialPlatform(!handleInitialPlatform);
  };

  const handleDashboardClose = () => {
    setShow(false); // Hide the modal

    // Reset modal state
    setEditMode(false);
    // setFormData({ /* initial form state */ });
    // Reset any other state variables here
  };

  // --------------- Assigned Dashboard Table related : 

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);


  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  function editFilters(index) {
    // console.log(index);
    setEditMode(true);
    const objectToEdit = assignedDashboards[index];
    setFilterRowId(objectToEdit.rowId);
    setDashboardId(objectToEdit.id);
    setFilterArray(JSON.parse(objectToEdit.filter));
    if (objectToEdit.dashboard_platform_flag === 1) {
      setDashboardPlatform("Powerbi");
    } else if (objectToEdit.dashboard_platform_flag === 0) {
      setDashboardPlatform("DOMO");
    } else if (objectToEdit.dashboard_platform_flag === 2) {
      setDashboardPlatform("Tableau");
    }
    handleShow();
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);


  const handleDelete = (id) => {
    // console.log("Delete action triggered for ID:", id);
    setDeleteDashboardObj({ id }); // Set the id in the deleteDashboardObj
    setShowDeleteModal(true);      // Show the delete modal
    // console.log("Modal state set to true");
  };


  const handleDeleteConfirm = (id) => {
    axios.delete(`${baseURL}/deleteGroupDashboard/${id}`)
      .then(response => {
        // Log the admin event for deleting the group dashboard
        axios.post(`${baseURL}/logAdminEvent`, {
          eventType: "dashboard unshared",
          info: `group dashboard Id = ${id}`,
        }).then(res => {
          // Additional logging or actions if needed
          // console.log(res);
        });

        toast.success("Dashboard deleted successfully!");
        setShowDeleteModal(false);
        setDeleteDashboardObj({});
        getDashboardsByGroupId();
      })
      .catch(error => {
        // Handle errors
        console.error("There was an error deleting the dashboard!", error);
        toast.error("Failed to delete the dashboard. Please try again.");
      });
  };

  const columns = [
    { field: 'number', headerName: 'Id', width: 80, align: 'center', headerAlign: 'center' },
    { field: 'dashboardName', headerName: 'Dashboard Name', flex: 1 },
    { field: 'assignedOn', headerName: 'Assigned On', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="Options"
            onClick={(event) => handleOpenMenu(event, params.row)}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && currentRow?.id === params.row.id}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => editFilters(params.row.index)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDelete(params.row.rowId)}>Delete</MenuItem>
          </Menu>
        </>
      )
    },
  ];

  const rows = assignedDashboards.map((dashboard, index) => ({
    number: index + 1,
    id: dashboard.id,
    rowId: dashboard.rowId,
    dashboardName: dashboard.dashboard_name,
    assignedOn: dashboard.created_on.split("T")[0],
    index: index
  }));


  const columnsTwo = [
    { field: 'number', headerName: 'Id', width: 80, align: 'center', headerAlign: 'center', },
    { field: 'userName', headerName: 'User Name', flex: 2 },
    { field: 'assignedOn', headerName: 'Assigned On', flex: 1 },
    ...(groupCategory === 0 ? [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        align: 'center',
        headerAlign: 'center',

        renderCell: (params) => (
          <Button
            onClick={() => handleRemoveUserClick(params.row.id)}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            size='small'
          >
            Remove
          </Button>
        )

      }
    ] : []),
  ];


  const rowsTwo = [
    ...filteredUsers.map((user, index) => ({
      number: index + 1,
      id: user.user_id,  // Ensure 'id' is always defined
      userName: `${user.first_name} ${user.last_name}`,
      assignedOn: user.created_on.split("T")[0],
    })),
    ...dynamicGroupUsers.map((user, index) => ({
      number: index + 1,
      id: user.user_id, // Ensure 'id' is always defined
      userName: `${user.first_name} ${user.last_name}`,
      assignedOn: user.created_time > groupCreationDate
        ? user.created_time.split("T")[0]
        : groupCreationDate.split("T")[0],
    }))
  ];

  // console.log('rowsTwo', rowsTwo);



  return (
    <>
      <label className="px-3 text-sm text-gray-900 capitalize py-1 flex items-center overflow-x-auto whitespace-nowrap">
        <Link to={'/dashboard/settings/manage-groups'} className="text-gray-600 hover:underline">
          Group Details
        </Link>

        <span className="mx-3 text-gray-500 rtl:-scale-x-100">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </span>

        <p className={`text-${selectedColor}-600 hover:underline`} >

          Group Info
        </p>
      </label>

      <div className="mt-8 flex justify-between items-center border-b border-gray-200">
        <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
          <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'groupInformation'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('groupInformation')}
          >
            <HiOutlineUsers className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              Group Information
            </span>
          </button>

          <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'assignedDashboards'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('assignedDashboards')}
          >
            <LuLayoutDashboard className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              Assigned Dashboards
            </span>
          </button>
          {/* <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'assignedTables'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('assignedTables')}
          >
            <LuTable2 className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              Assigned Tables
            </span>
          </button> */}
          <button
            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'assignedUsers'
              ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
              : 'text-gray-700 border-b-2 border-transparent'
              } bg-transparent sm:px-4 whitespace-nowrap focus:outline-none hover:border-gray-400`}
            onClick={() => handleTabClick('assignedUsers')}
          >
            <LuUser2 className="w-4 h-4 mx-1 sm:w-5 sm:h-5" />
            <span className="mx-1 text-xs sm:text-sm font-medium">
              Assigned Users
            </span>
          </button>
        </div>
      </div>

      {/* Group Information Tab */}

      <div className="my-4 relative">
        {activeTab === 'groupInformation' && (
          <>
            <div className='flex justify-between mb-2'>
              <p className='text-sm text-gray-900 my-auto font-medium ml-4'>Group Info</p>
              <button
                type="button"
                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                onClick={handleEditClick}
              >
                <FiEdit className="w-5 h-5 mr-2" />
                <span className="text-sm">Edit Info</span>
              </button>
            </div>
            <div className="p-8 bg-white shadow rounded-lg">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-1 p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Group Name</h3>
                  <p className="mt-1 text-base text-gray-900 break-words">{groupName}</p>
                </div>
                <div className="col-span-2 p-4 border rounded-lg shadow-sm">
                  <h3 className="text-xs font-semibold text-gray-500">Group Description</h3>
                  {groupDescription ? (
                    <p className="mt-2 text-base text-gray-900 break-words">{groupDescription}</p>
                  ) : (
                    <p className="mt-2 text-base text-gray-500 break-words">No description available</p>
                  )}
                </div>
              </div>
              {!userEditMode && groupCategory === 1 ? (
                <p className="mt-5 mb-2 text-sm text-gray-900">
                  <span className="text-red-600">*</span>{" "}
                  Group dynamically created on the basis of
                  <strong className={`text-${selectedColor}-500 ml-1`}>
                    {addGroupData.targetColumn || "Field"}
                  </strong>{" "}
                  with values{" "}
                  <strong className={`text-${selectedColor}-500 ml-1`}>
                    {selectedValues.length > 0 ?
                      (selectedValues.length === 1 ?
                        selectedValues[0] :
                        `[${selectedValues.join(", ")}]`)
                      : "N/A"}
                  </strong>
                </p>
              ) : null}
            </div>


            {/* Modal */}
            {userEditMode && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                <div className="bg-white rounded shadow-xl w-2/3 2xl:w-5/12 transition-transform transform duration-300">
                  <div className="px-6 py-4 flex justify-between items-center border-b">
                    <h3 className="text-lg font-semibold text-gray-800">Edit Group Information</h3>
                    <button className="text-gray-400 hover:text-gray-500" onClick={handleCancelClick}>
                      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <form className="px-6 py-4" onSubmit={updateGroupInfo}>
                    <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2 text-sm">
                      <div>
                        <label className="text-gray-700" htmlFor="groupName">
                          Group Name <span className="text-red-500 font-bold">*</span>
                        </label>
                        <input
                          type="text"
                          id="groupName"
                          onChange={handleChangeGroupName}
                          value={groupName}
                          readOnly={!userEditMode}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Enter group name"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.groupName}</p>
                      </div>
                      <div>
                        <label className="text-gray-700" htmlFor="groupDescription">
                          Group Description
                        </label>
                        <input
                          type="text"
                          id="groupDescription"
                          onChange={handleChangeGroupDescription}
                          value={groupDescription}
                          readOnly={!userEditMode}
                          className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                          placeholder="Enter group description"
                        />
                        <p className="text-red-500 text-xs mt-1">{formError.groupDescription}</p>
                      </div>
                    </div>

                    {groupCategory === 1 && !userEditMode && (
                      <div className="mt-4 text-sm">
                        <label className="text-sm text-gray-600">
                          <span className="text-red-500">*</span> Group dynamically created based on{" "}
                          <b>{addGroupData.targetColumn}</b> with values{" "}
                          <b>[{selectedValues.join(", ")}]</b>
                        </label>
                      </div>
                    )}

                    {groupCategory === 1 && userEditMode && (
                      <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 text-sm">
                        <div>
                          <label className="text-gray-700" htmlFor="targetColumn">
                            Field <span className="text-red-500 font-bold">*</span>
                          </label>
                          <select
                            id="targetColumn"
                            className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                            onChange={handleGroupDataChange}
                            value={addGroupData.targetColumn}
                          >
                            <option value="" disabled>Select Field</option>
                            <option value="Department">Department</option>
                            <option value="Job Title">Job Title</option>
                            <option value="Location">Location</option>
                          </select>
                        </div>
                        <div>
                          <label className="text-gray-700" htmlFor="criteria">
                            Select{" "}
                            <strong className={`text-${selectedColor}-500`}>
                              {addGroupData.targetColumn || "Field"}
                            </strong>{" "}
                            of members to add to this group{" "}
                            <span className="text-red-500 font-bold">*</span>
                          </label>
                          <Select
                            id="members"
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedValueList}
                            onChange={handleMemberCriteriaChange}
                            options={MemberCriteriaList}
                            className="mt-2"
                            classNamePrefix="select"
                          />
                          <p className="text-red-500 text-xs mt-1">{formError.targetColumn}</p>
                        </div>
                      </div>
                    )}
                  </form>
                  <div className="px-6 py-3 flex justify-end gap-4 border-t">
                    <button
                      className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"

                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                    <button
                      className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                      type="submit"
                      form="editGroupForm"
                      onClick={updateGroupInfo}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {activeTab === 'assignedDashboards' && (
          <>
            <div className='flex justify-between mb-2'>
              <p className='text-sm text-gray-900 my-auto font-medium ml-4'>Dashboard Details</p>
              <button
                type="button"
                className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                onClick={handleDashboardClick}
              >
                <PiWrench className="w-5 h-5 mr-2" />
                <span className="text-sm">Configure</span>
              </button>
            </div>
            <div className="mt-4" style={{ height: 'auto', width: '100%' }}>
              <StyledDataGrid
                rows={rows}
                columns={columns}
                rowHeight={45}
                columnHeaderHeight={50}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                autoHeight
                // checkboxSelection
                disableSelectionOnClick
                disableRowSelectionOnClick
              />
            </div>


            <>
              {/* Modals */}
              {show && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                  <div className="bg-white rounded shadow-xl w-4/5 2xl:w-3/6 transition-transform transform duration-300">
                    <div className="px-6 py-4 flex justify-between items-center border-b">
                      <h3 className="text-lg font-semibold text-gray-800">
                        {editMode ? "Edit Dashboard Filters" : "Add Dashboard Filters"}
                      </h3>
                      <button
                        className="text-gray-400 hover:text-gray-500"
                        onClick={handleDashboardClose}
                      >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="px-6 py-4">
                      <form className="w-75 text-sm">
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                          <div className="col mb-2 w-full">
                            <label htmlFor="InputRole" className="text-gray-700">
                              Select Dashboard
                            </label>
                            <select
                              ref={selectRef}
                              className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                              id="dashboardSelect"
                              aria-label="Default select example"
                              onChange={handleChangeDashboard}
                              value={dashboardId}
                              disabled={editMode ? true : false}
                            >
                              {dashboards.map((dashboard, index) => {
                                if (editMode) {
                                  return (
                                    <option value={dashboard.id} key={dashboard.id}>
                                      {dashboard.dashboard_name +
                                        " [ " +
                                        dashboard.dashboard_embed_id +
                                        " ]"}{" "}
                                    </option>
                                  );
                                } else if (checkDashboard(dashboard.id, index)) {
                                  return (
                                    <option value={dashboard.id} key={dashboard.id}>
                                      {dashboard.dashboard_name +
                                        " [ " +
                                        dashboard.dashboard_embed_id +
                                        " ]"}{" "}
                                    </option>
                                  );
                                }
                                return null;
                              })}
                            </select>
                          </div>
                        </div>

                        {dashboardPlatform === 'DOMO' &&
                          filterArray.map((filter, i) => (
                            <div className="mt-4" key={i}>
                              <p className="text-sm text-gray-600 mt-4 italic">
                                Enter column, select operator, and press Enter to add values.
                              </p>
                              <div className="flex items-center gap-4 mb-2">
                                <div className="flex-1">
                                  <input
                                    type="text"
                                    name="column"
                                    onChange={(event) => handleChangeColumn(event, i)}
                                    value={filter?.column || ""}
                                    placeholder="Column"
                                    className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                  />
                                </div>
                                <div className="flex-1">
                                  <select
                                    className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                    onChange={(event) => handleChangeOperator(event, i)}
                                    value={filter?.operator || ""}
                                  >
                                    {/* <option value="" disabled>Select Operator</option> */}
                                    <option value="IN">IN</option>
                                    <option value="NOT_IN">NOT_IN</option>
                                    <option value="EQUALS">EQUALS</option>
                                    <option value="NOT_EQUALS">NOT_EQUALS</option>
                                    <option value="GREATER_THAN">GREATER_THAN</option>
                                    <option value="GREAT_THAN_EQUALS_TO">GREATER_THAN_EQUALS_TO</option>
                                    <option value="LESS_THAN">LESS_THAN</option>
                                    <option value="LESS_THAN_EQUALS_TO">LESS_THAN_EQUALS_TO</option>
                                  </select>
                                </div>
                                <div className="flex-1">
                                  <input
                                    type="text"
                                    name="values"
                                    ref={ref}
                                    onKeyDown={(event) => {
                                      if (event.key === 'Enter') {
                                        event.preventDefault();
                                        const value = event.target.value.trim();
                                        if (value.length === 0) {
                                          setError("Value cannot be empty");
                                        } else {
                                          handleChangeValue(event, i);
                                          event.target.value = '';
                                          setError('');
                                        }
                                      }
                                    }}
                                    placeholder="Type and press Enter to add"
                                    className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                  />
                                </div>
                              </div>
                              {error && (
                                <p className="text-red-500 text-sm mb-2">{error}</p>
                              )}

                              <div className="flex flex-wrap gap-2 mt-2">
                                {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => {
                                  return (
                                    <div className="flex items-center bg-gray-50 rounded px-3 py-1 text-sm">
                                      <span className="text-black mr-1">{v}</span>
                                      <CiCircleRemove
                                        className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                        onClick={() => removeValues(v, i)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="mt-2 text-red-500 hover:text-red-700 cursor-pointer">
                                <span
                                  className="pb-1"
                                  onClick={() => removeFilterObject(i)}
                                >
                                  Remove Filter
                                </span>
                              </div>
                            </div>
                          ))
                        }


                        {dashboardPlatform === "Tableau" &&
                          filterArray.map((filter, i) => (
                            <div className="mt-4" key={i}>
                              <p className="text-sm text-gray-600 mt-4 italic">
                                Enter column name and press Enter to add values.
                              </p>
                              <div className="flex items-center gap-4 mb-2">
                                <div className="flex-1">
                                  <input
                                    type="text"
                                    name="column"
                                    onChange={(event) => handleChangeColumn(event, i)}
                                    value={filter?.column || ""}
                                    placeholder="Column"
                                    className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                    id="column"
                                  />
                                </div>
                                <div className="flex-1">
                                  <input
                                    type="text"
                                    name="values"
                                    ref={ref}
                                    onKeyDown={(event) => {
                                      if (event.key === 'Enter') {
                                        event.preventDefault();
                                        const value = event.target.value.trim();
                                        if (value.length === 0) {
                                          setError("Value cannot be empty");
                                        } else {
                                          handleChangeValue(event, i);
                                          event.target.value = '';
                                          setError('');
                                        }
                                      }
                                    }}
                                    placeholder="Type and press Enter to add"
                                    className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                  />
                                </div>
                              </div>
                              {error && (
                                <p className="text-red-500 text-sm mb-2">{error}</p>
                              )}
                              <div className="flex flex-wrap gap-2 mt-2">
                                {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => {
                                  return (
                                    <div className="flex items-center bg-gray-50 rounded px-3 py-1 text-sm">
                                      <span className="text-black mr-1">{v}</span>
                                      <CiCircleRemove
                                        className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                        onClick={() => removeValues(v, i)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="mt-2 text-red-500 hover:text-red-700 cursor-pointer">
                                <span
                                  className="pb-1"
                                  onClick={() => removeFilterObject(i)}
                                >
                                  Remove Filter
                                </span>
                              </div>
                            </div>
                          ))
                        }

                        {dashboardPlatform === "Powerbi" &&
                          filterArray.map((filter, i) => (
                            <div className="mt-4" key={i}>
                              <p className="text-sm text-gray-600 mt-4 italic">
                                Press Enter to add a new role or value.
                              </p>
                              <div className="mb-2">
                                <input
                                  type="text"
                                  name="values"
                                  ref={ref}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      event.preventDefault();
                                      const value = event.target.value.trim();
                                      if (value.length === 0) {
                                        setError("Value cannot be empty");
                                      } else {
                                        handleChangeValue(event, i);
                                        event.target.value = '';
                                        setError('');
                                      }
                                    }
                                  }}
                                  placeholder="Type and press Enter to add"
                                  className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                                />
                              </div>
                              {error && (
                                <p className="text-red-500 text-sm mb-2">{error}</p>
                              )}
                              {/* <div className="flex flex-wrap gap-2">
                                {filterArray && filterArray.length && filterArray[i]?.values?.map((v, valueIndex) => (
                                  <span
                                    key={`${i}-${valueIndex}`}
                                    className="flex items-center bg-gray-50 rounded-lg px-3 py-1 text-sm"
                                  >
                                    <span className="text-gray-700 mx-2">{v}</span>
                                    <button
                                      onClick={() => removeValues(v, i)}
                                      className="text-red-500 hover:text-red-700"
                                    >
                                      <CiCircleRemove className="w-5 h-5" />
                                    </button>
                                  </span>
                                ))}
                              </div>
                              <button
                                className="mt-2 text-red-500 hover:text-red-700"
                                onClick={() => removeFilterObject(i)}
                              >
                                Remove Filter
                              </button> */}

                              <div className="flex flex-wrap gap-2 mt-2">
                                {filterArray && filterArray.length && filterArray[i]?.values?.map((v) => {
                                  return (
                                    <div className="flex items-center bg-gray-50 rounded px-3 py-1 text-sm">
                                      <span className="text-black mr-1">{v}</span>
                                      <CiCircleRemove
                                        className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                        onClick={() => removeValues(v, i)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="mt-2 text-red-500 hover:text-red-700 cursor-pointer">
                                <span
                                  className="pb-1"
                                  onClick={() => removeFilterObject(i)}
                                >
                                  Remove Filter
                                </span>
                              </div>  
                            </div>
                          ))
                        }


                        {/* <button
                          type="button"
                          className={`mt-4 inline-flex justify-center items-center rounded text-xs font-light bg-transparent text-${selectedColor}-500 transition duration-300 ease-in-out hover:text-${selectedColor}-700`}
                          onClick={addFilter}// ---------- Add User Event Binding
                        >
                          <IoIosAdd className="w-5 h-5" />
                          <span className="text-sm">  {dashboardPlatform === "Powerbi" ? "New Role" : dashboardPlatform === "DOMO" || dashboardPlatform === "Tableau" ? "New Filter" : "New Filter"}</span>
                        </button> */}

                        {dashboardPlatform !== "Unknown" && (
                          <button
                            type="button"
                            className={`mt-4 inline-flex justify-center items-center rounded text-xs font-light bg-transparent text-${selectedColor}-500 transition duration-300 ease-in-out hover:text-${selectedColor}-700`}
                            onClick={addFilter} // ---------- Add User Event Binding
                          >
                            <IoIosAdd className="w-5 h-5" />
                            <span className="text-sm">
                              {dashboardPlatform === "Powerbi"
                                ? "New Role"
                                : dashboardPlatform === "DOMO" || dashboardPlatform === "Tableau"
                                  ? "New Filter"
                                  : "New Filter"}
                            </span>
                          </button>
                        )}

                      </form>
                    </div>

                    <div className="px-6 py-4 border-t flex justify-end space-x-5">
                      <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={handleDashboardClose}>
                        Cancel
                      </button>
                      <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                        onMouseOver={handleDashboardDefault}
                        onClick={editMode ? updateFilter : saveInfo}>
                        {editMode ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                    <h2 className="text-lg font-semibold mb-4">Confirm Deletion</h2>
                    <p className="mb-6">Are you sure you want to delete this dashboard?</p>
                    <div className="flex justify-end space-x-4">
                      <button
                        onClick={() => setShowDeleteModal(false)}
                        className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleDeleteConfirm(deleteDashboardObj.id)}
                        className="inline-flex justify-center items-center rounded bg-red-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>


          </>
        )}

        {activeTab === "assignedUsers" && (
          <>
            <div className="flex justify-between mb-3">
              <p className="text-sm text-gray-900 my-auto font-medium ml-4">Assigned Users</p>
              {groupCategory === 0 && (
                <>
                  <button
                    type="button"
                    className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                    onClick={() => setAddToGroupModal(true)}
                  >
                    <IoIosAdd className="w-5 h-5 mr-2" />
                    <span className="text-sm">Add to Group</span>
                  </button>
                </>
              )}
            </div>
            <div className="mt-4" style={{ height: 'auto', width: '100%' }}>
              <StyledDataGrid
                rows={rowsTwo}
                columns={columnsTwo}
                rowHeight={45}
                columnHeaderHeight={50}
                getRowId={(row) => row.id || row.userName}  // Fallback to userName or another unique property
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                autoHeight
                // checkboxSelection
                disableSelectionOnClick
                disableRowSelectionOnClick
              />
            </div>

            {deleteUserModalShow && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                <div className="bg-white rounded shadow-xl w-1/3 2xl:w-1/3 transition-transform transform duration-300">
                  <div className="px-6 py-4 flex justify-between items-center border-b">
                    <h3 className="text-lg font-semibold text-gray-800">Remove User</h3>
                    <button
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        setDeleteUserModalShow(false);
                        setDeleteUserObj(null);
                      }}
                    >
                      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="px-6 py-4 text-center">
                    <p className="text-sm text-gray-600">
                      Are you sure you want to remove{" "}
                      <strong>
                        {deleteUserObj
                          ? `"${deleteUserObj.first_name + " " + deleteUserObj.last_name}"`
                          : ""}
                      </strong>
                      ?
                    </p>
                  </div>
                  <div className="px-6 py-3 flex justify-end gap-4 border-t">
                    <button
                      className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                      onClick={() => {
                        setDeleteUserModalShow(false);
                        setDeleteUserObj(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="inline-flex justify-center items-center rounded bg-red-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
                      onClick={() => deleteGroupUser(deleteUserObj.user_id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            )}


            {addToGroupModal && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
                <div className="bg-white rounded shadow-xl w-2/3 2xl:w-5/12 transition-transform transform duration-300">
                  <div className="px-6 py-4 flex justify-between items-center border-b">
                    <h3 className="text-lg font-semibold text-gray-800">Add to Group</h3>
                    <button
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setAddToGroupModal(false)}
                    >
                      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="px-6 py-4">
                    <label className="text-sm text-gray-700 mb-2 block" htmlFor="usersSelected">
                      Select Users
                    </label>
                    <Select
                      ref={ref}
                      id="usersSelected"
                      isMulti
                      onChange={(e) => handleSelectedUsersChange(e)}
                      options={users}
                      className="react-select text-start"
                      classNamePrefix="select"
                      maxMenuHeight={100}
                    // styles={colourStylesRow}
                    />
                  </div>
                  <div className="px-6 py-3 flex justify-end gap-4 border-t">
                    <button
                      className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                      onClick={() => setAddToGroupModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                      onClick={() => assignGroupToUsers()}
                    >
                      Add to Group
                    </button>
                  </div>
                </div>
              </div>
            )}

          </>
        )}


      </div>
    </>
  );
};

export default EditGroups;
