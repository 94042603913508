import React, { useState, useEffect } from 'react'
import PowerBi from '../../assets/powerbi.png'
import tableau from '../../assets/tableau.png'
import Domo from '../../assets/domo.png'
import { FiEdit } from "react-icons/fi";
import { GrConfigure } from "react-icons/gr";
import ConfigureWrench from '../../assets/Configure.png'
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { baseURL } from '../../constants.js'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import toast, { Toaster } from 'react-hot-toast';
import { useTheme } from '../../ThemeContext.js'; // Import useTheme


const Configure = () => {

    const { selectedColor } = useTheme();

    // -------------OLD VERSION FUNCTIONS AND STATES-------------------------
    const [editMode, setEditMode] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);
    const [savePowerbiUpdate, setSavePowerbiUpdate] = useState(false);
    const [saveTableauUpdate, setSaveTableauUpdate] = useState(false);
    const [orgUrl, setOrgURL] = useState("");
    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [powerbiClientId, setPowerbiClientId] = useState("");
    const [powerbiClientSecret, setPowerbiClientSecret] = useState("");
    const [powerbiTenantId, setPowerbiTenantId] = useState("");
    const [tableauClientId, setTableauClientId] = useState("");
    const [tableauSecretId, setTableauSecretId] = useState("");
    const [tableauSecretValue, setTableauSecretValue] = useState("");
    const [tableauUsername, setTableauUsername] = useState("");
    const [cookies, setCookie] = useCookies(["auth"]);
    const [formError, setFormError] = useState({});
    const [clientIdVisibility, setClientIdVisibility] = useState(false);
    const [clientSecretVisibility, setClientSecretVisibility] = useState(false);
    const [powerbiClientIdVisibility, setPowerbiClientIdVisibility] =
        useState(false);
    const [powerbiClientSecretVisibility, setPowerbiClientSecretVisibility] =
        useState(false);
    const [tenantIdVisibility, setTenantIdVisibility] = useState(false);
    const [tableauSecretIdVisibility, setTableauSecretIdVisibility] =
        useState(false);
    const [tableauClientIdVisibility, setTableauClientIdVisibility] =
        useState(false);
    const [tableauSecretValueVisibility, setTableauSecretValueVisibility] =
        useState(false);
    const [adminUsernameVisibility, setAdminUsernameVisibility] = useState(false);
    const [hasDomo, setHasDomo] = useState(false);
    const [hasTableau, setHasTableau] = useState(false);
    const [hasPowerBI, setHasPowerBI] = useState(false);
    // const [platformFlag, setPlatformFlag] = useState(null);

    let decoded = {};

    useEffect(() => {

        const decodedToken = localStorage.getItem("auth")
            ? jwt_decode(localStorage.getItem("auth"))
            : null;
        const flag = decodedToken.platformFlag;

        platformVisibility(flag);

    }, []);



    const [activeTab, setActiveTab] = useState('PowerBi');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        setEditMode(false);
        // console.log(tabName)
    }

    const handleEditCredentials = () => {
        // console.log('clicked')
    }


    const [isConfiguring, setIsConfiguring] = useState(false);

    // Which type of user sees which platforms

    function platformVisibility(platformFlag) {
        if (platformFlag === 1) {
            setHasDomo(true);
            setHasPowerBI(false);
            setHasTableau(false);
            setActiveTab("Domo");
            getClientInfoById();
        } else if (platformFlag === 2) {
            setHasPowerBI(true);
            setHasDomo(false);
            setHasTableau(false);
            setActiveTab("PowerBi");
            getPowerBiClientInfoById();
        } else if (platformFlag === 3) {
            setHasPowerBI(false);
            setHasDomo(false);
            setHasTableau(true);
            setActiveTab("Tableau");
            getTableauClientInfoById();
        } else if (platformFlag === 4) {
            setHasPowerBI(true);
            setHasDomo(true);
            setHasTableau(false);
            setActiveTab("Domo");
            getClientInfoById();
            getPowerBiClientInfoById();
        } else if (platformFlag === 5) {
            setHasPowerBI(false);
            setHasDomo(true);
            setHasTableau(true);
            setActiveTab("Domo");
            getClientInfoById();
            getTableauClientInfoById();
        } else if (platformFlag === 6) {
            setHasPowerBI(true);
            setHasDomo(false);
            setHasTableau(true);
            setActiveTab("PowerBi");
            getPowerBiClientInfoById();
            getTableauClientInfoById();
        } else if (platformFlag === 7) {
            setHasPowerBI(true);
            setHasDomo(true);
            setHasTableau(true);
            setActiveTab("Domo");
            getClientInfoById();
            getPowerBiClientInfoById();
            getTableauClientInfoById();
        }
    }

    // Functions to handle Onchange for all platfroms

    function handleClientIdChange(event) {
        setClientId(event.target.value);
    }

    function handleClientSecretChange(event) {
        setClientSecret(event.target.value);
    }

    function handlePowerbiCredentialsChange(event) {
        const credential = event.target.id;
        const value = event.target.value;
        if (credential === "powerbiClientId") {
            setPowerbiClientId(value);
        } else if (credential === "powerbiClientSecret") {
            setPowerbiClientSecret(value);
        } else if (credential === "powerbiTenantId") {
            setPowerbiTenantId(value);
        }
        // else if (credential === 'powerbiUsername') {
        //   setPowerbiUsername(value);
        // }else if (credential === 'powerbiPassword') {
        //   setPowerbiPassword(value);
        // }
    }

    function handleTableauCredentialsChange(event) {
        const credential = event.target.id;
        const value = event.target.value;
        if (credential === "tableauClientId") {
            setTableauClientId(value);
        } else if (credential === "tableauSecretId") {
            setTableauSecretId(value);
        } else if (credential === "tableauSecretValue") {
            setTableauSecretValue(value);
        } else if (credential === "tableauUsername") {
            setTableauUsername(value);
        }
        // else if (credential === 'powerbiPassword') {
        //   setPowerbiPassword(value);
        // }
    }

    // Functions to GET credentials for all th different Platforms

    function getClientInfoById() {
        axios
            .get(`${baseURL}/getClientInfoById`, {})
            .then(function (response) {
                if (Object.keys(response.data).length === 0) {
                    setEditMode(true);
                    setSaveUpdate(true);
                }
                decoded = jwt_decode(cookies.auth);
                // console.log("decoded", decoded);
                setOrgURL(decoded.companyName);
                setClientId(response.data.clientDetails.admin_client_id);
                setClientSecret(response.data.clientDetails.admin_client_secret);
                // setSelectedValue(response?.data?.message);
                // handleClickOpen();
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    function getPowerBiClientInfoById() {
        axios
            .get(`${baseURL}/getPowerBiClientInfoById`, {})
            .then(function (response) {
                if (Object.keys(response.data).length === 0) {
                    // setEditMode(true);
                    setSavePowerbiUpdate(true);
                }
                decoded = jwt_decode(cookies.auth);
                setOrgURL(decoded.companyName);
                setPowerbiClientId(response.data.clientDetails.admin_client_id);
                setPowerbiClientSecret(response.data.clientDetails.admin_client_secret);
                setPowerbiTenantId(response.data.clientDetails.admin_tenant_id);
                // setPowerbiUsername(response.data.clientDetails.powerbi_username);
                // setPowerbiPassword(response.data.clientDetails.powerbi_password);
                // setSelectedValue(response?.data?.message);
                // handleClickOpen();
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    function getTableauClientInfoById() {
        axios
            .get(`${baseURL}/getTableauClientInfoById`, {})
            .then(function (response) {
                if (Object.keys(response.data).length === 0) {
                    // setEditMode(true);
                    // console.log("found nothing");
                    setSaveTableauUpdate(true);
                } else {
                    decoded = jwt_decode(cookies.auth);
                    setOrgURL(decoded.companyName);
                    setTableauClientId(response.data.clientDetails.app_client_id);
                    setTableauSecretId(response.data.clientDetails.app_secret_id);
                    setTableauSecretValue(response.data.clientDetails.app_secret_value);
                    setTableauUsername(response.data.clientDetails.admin_username);
                }
                // handleClickOpen();
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    // Function to Edit the Credentials for all platforms
    function handleEdit() {
        setEditMode(true);
        setSaveUpdate(false);
        setSaveTableauUpdate(false);
        setSavePowerbiUpdate(false);
    }

    // Functions to Save the Credentials for all Platforms

    // function saveCredentials() {
    //     if (orgUrl && clientId && clientSecret) {
    //         // Remove error messages
    //         setFormError({});

    //         const axiosRequest = saveUpdate || isConfiguring
    //             ? axios.post(`${baseURL}/saveClientConfiguration`, {
    //                 adminClientId: clientId,
    //                 adminClientSecret: clientSecret,
    //             })
    //             : axios.put(`${baseURL}/updateClientConfiguration`, {
    //                 adminClientId: clientId,
    //                 adminClientSecret: clientSecret,
    //             });

    //         axiosRequest
    //             .then(function (response) {
    //                 setEditMode(false);
    //                 setIsConfiguring(false);
    //                 setSaveUpdate(false);
    //                 setClientIdVisibility(false);
    //                 setClientSecretVisibility(false);

    //                 axios.post(`${baseURL}/logAdminEvent`, {
    //                     eventType: "client secrets saved",
    //                     info: "",
    //                 }).then((res) => { });

    //                 // Optionally handle the response message
    //                 // setSelectedValue(response?.data?.message);
    //                 // handleClickOpen();
    //             })
    //             .catch((error) => {
    //                 if (error.response.data === "Duplicate Entry") {
    //                     const err = {};
    //                     err.duplicateClientData = "Provided client details already exist.";
    //                     setFormError(err);
    //                 }
    //             });
    //     } else {
    //         const err = {};
    //         if (!orgUrl) {
    //             err.orgUrl = "Organisational URL is mandatory";
    //         }
    //         if (!clientId) {
    //             err.clientId = "Client ID is mandatory";
    //         }
    //         if (!clientSecret) {
    //             err.clientSecret = "Client Secret is mandatory";
    //         }
    //         setFormError(err);
    //     }
    // }

    // function savePowerbiCredentials() {
    //     if (orgUrl && powerbiClientId && powerbiClientSecret && powerbiTenantId) {
    //         // Remove error messages
    //         setFormError({});

    //         const axiosRequest = savePowerbiUpdate || isConfiguring
    //             ? axios.post(`${baseURL}/savePowerbiClientConfiguration`, {
    //                 adminClientId: powerbiClientId,
    //                 adminClientSecret: powerbiClientSecret,
    //                 adminTenantId: powerbiTenantId,
    //             })
    //             : axios.put(`${baseURL}/updatePowerbiClientConfiguration`, {
    //                 adminClientId: powerbiClientId,
    //                 adminClientSecret: powerbiClientSecret,
    //                 adminTenantId: powerbiTenantId,
    //             });

    //         axiosRequest
    //             .then(function (response) {
    //                 setEditMode(false);
    //                 setIsConfiguring(false); // Ensure this is also set
    //                 setSavePowerbiUpdate(false);
    //                 setClientIdVisibility(false);
    //                 setClientSecretVisibility(false);

    //                 axios.post(`${baseURL}/logAdminEvent`, {
    //                     eventType: "PowerBi client secrets saved",
    //                     info: "",
    //                 }).then((res) => { });

    //                 // Optionally handle the response message
    //                 // setSelectedValue(response?.data?.message);
    //                 // handleClickOpen();
    //             })
    //             .catch((error) => {
    //                 if (error.response && error.response.data === "Duplicate Entry") {
    //                     const err = {};
    //                     err.duplicateClientData = "Provided client details already exist.";
    //                     setFormError(err);
    //                 }
    //             });
    //     } else {
    //         const err = {};
    //         if (!orgUrl) {
    //             err.orgUrl = "Organisational URL is mandatory";
    //         }
    //         if (!powerbiClientId) {
    //             err.powerbiClientId = "App Client ID is mandatory";
    //         }
    //         if (!powerbiClientSecret) {
    //             err.powerbiClientSecret = "App Client Secret is mandatory";
    //         }
    //         if (!powerbiTenantId) {
    //             err.powerbiTenantId = "Tenant ID is mandatory";
    //         }
    //         setFormError(err);
    //     }
    // }

    // function saveTableauCredentials() {
    //     if (orgUrl && tableauClientId && tableauSecretId && tableauSecretValue && tableauUsername) {
    //         // Remove error messages
    //         setFormError({});

    //         const axiosRequest = saveTableauUpdate || isConfiguring
    //             ? axios.post(`${baseURL}/saveTableauClientConfiguration`, {
    //                 appClientId: tableauClientId,
    //                 appSecretId: tableauSecretId,
    //                 appSecretValue: tableauSecretValue,
    //                 adminUsername: tableauUsername,
    //             })
    //             : axios.put(`${baseURL}/updateTableauClientConfiguration`, {
    //                 appClientId: tableauClientId,
    //                 appSecretId: tableauSecretId,
    //                 appSecretValue: tableauSecretValue,
    //                 adminUsername: tableauUsername,
    //             });

    //         axiosRequest
    //             .then(function (response) {
    //                 setEditMode(false);
    //                 setIsConfiguring(false);
    //                 setSaveTableauUpdate(false);
    //                 setTableauClientIdVisibility(false);
    //                 setTableauSecretIdVisibility(false);
    //                 setTableauSecretValueVisibility(false);
    //                 setAdminUsernameVisibility(false);

    //                 axios.post(`${baseURL}/logAdminEvent`, {
    //                     eventType: "Tableau client secrets saved",
    //                     info: "",
    //                 }).then((res) => { });

    //                 // Optionally handle the response message
    //                 // setSelectedValue(response?.data?.message);
    //                 // handleClickOpen();
    //             })
    //             .catch((error) => {
    //                 if (error.response.data === "Duplicate Entry") {
    //                     const err = {};
    //                     err.duplicateClientData = "Provided client details already exist.";
    //                     setFormError(err);
    //                 }
    //             });
    //     } else {
    //         const err = {};
    //         if (!orgUrl) {
    //             err.orgUrl = "Organisational URL is mandatory";
    //         }
    //         if (!tableauClientId) {
    //             err.tableauClientId = "App Client ID is mandatory";
    //         }
    //         if (!tableauSecretId) {
    //             err.tableauSecretId = "App Secret ID is mandatory";
    //         }
    //         if (!tableauSecretValue) {
    //             err.tableauSecretValue = "App Secret Value is mandatory";
    //         }
    //         if (!tableauUsername) {
    //             err.tableauUsername = "Username is mandatory";
    //         }
    //         setFormError(err);
    //     }
    // }

    function saveCredentials() {
        if (orgUrl && clientId && clientSecret) {
            // Remove error messages
            setFormError({});

            const isNewCredential = saveUpdate || isConfiguring;
            const axiosRequest = isNewCredential
                ? axios.post(`${baseURL}/saveClientConfiguration`, {
                    adminClientId: clientId,
                    adminClientSecret: clientSecret,
                })
                : axios.put(`${baseURL}/updateClientConfiguration`, {
                    adminClientId: clientId,
                    adminClientSecret: clientSecret,
                });

            axiosRequest
                .then(function (response) {
                    setEditMode(false);
                    setIsConfiguring(false);
                    setSaveUpdate(false);
                    setClientIdVisibility(false);
                    setClientSecretVisibility(false);

                    axios.post(`${baseURL}/logAdminEvent`, {
                        eventType: "client secrets saved",
                        info: "",
                    }).then((res) => { });

                    // Show success toast
                    toast.success(isNewCredential ? 'Credentials Added Successfully' : 'Credentials Updated Successfully');
                })
                .catch((error) => {
                    if (error.response && error.response.data === "Duplicate Entry") {
                        const err = {};
                        err.duplicateClientData = "Provided client details already exist.";
                        setFormError(err);
                    } else {
                        // Show error toast
                        toast.error("Something went Wrong, Please try again later");
                    }
                });
        } else {
            const err = {};
            if (!orgUrl) {
                err.orgUrl = "Organisational URL is mandatory";
            }
            if (!clientId) {
                err.clientId = "Client ID is mandatory";
            }
            if (!clientSecret) {
                err.clientSecret = "Client Secret is mandatory";
            }
            setFormError(err);
        }
    }

    function savePowerbiCredentials() {
        if (orgUrl && powerbiClientId && powerbiClientSecret && powerbiTenantId) {
            // Remove error messages
            setFormError({});

            const isNewCredential = savePowerbiUpdate || isConfiguring;
            const axiosRequest = isNewCredential
                ? axios.post(`${baseURL}/savePowerbiClientConfiguration`, {
                    adminClientId: powerbiClientId,
                    adminClientSecret: powerbiClientSecret,
                    adminTenantId: powerbiTenantId,
                })
                : axios.put(`${baseURL}/updatePowerbiClientConfiguration`, {
                    adminClientId: powerbiClientId,
                    adminClientSecret: powerbiClientSecret,
                    adminTenantId: powerbiTenantId,
                });

            axiosRequest
                .then(function (response) {
                    setEditMode(false);
                    setIsConfiguring(false);
                    setSavePowerbiUpdate(false);
                    setClientIdVisibility(false);
                    setClientSecretVisibility(false);

                    axios.post(`${baseURL}/logAdminEvent`, {
                        eventType: "PowerBi client secrets saved",
                        info: "",
                    }).then((res) => { });

                    // Show success toast
                    toast.success(isNewCredential ? 'Credentials Added Successfully' : 'Credentials Updated Successfully');
                })
                .catch((error) => {
                    if (error.response && error.response.data === "Duplicate Entry") {
                        const err = {};
                        err.duplicateClientData = "Provided client details already exist.";
                        setFormError(err);
                    } else {
                        // Show error toast
                        toast.error("Something went Wrong, Please try again later");
                    }
                });
        } else {
            const err = {};
            if (!orgUrl) {
                err.orgUrl = "Organisational URL is mandatory";
            }
            if (!powerbiClientId) {
                err.powerbiClientId = "App Client ID is mandatory";
            }
            if (!powerbiClientSecret) {
                err.powerbiClientSecret = "App Client Secret is mandatory";
            }
            if (!powerbiTenantId) {
                err.powerbiTenantId = "Tenant ID is mandatory";
            }
            setFormError(err);
        }
    }

    function saveTableauCredentials() {
        if (orgUrl && tableauClientId && tableauSecretId && tableauSecretValue && tableauUsername) {
            // Remove error messages
            setFormError({});

            const isNewCredential = saveTableauUpdate || isConfiguring;
            const axiosRequest = isNewCredential
                ? axios.post(`${baseURL}/saveTableauClientConfiguration`, {
                    appClientId: tableauClientId,
                    appSecretId: tableauSecretId,
                    appSecretValue: tableauSecretValue,
                    adminUsername: tableauUsername,
                })
                : axios.put(`${baseURL}/updateTableauClientConfiguration`, {
                    appClientId: tableauClientId,
                    appSecretId: tableauSecretId,
                    appSecretValue: tableauSecretValue,
                    adminUsername: tableauUsername,
                });

            axiosRequest
                .then(function (response) {
                    setEditMode(false);
                    setIsConfiguring(false);
                    setSaveTableauUpdate(false);
                    setTableauClientIdVisibility(false);
                    setTableauSecretIdVisibility(false);
                    setTableauSecretValueVisibility(false);
                    setAdminUsernameVisibility(false);

                    axios.post(`${baseURL}/logAdminEvent`, {
                        eventType: "Tableau client secrets saved",
                        info: "",
                    }).then((res) => { });

                    // Show success toast
                    toast.success(isNewCredential ? 'Credentials Added Successfully' : 'Credentials Updated Successfully');
                })
                .catch((error) => {
                    if (error.response && error.response.data === "Duplicate Entry") {
                        const err = {};
                        err.duplicateClientData = "Provided client details already exist.";
                        setFormError(err);
                    } else {
                        // Show error toast
                        toast.error("Something went Wrong, Please try again later");
                    }
                });
        } else {
            const err = {};
            if (!orgUrl) {
                err.orgUrl = "Organisational URL is mandatory";
            }
            if (!tableauClientId) {
                err.tableauClientId = "App Client ID is mandatory";
            }
            if (!tableauSecretId) {
                err.tableauSecretId = "App Secret ID is mandatory";
            }
            if (!tableauSecretValue) {
                err.tableauSecretValue = "App Secret Value is mandatory";
            }
            if (!tableauUsername) {
                err.tableauUsername = "Username is mandatory";
            }
            setFormError(err);
        }
    }


    // const handleToggleVisibility = (event) => {
    //     const spanId = event.target.id;
    //     switch (spanId) {
    //         case "clientIdVisibility":
    //             setClientIdVisibility(!clientIdVisibility);
    //             break;
    //         case "clientSecretVisibility":
    //             setClientSecretVisibility(!clientSecretVisibility);
    //             break;
    //         case "powerbiClientIdVisibility":
    //             setPowerbiClientIdVisibility(!powerbiClientIdVisibility);
    //             break;
    //         case "powerbiClientSecretVisibility":
    //             setPowerbiClientSecretVisibility(!powerbiClientSecretVisibility);
    //             break;
    //         case "tenantIdVisibility":
    //             setTenantIdVisibility(!tenantIdVisibility);
    //             break;
    //         case "tableauSecretIdVisibility":
    //             setTableauSecretIdVisibility(!tableauSecretIdVisibility);
    //             break;
    //         case "tableauClientIdVisibility":
    //             setTableauClientIdVisibility(!tableauClientIdVisibility);
    //             break;
    //         case "tableauSecretValueVisibility":
    //             setTableauSecretValueVisibility(!tableauSecretValueVisibility);
    //             break;
    //         case "adminUsernameVisibility":
    //             setAdminUsernameVisibility(!adminUsernameVisibility);
    //             break;
    //         default:
    //             console.warn('Unknown id:', spanId);
    //             break;
    //     }
    // };


    const handleToggleVisibility = (event) => {
        const button = event.currentTarget;
        const visibilityType = button.dataset.visibilityType;

        switch (visibilityType) {
            case "clientId":
                setClientIdVisibility(prev => !prev);
                break;
            case "clientSecret":
                setClientSecretVisibility(prev => !prev);
                break;
            case "powerbiClientId":
                setPowerbiClientIdVisibility(prev => !prev);
                break;
            case "powerbiClientSecret":
                setPowerbiClientSecretVisibility(prev => !prev);
                break;
            case "tenantId":
                setTenantIdVisibility(prev => !prev);
                break;
            case "tableauSecretId":
                setTableauSecretIdVisibility(prev => !prev);
                break;
            case "tableauClientId":
                setTableauClientIdVisibility(prev => !prev);
                break;
            case "tableauSecretValue":
                setTableauSecretValueVisibility(prev => !prev);
                break;
            case "adminUsername":
                setAdminUsernameVisibility(prev => !prev);
                break;
            default:
                console.warn('Unknown visibility type:', visibilityType);
                break;
        }
    };



    // -------------NEW VERSION FUNCTIONS AND STATES-------------------------





    return (
        <>
            <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
                Configure Details
            </label>


            <div className="mt-8 flex justify-between items-center border-b border-gray-200">
                <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
                    {hasDomo && (
                        <button
                            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'Domo'
                                ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                                : 'text-gray-700 border-b-2 border-transparent'
                                } bg-transparent sm:px-6 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                            onClick={() => handleTabClick('Domo')}
                        >
                            <img src={Domo} alt='Domo' className="w-4 h-4 mx-1 sm:w-6 sm:h-6" />
                            <span className="mx-1 text-sm sm:text-base font-medium">
                                DOMO
                            </span>
                        </button>
                    )}
                    {hasPowerBI && (
                        <button
                            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'PowerBi'
                                ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                                : 'text-gray-700 border-b-2 border-transparent'
                                } bg-transparent sm:px-6 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                            onClick={() => handleTabClick('PowerBi')}
                        >
                            <img src={PowerBi} alt='PowerBi' className="w-4 h-4 mx-1 sm:w-6 sm:h-6" />
                            <span className="mx-1 text-sm sm:text-base font-medium">
                                Power BI
                            </span>
                        </button>
                    )}

                    {hasTableau && (
                        <button
                            className={`inline-flex items-center h-10 px-4 py-2 -mb-px text-center ${activeTab === 'Tableau'
                                ? `text-${selectedColor}-600 border-b-2 border-${selectedColor}-500`
                                : 'text-gray-700 border-b-2 border-transparent'
                                } bg-transparent sm:px-6 whitespace-nowrap focus:outline-none hover:border-gray-400`}
                            onClick={() => handleTabClick('Tableau')}
                        >
                            <img src={tableau} alt='Tableau' className="w-4 h-4 mx-1 sm:w-6 sm:h-6" />
                            <span className="mx-1 text-sm sm:text-base font-medium">
                                Tableau
                            </span>
                        </button>
                    )}


                </div>

                <button
                    type="button"
                    className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-2 py-2 mb-1 leading-5 font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                    onClick={() => setEditMode(true)}
                >
                    <FiEdit className="w-4 h-4 mx-1" />
                    <span className="text-sm">Edit Credentials</span>
                </button>
            </div>


            <div className='mt-8'>

              

                {activeTab === 'PowerBi' && (
                    (editMode || isConfiguring || (powerbiClientId && powerbiClientSecret && powerbiTenantId)) ? (
                        <div className="bg-white rounded-2xl shadow-lg p-6">
                            <form className="space-y-10">

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="powerbiClientId" className="w-36 text-sm font-medium text-gray-700">
                                        App Client ID <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (powerbiClientIdVisibility ? "text" : "password")}
                                            id="powerbiClientId"
                                            readOnly={!editMode && !isConfiguring}
                                            value={powerbiClientId}
                                            onChange={handlePowerbiCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="powerbiClientId"
                                            >
                                                {powerbiClientIdVisibility ? (
                                                    <HiOutlineEyeOff className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="powerbiClientSecret" className="w-36 text-sm font-medium text-gray-700">
                                        App Client Secret <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (powerbiClientSecretVisibility ? "text" : "password")}
                                            id="powerbiClientSecret"
                                            readOnly={!editMode && !isConfiguring}
                                            value={powerbiClientSecret}
                                            onChange={handlePowerbiCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="powerbiClientSecret"
                                            >
                                                {powerbiClientSecretVisibility ? (
                                                    <HiOutlineEyeOff id='powerbiClientSecretVisibility' className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id='powerbiClientSecretVisibility' className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="powerbiTenantId" className="w-36 text-sm font-medium text-gray-700">
                                        Tenant ID <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (tenantIdVisibility ? "text" : "password")}
                                            id="powerbiTenantId"
                                            readOnly={!editMode && !isConfiguring}
                                            value={powerbiTenantId}
                                            onChange={handlePowerbiCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="tenantId"

                                            >
                                                {tenantIdVisibility ? (
                                                    <HiOutlineEyeOff id='tenantIdVisibility' className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id='tenantIdVisibility' className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <p className="text-sm text-red-600">{formError.duplicateClientData}</p>

                                {(editMode || isConfiguring) && (
                                    <div className="mt-4 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                            onClick={() => {
                                                setEditMode(false);
                                                setIsConfiguring(false);
                                                setFormError({});
                                                getPowerBiClientInfoById();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                            onClick={savePowerbiCredentials}
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                    ) : (
                        <div className='bg-white rounded-2xl shadow-lg py-6 px-8'>
                            <div>
                                <img src={ConfigureWrench} alt='wrench' className='w-16 h-16' />
                            </div>
                            <h2 className="mt-5 text-lg text-gray-900 capitalize font-medium">
                                Configure Power BI credentials to get started
                            </h2>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex flex-col text-gray-600 text-sm gap-2'>
                                    <p>Get started by configuring the tool.</p>
                                    <p>This will allow you to fully utilize all the features of the dashboard.</p>
                                </div>
                                <button
                                    type="button"
                                    className={`inline-flex truncate justify-center items-center rounded-md bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                    onClick={() => {
                                        setEditMode(true);
                                        setIsConfiguring(true);
                                        setClientId('');
                                        setClientSecret('');
                                    }}
                                >
                                    <GrConfigure className="w-4 h-4 mr-2" />
                                    <span className="text-sm">Configure</span>
                                </button>
                            </div>
                        </div>
                    )
                )}

                {activeTab === 'Tableau' && (
                    (editMode || isConfiguring || (tableauClientId && tableauSecretId && tableauSecretValue && tableauUsername)) ? (
                        <div className="bg-white rounded-2xl shadow-lg p-6">
                            <form className="space-y-10">

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="tableauClientId" className="w-36 text-sm font-medium text-gray-700">
                                        Client ID <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (tableauClientIdVisibility ? "text" : "password")}
                                            id="tableauClientId"
                                            readOnly={!editMode && !isConfiguring}
                                            value={tableauClientId}
                                            onChange={handleTableauCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="tableauClientId"

                                            >
                                                {tableauClientIdVisibility ? (
                                                    <HiOutlineEyeOff id="tableauClientIdVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="tableauClientIdVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.tableauClientId}</p>
                                </div>
                                <div className="flex items-center space-x-10">
                                    <label htmlFor="tableauSecretId" className="w-36 text-sm font-medium text-gray-700">
                                        Secret ID <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (tableauSecretIdVisibility ? "text" : "password")}
                                            id="tableauSecretId"
                                            readOnly={!editMode && !isConfiguring}
                                            value={tableauSecretId}
                                            onChange={handleTableauCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="tableauSecretId"

                                            >
                                                {tableauSecretIdVisibility ? (
                                                    <HiOutlineEyeOff id="tableauSecretIdVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="tableauSecretIdVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.tableauSecretId}</p>
                                </div>
                                <div className="flex items-center space-x-10">
                                    <label htmlFor="tableauSecretValue" className="w-36 text-sm font-medium text-gray-700">
                                        Secret Value <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (tableauSecretValueVisibility ? "text" : "password")}
                                            id="tableauSecretValue"
                                            readOnly={!editMode && !isConfiguring}
                                            value={tableauSecretValue}
                                            onChange={handleTableauCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="tableauSecretValue"

                                            >
                                                {tableauSecretValueVisibility ? (
                                                    <HiOutlineEyeOff id="tableauSecretValueVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="tableauSecretValueVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.tableauSecretValue}</p>
                                </div>
                                <div className="flex items-center space-x-10">
                                    <label htmlFor="tableauUsername" className="w-36 text-sm font-medium text-gray-700">
                                        Tableau Username <span className='text-red-600'>*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (adminUsernameVisibility ? "text" : "password")}
                                            id="tableauUsername"
                                            readOnly={!editMode && !isConfiguring}
                                            value={tableauUsername}
                                            onChange={handleTableauCredentialsChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="adminUsername"

                                            >
                                                {adminUsernameVisibility ? (
                                                    <HiOutlineEyeOff id="adminUsernameVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="adminUsernameVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.tableauUsername}</p>
                                </div>

                                <p className="text-sm text-red-600">{formError.duplicateClientData}</p>
                                {/* {(editMode || isConfiguring) && (
                                    <div className="mt-4 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                            onClick={() => {
                                                setEditMode(false);
                                                setIsConfiguring(false);
                                                setFormError({});
                                                getTableauClientInfoById();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                            onClick={saveTableauCredentials}
                                        >
                                            Save
                                        </button>
                                    </div>
                                )} */}

                                {(editMode || isConfiguring) && (
                                    <div className="mt-4 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                            onClick={() => {
                                                setEditMode(false);
                                                setIsConfiguring(false);
                                                setFormError({});
                                                getTableauClientInfoById();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                            onClick={saveTableauCredentials}
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                    ) : (
            
                        <div className='bg-white rounded-2xl shadow-lg py-6 px-8'>
                            <div>
                                <img src={ConfigureWrench} alt='wrench' className='w-16 h-16' />
                            </div>
                            <h2 className="mt-5 text-lg text-gray-900 capitalize font-medium">
                                Configure Tableau credentials to get started
                            </h2>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex flex-col text-gray-600 text-sm gap-2'>
                                    <p>Get started by configuring the tool.</p>
                                    <p>This will allow you to fully utilize all the features of the dashboard.</p>
                                </div>
                                <button
                                    type="button"
                                    className={`inline-flex truncate justify-center items-center rounded-md bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                    onClick={() => {
                                        setEditMode(true);
                                        setIsConfiguring(true);
                                        setTableauClientId('');
                                        setTableauSecretId('');
                                        setTableauSecretValue('');
                                        setTableauUsername('');
                                    }}
                                >
                                    <GrConfigure className="w-4 h-4 mr-2" />
                                    <span className="text-sm">Configure</span>
                                </button>
                            </div>
                        </div>
                    )
                )}

                {activeTab === 'Domo' && (
                    (clientId && clientSecret) || isConfiguring ? (
                        <div className="bg-white rounded-2xl shadow-lg p-6">
                            <form className="space-y-10">
                                <div className="flex items-center space-x-10">
                                    <label htmlFor="clientId" className="w-36 text-sm font-medium text-gray-700">
                                        Client ID <span className="text-red-600">*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (clientIdVisibility ? "text" : "password")}
                                            id="clientId"
                                            readOnly={!editMode && !isConfiguring}
                                            value={clientId}
                                            onChange={(e) => {
                                                handleClientIdChange(e);
                                                if (e.target.value === '') {
                                                    setEditMode(true);
                                                    setIsConfiguring(true);
                                                }
                                            }}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="clientId"

                                            >
                                                {clientIdVisibility ? (
                                                    <HiOutlineEyeOff id="clientIdVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="clientIdVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.clientId}</p>
                                </div>

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="clientSecret" className="w-36 text-sm font-medium text-gray-700">
                                        Client Secret <span className="text-red-600">*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (clientSecretVisibility ? "text" : "password")}
                                            id="clientSecret"
                                            readOnly={!editMode && !isConfiguring}
                                            value={clientSecret}
                                            onChange={(e) => {
                                                handleClientSecretChange(e);
                                                if (e.target.value === '') {
                                                    setEditMode(true);
                                                    setIsConfiguring(true);
                                                }
                                            }}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                                data-visibility-type="clientSecret"

                                            >
                                                {clientSecretVisibility ? (
                                                    <HiOutlineEyeOff id="clientSecretVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="clientSecretVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.clientSecret}</p>
                                </div>

                                <p className="text-sm text-red-600">{formError.duplicateClientData}</p>
                                {(editMode || isConfiguring) && (
                                    <div className="mt-4 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                            onClick={() => {
                                                setEditMode(false);
                                                setIsConfiguring(false);
                                                setFormError({});
                                                getClientInfoById();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                            onClick={saveCredentials}
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                    ) : (
                        <div className='bg-white rounded-2xl shadow-lg py-6 px-8'>
                            <div>
                                <img src={ConfigureWrench} alt='wrench' className='w-16 h-16' />
                            </div>
                            <h2 className="mt-5 text-lg text-gray-900 capitalize font-medium">
                                Configure DOMO credentials to get started
                            </h2>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex flex-col text-gray-600 text-sm gap-2'>
                                    <p>Get started by configuring the tool.</p>
                                    <p>This will allow you to fully utilize all the features of the dashboard.</p>
                                </div>
                                <button
                                    type="button"
                                    className={`inline-flex truncate justify-center items-center rounded-md bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                    onClick={() => {
                                        setEditMode(true);
                                        setIsConfiguring(true);
                                        setClientId('');
                                        setClientSecret('');
                                    }}
                                >
                                    <GrConfigure className="w-4 h-4 mr-2" />
                                    <span className="text-sm">Configure</span>
                                </button>
                            </div>
                        </div>
                    )
                )}


                {/* {activeTab === 'Domo' && (
                    (clientId && clientSecret) || isConfiguring ? (
                        <div className="bg-white rounded-2xl shadow-lg p-6">
                            <form className="space-y-10">

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="clientId" className="w-36 text-sm font-medium text-gray-700">
                                        Client ID <span className="text-red-600">*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (clientIdVisibility ? "text" : "password")}
                                            id="clientId"
                                            readOnly={!editMode && !isConfiguring}
                                            value={clientId}
                                            onChange={handleClientIdChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                            >
                                                {clientIdVisibility ? (
                                                    <HiOutlineEyeOff id="clientIdVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="clientIdVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.clientId}</p>
                                </div>

                                <div className="flex items-center space-x-10">
                                    <label htmlFor="clientSecret" className="w-36 text-sm font-medium text-gray-700">
                                        Client Secret <span className="text-red-600">*</span>
                                    </label>
                                    <div className="flex-grow relative">
                                        <input
                                            type={editMode || isConfiguring ? "text" : (clientSecretVisibility ? "text" : "password")}
                                            id="clientSecret"
                                            readOnly={!editMode && !isConfiguring}
                                            value={clientSecret}
                                            onChange={handleClientSecretChange}
                                            className={editMode || isConfiguring
                                                ? `block mt-2 w-full placeholder-gray-400/70 rounded-sm bg-${selectedColor}-50 px-5 py-2.5 text-gray-700 focus:border-${selectedColor}-400 focus:outline-none focus:ring focus:ring-${selectedColor}-300 focus:ring-opacity-40 `
                                                : `shadow-sm focus:ring-${selectedColor}-500 focus:border-${selectedColor}-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md`}
                                        />
                                        {!editMode && !isConfiguring && (
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleToggleVisibility}
                                            >
                                                {clientSecretVisibility ? (
                                                    <HiOutlineEyeOff id="clientSecretVisibility" className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <HiOutlineEye id="clientSecretVisibility" className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    <p className="mt-1 text-sm text-red-600">{formError.clientSecret}</p>
                                </div>

                                <p className="text-sm text-red-600">{formError.duplicateClientData}</p>
                                {(editMode || isConfiguring) && (
                                    <div className="mt-4 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow"
                                            onClick={() => {
                                                setEditMode(false);
                                                setIsConfiguring(false);
                                                setFormError({});
                                                getClientInfoById();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                                            onClick={saveCredentials}
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                    ) : (
                        <div className='bg-white rounded-2xl shadow-lg py-6 px-8'>
                            <div>
                                <img src={ConfigureWrench} alt='wrench' className='w-16 h-16' />
                            </div>
                            <h2 className="mt-5 text-lg text-gray-900 capitalize font-medium">
                                Configure DOMO credentials to get started
                            </h2>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex flex-col text-gray-600 text-sm gap-2'>
                                    <p>Get started by configuring the tool.</p>
                                    <p>This will allow you to fully utilize all the features of the dashboard.</p>
                                </div>
                                <button
                                    type="button"
                                    className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    onClick={() => {
                                        setEditMode(true);
                                        setIsConfiguring(true);
                                        setClientId('');
                                        setClientSecret('');
                                    }}
                                >
                                    <GrConfigure className="w-4 h-4 mr-2" />
                                    <span className="text-sm">Configure</span>
                                </button>
                            </div>
                        </div>
                    )
                )} */}




            </div>
        </>
    );
}

export default Configure