import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { baseURL } from "./constants.js";
import toast, { Toaster } from 'react-hot-toast';

const ThemeContext = createContext();

export const useTheme = () => {
    return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
    const [selectedColor, setSelectedColor] = useState(null);
    const [previewColor, setPreviewColor] = useState(null);

    // useEffect(() => {
    //     const fetchSavedTheme = async () => {
    //         try {
    //             const token = localStorage.getItem("auth");
    //             const decoded = jwt_decode(token);
    //             const org_id = decoded.organisationId;
    //             console.log('hello')
    //             console.log("org_id", org_id)
    //             const response = await axios.get(`${baseURL}/getColorTheme/${org_id}`);
    //             console.log('response', response)
    //             const savedTheme = response.data.colorTheme;
    //             setSelectedColor(savedTheme);
    //             setPreviewColor(savedTheme);
    //         } catch (error) {
    //             console.log("Error fetching saved theme:", error);
    //             toast.error('Failed to fetch the saved theme.');
    //         }
    //     };

    //     fetchSavedTheme();
    // }, []);


    useEffect(() => {
        const fetchSavedTheme = async () => {
            try {
                const token = localStorage.getItem("auth");
                if (!token) {
                    // console.log("No auth token found in localStorage.");
                    setSelectedColor('blue');
                    setPreviewColor('blue');
                    return;
                }
    
                const decoded = jwt_decode(token);
                const org_id = decoded?.organisationId;
    
                if (!org_id) {
                    // console.log("No organisationId found in the decoded token.");
                    setSelectedColor('blue');
                    setPreviewColor('blue');
                    return;
                }
    
                const response = await axios.get(`${baseURL}/getColorTheme/${org_id}`);
                const savedTheme = response.data?.colorTheme;
    
                if (savedTheme) {
                    // console.log("Saved theme found:", savedTheme);
                    setSelectedColor(savedTheme);
                    setPreviewColor(savedTheme);
                } else {
                    // console.log("No saved theme found, defaulting to 'blue'.");
                    setSelectedColor('blue');
                    setPreviewColor('blue');
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // console.log("Theme not found, defaulting to 'blue'.");
                    setSelectedColor('blue');
                    setPreviewColor('blue');
                } else {
                    console.error("Error fetching saved theme:", error);
                    toast.error('Failed to fetch the saved theme.');
                }
            }
        };
    
        fetchSavedTheme();
    }, []);
    

    return (
        <ThemeContext.Provider value={{ selectedColor, setSelectedColor, previewColor, setPreviewColor }}>
            {children}
        </ThemeContext.Provider>
    );
};
