import { React, useEffect, useState } from 'react';
import LoginBg from '../../assets/loginBg.png';
import CompanyLogo from '../../assets/companyLogo.png';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { MdOutlineErrorOutline } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from '../../constants';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import Bg from '../../assets/Diacto-Logo.jpg'

const Login = () => {
    const [showError, setShowError] = useState(false);
    const [directToLanding, setDirectToLanding] = useState(false);
    let navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["auth"]);

    const [companyName, setCompanyName] = useState("");
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [formError, setFormError] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleChangeUsername = (event) => {
        setUsername(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = () => {
        const auth = localStorage.getItem("auth");

        if (auth) {
            const decoded = jwt_decode(auth);
            if (decoded) {
                if (decoded.role === 0) {
                    setTimeout(() => {
                        navigate("/dashboard/orgs");
                    }, 200);
                } else if (decoded.role === 1) {
                    setTimeout(() => {
                        navigate("/dashboard/settings/configure");
                    }, 200);
                } else if (decoded.role === 2) {
                    setTimeout(() => {
                        navigate("/dashboard/user-dashboard");
                    }, 200);
                }
            }
        }
    };

    useEffect(() => {
        handleLogin()
    })
    const logIn = (event) => {
        event.preventDefault();

        if (userName && password) {
            axios
                .post(`${baseURL}/login`, {
                    companyName: userName == "r.h@d.com" ? "diacto-support" : "diacto",
                    workEmail: userName,
                    password: password,
                })
                .then(function (response) {
                    if (response.data === "User not found" || response.data === "Password not set yet" || response.data === "Invalid Credentials") {
                        const err = {};
                        err.userNotFound = response.data;
                        setFormError(err);
                        setShowError(true);
                        setDirectToLanding(false);
                    } else {
                        setFormError({});
                        setShowError(false);
                        setDirectToLanding(true);
                        setCookie("auth", response.data, { path: "/" });
                        const decoded = jwt_decode(response.data);

                        localStorage.setItem("auth", response.data);
                        axios.post(`${baseURL}/logEvent`, {
                            eventType: "Login",
                            dashboardId: null,
                            userId: decoded.adminId,
                        });

                        const redirectTimeout = 500;
                        if (decoded.role === 0) {
                            setTimeout(() => {
                                navigate("/dashboard/orgs");
                            }, redirectTimeout);
                        } else if (decoded.role === 1) {
                            setTimeout(() => {
                                navigate("/dashboard/settings/configure");
                            }, redirectTimeout);
                        } else if (decoded.role === 2) {
                            setTimeout(() => {
                                navigate("/dashboard/user-dashboard");
                            }, redirectTimeout);
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } else {
            const err = {};
            // if (!companyName) {
            //     err.companyName = "Organisation is mandatory";
            // }
            if (!userName) {
                err.userName = "Username is mandatory";
            }
            if (!password) {
                err.password = "Password is mandatory";
            }
            setFormError(err);
        }
    };

    return (
        <>
            <div>
                <div className="flex justify-center h-screen bg-gray-50">
                    <div className="w-1/2 h-full bg-cover lg:block bg-white">
                        <img className="object-contain w-6/12 2xl:w-6/12 h-full bg-opacity-30 mx-auto" src={Bg} alt="Side" />
                    </div>

                    <div className="w-2/3 flex items-center max-w-lg mx-auto lg:w-2/3 h-max my-auto p-12" style={{ width: '64rem' }}>
                        <div className="flex-1">
                            <div className="text-center">
                                {/* <div className="flex justify-center mx-auto">
                                    <img className="w-auto h-10" src={CompanyLogo} alt="Company Logo" />
                                </div> */}

                                <div className="text-left mt-4">
                                    <h2 className="text-3xl font-bold text-left text-gray-700">Log In</h2>
                                    <p className="mt-2 text-gray-500">Enter your email, and password to log in!</p>
                                </div>
                                {formError.userNotFound && (
                                    <div className="flex justify-center mt-4">
                                        <div className="w-3/5 px-3 py-2 tracking-wide text-red-600 transition-colors duration-200 transform bg-red-100 border border-red-200 rounded-sm focus:ring focus:ring-red-200 focus:ring-opacity-50">
                                            <div className="flex items-center justify-center">
                                                <MdOutlineErrorOutline className="mr-1" />
                                                <span className="text-sm text-center">{formError.userNotFound}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="mt-6">
                                <form onSubmit={logIn}>
                                  {/* <div>
                                        <label htmlFor="companyName" className="block mb-2 text-sm text-gray-600">Organization URL <span className="text-red-600">*</span></label>
                                        <input type="text" name="companyName" id="companyName" value={companyName} onChange={handleChangeCompanyName} placeholder="Companyname.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        {formError.companyName && (
                                            <div className="flex items-center mt-1 text-red-500">
                                                <MdOutlineErrorOutline className="mr-1" />
                                                <span className="text-sm">{formError.companyName}</span>
                                            </div>
                                        )}
                                    </div> */}

                                    <div className="mt-4">
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-600">Email <span className="text-red-600">*</span></label>
                                        <input type="email" name="email" id="email" value={userName} onChange={handleChangeUsername} placeholder="Enter email" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        {formError.userName && (
                                            <div className="flex items-center mt-1 text-red-500">
                                                <MdOutlineErrorOutline className="mr-1" />
                                                <span className="text-sm">{formError.userName}</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex justify-between">
                                            <label htmlFor="password" className="block text-sm text-gray-600">Password <span className="text-red-600">*</span></label>
                                        </div>

                                        <div className="relative">
                                            <input type={passwordVisible ? 'text' : 'password'} name="password" id="password" value={password} onChange={handleChangePassword} placeholder="Enter password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                            <button type="button" onClick={togglePasswordVisibility} className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-600">
                                                {passwordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                                            </button>
                                        </div>
                                        {formError.password && (
                                            <div className="flex items-center mt-1 text-red-500">
                                                <MdOutlineErrorOutline className="mr-1" />
                                                <span className="text-sm">{formError.password}</span>
                                            </div>
                                        )}

                                        <div className="flex justify-end mt-2">
                                            <Link to="/reset-password" className="text-sm text-gray-400 focus:outline-none hover:text-red-500">Forgot password?</Link>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button type="submit" className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Log in
                                        </button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
