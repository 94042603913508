import { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
import "./ManageGroups.css";
import axios from "axios";
import Select from "react-select";
import { useCookies } from "react-cookie";
import { createRoot } from "react-dom/client";
import { IoIosAdd } from "react-icons/io";
import { IoSearchOutline } from 'react-icons/io5';
import { useDebounce } from 'use-debounce';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { baseURL } from "../../constants.js";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTheme } from '../../ThemeContext.js'; // Import useTheme

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: `#f1f5f9`
    , // Optional: Change column headers background color
    borderBottom: '1px solid #dbeafe', // Added border bottom
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: `#f1f5f9`
    , // Tailwind CSS bg-grey-50 equivalent
  },

});


const ManageGroups = () => {
  const { selectedColor } = useTheme();

  const navigate = useNavigate()
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [isDynamicGroup, setIsDynamicGroup] = useState(false);
  const [isClosedGroup, setIsClosedGroup] = useState(false);
  const [users, setUsers] = useState([]);
  const [formError, setFormError] = useState({});
  const [groups, setGroups] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteGroupObj, setDeleteGroupObj] = useState([]);
  // const [filteredGroup, setFilteredGroup] = useState([...groups]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({
    label: "Search Groups",
    value: "",
  });
  const groupData = {
    groupName: "",
    groupDescription: "",
    groupType: "",
    targetColumn: "",
    usersToSend: [],
  };

  const [department, setDepartment] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [location, setLocation] = useState([]);



  /**/ const [MemberCriteriaList, setMemberCriteriaList] = useState([
    ...department,
  ]);
  /**/ const [selectedValueList, setSelectedValueList] = useState([]);

  let history = useNavigate();
  const [addGroupData, setAddGroupData] = useState({ ...groupData });


  useEffect(() => {
    getAllGroups();
  }, []);

  useEffect(() => {
    setFilteredGroup(groups);

  }, [groups]);

  const handleGroupModalClose = () => {
    setShowGroupModal(false);
    setAddGroupData({ ...groupData });
    setIsDynamicGroup(false);
    setIsClosedGroup(false);
    setFormError({});
  };


  const handleShowModal = (modalId) => {
    //console.log("run")
    setShowModal(modalId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getAllDynamicValuesForGroup = () => {
    const departmentValues = [];
    const locationValues = [];
    const jobTitleValues = [];
    axios.get(`${baseURL}/getAllDynamicValuesForDynamicGroup`).then((res) => {
      //console.log(res);
      const values = res.data.values;
      values.forEach((v) => {
        if (v.department) {
          departmentValues.push({ value: v.department, label: v.department });
        }
        if (v.location) {
          locationValues.push({ value: v.location, label: v.location });
        }
        if (v.job_title) {
          jobTitleValues.push({ value: v.job_title, label: v.job_title });
        }
      });
      setDepartment(departmentValues);
      setLocation(locationValues);
      setJobTitle(jobTitleValues);
    });
  };


  const handleGroupFilter = (selectedOption) => {
    const selected = { ...selectedGroup };
    //console.log("SELECTED", selected);
    //console.log(selectedOption);
    if (selectedOption) {
      const found = groups.find((group) => group.id === selectedOption.value);
      setFilteredGroup([found]);
      selected.value = selectedOption.value;
      selected.label = selectedOption.label;
    } else {
      selected.value = "";
      selected.label = "Search Groups";
      setFilteredGroup(groups);
    }
    setSelectedGroup(selected);
  };

  const getAllUsers = () => {
    axios
      .get(`${baseURL}/getAllUsersByOrgId`)
      .then(function (response) {
        // console.log(response.data);
        const newUsers = response.data.users.map((element) => ({
          value: element.id,
          label: element.first_name + " " + element.last_name,
        }));
        setUsers(newUsers);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };


  const getAllGroups = () => {
    axios.get(baseURL + `/getAllGroupsByOrgId`).then((response) => {
      setGroups(response.data.groups);
      setFilteredGroup([...groups]);
      const groupOptions = response.data?.groups.map((group) => ({
        value: group.id,
        label: group.name,
      }));
      setGroupList(groupOptions);
      //console.log(response.data.groups);
    });
  };

  const handleGroupDataChange = (e) => {
    const fieldName = e.target.id;
    const fieldValue = e.target.value;

    const newGroupData = { ...addGroupData };
    newGroupData[fieldName] = fieldValue;

    setAddGroupData(newGroupData);
    setSelectedValueList([]);

    if (fieldName === "targetColumn") {
      if (fieldValue === "Department") {
        setMemberCriteriaList(department);
      }
      if (fieldValue === "Job Title") {
        setMemberCriteriaList(jobTitle);
      }
      if (fieldValue === "Location") {
        setMemberCriteriaList(location);
      }
    }
  };

  useEffect(() => {
    getAllDynamicValuesForGroup();
    getAllUsers();
  }, []);

  const handleMemberCriteriaChange = (selectedOption) => {
    //console.log(selectedOption);
    setSelectedValueList(selectedOption);
  };

  const handleSelectedUsersChange = (e) => {
    addGroupData.usersToSend = [];
    addGroupData.usersToSend = e;
  };

  // const saveGroup = () => {
  //   if (addGroupData.groupName && addGroupData.groupType) {
  //     if (addGroupData.groupType == "ClosedGroup") {
  //       delete addGroupData.targetColumn;
  //       //console.log(addGroupData.usersToSend);
  //       axios
  //         .post(baseURL + `/saveGroup`, {
  //           name: addGroupData.groupName,
  //           description: addGroupData.groupDescription,
  //           type: addGroupData.groupType,
  //           users: addGroupData.usersToSend,
  //         })
  //         .then((response) => {
  //           if (response.data == "Record already exists") {
  //             alert("Group with same name already exists.");
  //           } else {
  //             getAllGroups();
  //             handleGroupModalClose();
  //           }
  //         });
  //     } else if (addGroupData.groupType == "DynamicGroup") {
  //       if (selectedValueList.length > 0) {
  //         delete addGroupData.users;
  //         axios
  //           .post(baseURL + `/saveGroup`, {
  //             name: addGroupData.groupName,
  //             description: addGroupData.groupDescription,
  //             type: addGroupData.groupType,
  //             dynamic_parameter: {
  //               [addGroupData.targetColumn]: selectedValueList.map(
  //                 (e) => e.value
  //               ),
  //             },
  //           })
  //           .then((response) => {
  //             if (response.data == "Record already exists") {
  //               alert("Group with same name already exists.");
  //             } else {
  //               getAllGroups();
  //               handleGroupModalClose();
  //             }
  //           });
  //       } else {
  //         const err = {};
  //         err.selectedValue = "Select a value";
  //         setFormError(err);
  //       }
  //     }
  //   } else {
  //     const err = {};
  //     if (!addGroupData.groupName) {
  //       err.groupName = "Group Name is mandatory";
  //     }
  //     if (!addGroupData.groupType) {
  //       err.groupType = "Please select Group Type";
  //     }
  //     setFormError(err);
  //   }
  // };

  const saveGroup = () => {
    // console.log("Saving group...");
    // console.log("Group Data:", addGroupData);
    // console.log("Selected Values:", selectedValueList);

    if (addGroupData.groupName && addGroupData.groupType) {
      if (addGroupData.groupType === "ClosedGroup") {
        delete addGroupData.targetColumn;
        axios
          .post(`${baseURL}/saveGroup`, {
            name: addGroupData.groupName,
            description: addGroupData.groupDescription,
            type: addGroupData.groupType,
            users: addGroupData.usersToSend,
          })
          .then((response) => {
            // console.log("Response:", response.data);
            if (response.data === "Record already exists") {
              toast.error("Group with same name already exists.");
            } else {
              toast.success("Group added successfully.");
              getAllGroups();
              handleGroupModalClose();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            toast.error("An error occurred while saving the group.");
          });
      } else if (addGroupData.groupType === "DynamicGroup") {
        if (selectedValueList.length > 0) {
          // console.log(addGroupData, 'addgroupdata')
          delete addGroupData.users;
          axios
            .post(`${baseURL}/saveGroup`, {
              name: addGroupData.groupName,
              description: addGroupData.groupDescription,
              type: addGroupData.groupType,
              dynamic_parameter: {
                [addGroupData.targetColumn]: selectedValueList.map(e => e.value),
              },
            })
            .then((response) => {
              // console.log("Response:", response.data);
              if (response.data === "Record already exists") {
                toast.error("Group with same name already exists.");
              } else {
                toast.success("Group added successfully.");
                getAllGroups();
                handleGroupModalClose();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              toast.error("Something went wrong. Please try again later.");
            });
        } else {
          const err = {};
          err.selectedValue = "Select a value";
          setFormError(err);
        }
      }
    } else {
      const err = {};
      if (!addGroupData.groupName) {
        err.groupName = "Group Name is mandatory";
      }
      if (!addGroupData.groupType) {
        err.groupType = "Please select Group Type";
      }
      setFormError(err);
    }
  };


  const deleteGroup = (groupId) => {
    axios.delete(`${baseURL}/deleteGroup/${groupId}`)
      .then((res) => {
        getAllGroups();
        setDeleteModalShow(false);
        setDeleteGroupObj({});
        toast.success('Group deleted successfully!');
      })
      .catch((error) => {
        toast.error('Failed to delete group. Please try again.');
      });
  };

  const handleDefaultDepartments = () => {
    // const rootElement = document.getElementById("targetColumn");
    // const root = createRoot(rootElement);
    // rootElement.change();
    setMemberCriteriaList(department);
  };

  const handleBulkDelete = (itemsToDelete) => {
    axios.post(`${baseURL}/deleteGroupsInBulk`, {
      idsToDelete: itemsToDelete
    }).then((res) => {
      // console.log("res", res.data.message);
      setGroupList([]);
      getAllGroups();
      handleCloseModal();
    });
  }

  const handleDeleteGroupClick = (id) => {
    const foundGroup = groups.find((group) => group.id === id);
    setDeleteGroupObj(foundGroup);
    setDeleteModalShow(true);
  };



  // -------------New States and Functions -------

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 300); // Reduced debounce time for responsiveness
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  // const [filteredGroup, setFilteredGroups] = useState([]);


  const [filteredGroup, setFilteredGroup] = useState([...groups]);

  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
    // console.log('Opening menu for row:', row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const editGroup = (groupID) => {
    navigate("/dashboard/settings/manage-groups/edit-groups/" + groupID);
  };

  // const handleGroupModalClose = () => setShowGroupModal(false);
  const handleGroupModalShow = () => setShowGroupModal(true);


  useEffect(() => {
    setFilteredGroup(
      groups.filter(group =>
        `${group.name}`.toLowerCase().includes(debouncedSearchText.toLowerCase())
      )
    );
  }, [debouncedSearchText, groups]);


  const columns = [
    { field: 'number', headerName: 'Id', width: 70, align: 'center', headerAlign: 'center' },
    { field: 'groupName', headerName: 'Group Name', flex: 1 },
    { field: 'groupOwner', headerName: 'Group Owner', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="Options"
            onClick={(event) => handleOpenMenu(event, params.row)}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && currentRow?.id === params.row.id}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => {
              editGroup(params.row.id);
              handleCloseMenu();
            }}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => {
              handleDeleteGroupClick(params.row.id)
              handleCloseMenu();
            }}>
              Delete
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const rows = filteredGroup.map((group, index) => ({
    id: group.id,
    number: index + 1,
    groupName: group.name,
    groupOwner: `${group.first_name} ${group.last_name}`,
  }));

  return (
    <>
      <label className="px-3 text-2xl text-gray-900 capitalize font-medium">
        Manage Groups
      </label>

      <div className="mt-3 flex justify-between items-center border-b border-gray-200">
        <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap">
          <div className="relative mt-3 mb-3 text-sm">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <IoSearchOutline className="w-5 h-5 text-gray-400" />
            </span>
            <input
              type="text"
              className="w-full py-1.5 pl-10 pr-4 text-gray-700 bg-gray-50 border rounded outline-none"
              placeholder="Search Groups"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <div className="flex space-x-5">

          <button
            type="button"
            className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-3 py-2 mb-1 text-xs font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
            onClick={handleGroupModalShow}// ---------- Add User Event Binding
          >
            <IoIosAdd className="w-5 h-5 mx-1" />
            <span className="text-sm">Add Group</span>
          </button>
        </div>
      </div>

      <div className="mt-6" style={{ height: 'auto', width: '100%' }}>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          rowHeight={45}
          columnHeaderHeight={50}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          autoHeight
          // checkboxSelection
          disableSelectionOnClick
          disableRowSelectionOnClick

        />
      </div>

      {showGroupModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-5/12 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Create Group</h3>
              <button className="text-gray-400 hover:text-gray-500" onClick={handleGroupModalClose}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              <form className="add-user-modal w-75 text-sm">
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <div>
                    <label className="text-gray-700" htmlFor="groupName">
                      Group Name <span className="text-red-500 font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      name="groupName"
                      onChange={handleGroupDataChange}
                      value={addGroupData.groupName}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter group name"
                      id="groupName"
                    />
                    <p className="text-red-500 text-xs mt-1">{formError.groupName}</p>
                  </div>
                  <div>
                    <label className="text-gray-700" htmlFor="groupDescription">
                      Group Description
                    </label>
                    <input
                      type="text"
                      name="groupDescription"
                      onChange={handleGroupDataChange}
                      value={addGroupData.groupDescription}
                      className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                      placeholder="Enter group description"
                      id="groupDescription"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label className="text-gray-700" htmlFor="groupType">
                    Type <span className="text-red-500 font-bold">*</span>
                  </label>
                  <div className="flex items-center mt-2">
                    <div className="mr-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="groupType"
                        onClick={(e) => {
                          setIsDynamicGroup(false);
                          setIsClosedGroup(true);
                          handleGroupDataChange(e);
                        }}
                        name="groupType"
                        value="ClosedGroup"
                      />
                      <label htmlFor="closedGroup" className="ml-2 text-gray-700">
                        Closed Group
                      </label>
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        id="groupType"
                        onClick={(e) => {
                          setIsDynamicGroup(true);
                          setIsClosedGroup(false);
                          handleGroupDataChange(e);
                          handleDefaultDepartments();
                        }}
                        type="radio"
                        name="groupType"
                        value="DynamicGroup"
                      />

                      <label htmlFor="dynamicGroup" className="ml-2 text-gray-700">
                        Dynamic Group
                      </label>
                    </div>
                  </div>
                  <p className="text-red-500 text-xs mt-1">{formError.groupType}</p>
                </div>
                {isClosedGroup && (
                  <div className="mt-4">
                    <label className="text-gray-700" htmlFor="usersSelected">
                      Select Users
                    </label>
                    <Select
                      defaultValue="Select Users"
                      id="usersSelected"
                      isMulti
                      onChange={handleSelectedUsersChange}
                      name="users"
                      options={users}
                      className="basic-multi-select shadow-none input-text"
                      classNamePrefix="select"
                    />
                  </div>
                )}
                {isDynamicGroup && (
                  <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
                    <div>
                      <label className="text-gray-700" htmlFor="targetColumn">
                        Field <span className="text-red-500 font-bold">*</span>
                      </label>
                      <select
                        id="targetColumn"
                        name="targetColumn"
                        className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded focus:border-${selectedColor}-400 focus:ring-${selectedColor}-300 focus:ring-opacity-40 focus:outline-none focus:ring`}
                        onChange={handleGroupDataChange}
                        value={addGroupData.targetColumn}
                      >
                        <option value="" disabled>Select Field</option>
                        <option value="Department">Department</option>
                        <option value="Job Title">Job Title</option>
                        <option value="Location">Location</option>
                      </select>
                    </div>
                    <div>
                      <label className="text-gray-700" htmlFor="criteria">
                        Select{" "}
                        <strong className={`text-${selectedColor}-500`}>
                          {addGroupData.targetColumn || "Field"}
                        </strong>{" "}
                        of members to add to this group{" "}
                        <span className="text-red-500 font-bold">*</span>
                      </label>

                      <Select
                        id="criteria"
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedValueList}
                        onChange={handleMemberCriteriaChange}
                        options={MemberCriteriaList}
                        className="groupField-multi-select shadow-none input-text mt-2"
                        classNamePrefix="select"
                      />
                      <p className="text-red-500 text-xs mt-1">{formError.targetColumn}</p>
                    </div>
                  </div>
                )}
              </form>
            </div>
            <div className="px-6 py-3 flex justify-end gap-4 border-t">
              <button className="inline-flex justify-center items-center px-8 py-2 mb-1 leading-5 text-sm transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow" onClick={handleGroupModalClose}>
                Cancel
              </button>
              <button className={`inline-flex justify-center items-center rounded bg-${selectedColor}-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-${selectedColor}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${selectedColor}-600`}
                onClick={saveGroup} >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteModalShow && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white rounded shadow-xl w-2/3 2xl:w-1/3 transition-transform transform duration-300">
            <div className="px-6 py-4 flex justify-between items-center border-b">
              <h3 className="text-lg font-semibold text-gray-800">Delete Group</h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setDeleteModalShow(false)}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="px-6 py-4">
              Are you sure you want to delete group{" "}
              {deleteGroupObj ? `"${deleteGroupObj.name}"` : false} ?
            </div>
            <div className="px-6 py-4 border-t flex justify-end space-x-5">
              <button
                className="inline-flex justify-center items-center rounded bg-white px-8 py-2 mb-1 leading-5 text-sm font-semibold text-blue-500 border border-blue-500 shadow-sm transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer"
                onClick={() => {
                  setDeleteModalShow(false);
                  setDeleteGroupObj(null);
                }}
              >
                Cancel
              </button>

              <button
                className="inline-flex justify-center items-center rounded bg-red-500 px-8 py-2 mb-1 leading-5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"
                onClick={() => deleteGroup(deleteGroupObj.id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageGroups;
