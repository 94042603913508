import React, { useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

const Dashboard = ({ children }) => {
    const [action, setAction] = useState(null);
    const [reRenderSidebar , setReRenderSidebar] = useState(false);
    return (
        <div className="flex h-screen">
            <Sidebar reRenderSidebar={reRenderSidebar} />
            <div className="flex-1 overflow-y-scroll py-8 px-10 bg-gray-50">
                <Outlet context={[action, setAction ,setReRenderSidebar]}  />
            </div>
        </div>
    );
};

export default Dashboard;

// import React, { useState } from 'react';
// import Sidebar from '../sidebar/Sidebar';
// import { Outlet } from 'react-router-dom';
// import { DashboardProvider } from '../../DashboardContext';

// const Dashboard = ({ children }) => {
//     const [allAction, setAllAction] = useState(null);

//     return (
//         <DashboardProvider>
//             <div className="flex h-screen">
//                 <Sidebar />
//                 <div className="flex-1 overflow-y-scroll py-8 px-10 bg-gray-50">
//                     <Outlet context={[allAction, setAllAction]} />
//                 </div>
//             </div>
//         </DashboardProvider>
//     );
// };

// export default Dashboard;
